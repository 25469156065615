import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutDialogComponent } from './modules/logout-dialog/logout-dialog.component';
import { IdleService } from './core/services/idle/idle.service';
import { AuthService } from './core/services/auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'TATrack';
  private modalRef: any;
  private idleSubscription?: Subscription;

  constructor(private modalService: NgbModal, private idleService: IdleService, private authService: AuthService,) { }


  // ngOnInit() {

  //   this.idleService.getIdleEvents().subscribe(() => {
  //     if (!this.modalRef) {
  //       this.openModal();
  //     }
  //   });
  // }

  ngOnInit() {
    localStorage.removeItem('popupOpen');
    // Subscribe to idle events only if the user is authenticated
    this.idleSubscription = this.idleService.getIdleEvents().subscribe(() => {
      if (!this.modalRef && this.authService.getAccessToken()) { // Check token before opening modal
        this.openModal();
      }
    });
  }

  openModal() {
    this.modalRef = this.modalService.open(LogoutDialogComponent);
    this.modalRef.componentInstance.message = 'You have been idle for a while. Please take action to continue your session.';

    this.idleService.getCountdown().subscribe(seconds => {
      if (this.modalRef) { // Ensure modal is still open
        this.modalRef.componentInstance.countdown = seconds;
        if (seconds <= 0) {
          this.modalRef.close(); // Close modal when countdown ends
        }
      }
    });

    this.modalRef.result.finally(() => {
      this.modalRef = null; // Reset modal reference on close
    });
  }

  ngOnDestroy() {
    // Unsubscribe from idle events when the component is destroyed
    if (this.idleSubscription) {
      this.idleSubscription.unsubscribe();
    }

    // Close modal if authToken is invalid or user is logged out
    if (!this.authService.getAccessToken() && this.modalRef) {
      this.modalRef.close();
    }
  }
}
