import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CandidateSearchComponent } from './pages/candidate-search/candidate-search.component';
import { ClientSearchComponent } from './pages/client-search/client-search.component';
import { ClientDrillDownComponent } from './pages/client-drill-down/client-drill-down.component';
import { CandidateDrillDownComponent } from './pages/candidate-drill-down/candidate-drill-down.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent
  },
  {
    path: 'candidates-list',
    component: CandidateSearchComponent
  },
  {
    path: 'clients-list',
    component: ClientSearchComponent
  },
  {
    path: 'clients-drill-down',
    component: ClientDrillDownComponent
  },
  {
    path: 'candidates-drill-down',
    component: CandidateDrillDownComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
