import { Injectable } from '@angular/core';
import { Subject, timer, fromEvent, BehaviorSubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  private idleTime = 60 * 60* 1000; // 1 hour
  private countdownTime = 10; // 10 seconds
  private idle$ = new Subject<void>();
  private countdown$ = new BehaviorSubject<number>(this.countdownTime);
  private logout$ = new Subject<void>();
  private idleTimerSub?: Subscription;
  private countdownTimerSub?: Subscription;

  constructor(private router: Router, private authService: AuthService) {
    this.startWatching();
    this.listenForStorageEvents();
  }

  /** * Start watching for user activity and idle time */
  private startWatching() {
    this.resetTimers();
    fromEvent(document, 'mousemove').pipe(tap(() => this.userActive())).subscribe();
    fromEvent(document, 'keydown').pipe(tap(() => this.userActive())).subscribe();

    // Handle logout across all tabs
    this.logout$.subscribe(() => {
      this.stopTimers();
      console.log('User logged out due to inactivity');
      localStorage.setItem('logoutEvent', Date.now().toString());
      localStorage.clear();
      this.router.navigate(['']);
      window.location.reload();
    });
  }

  /** * Mark user as active and reset idle timers globally */
  private userActive() {
    if (this.authService.getAccessToken()) {
      localStorage.setItem('lastActiveTime', Date.now().toString());
      this.resetTimers();
      localStorage.removeItem('idleModalClosed');
    }
  }

  /** * Reset idle timers and notify all tabs */
  private resetTimers() {
    this.stopTimers();
    if (this.authService.getAccessToken()) {
      this.idleTimerSub = timer(this.idleTime).pipe(
        tap(() => this.checkIdleStatus())
      ).subscribe();
    }
  }

  /** * Check if all tabs are idle before logging out */
  private checkIdleStatus() {
    const lastActiveTime = localStorage.getItem('lastActiveTime');
    if (lastActiveTime && Date.now() - parseInt(lastActiveTime) < this.idleTime) {
      this.resetTimers();
    } else {
      this.triggerIdle();
    }
  }

  /** * Stop all timers (idle & countdown) */
  private stopTimers() {
    const isPopupOpened = localStorage.getItem('popupOpen');
    if(!isPopupOpened) {
      if (this.idleTimerSub) {
        this.idleTimerSub.unsubscribe();
        this.idleTimerSub = undefined;
      }
      if (this.countdownTimerSub) {
        this.countdownTimerSub.unsubscribe();
        this.countdownTimerSub = undefined;
      }
    }
  }

  /** * Trigger idle state and start countdown */
  private triggerIdle() {
    const isPopupOpen = localStorage.getItem('popupOpen');
    if (!isPopupOpen) {
      localStorage.setItem('popupOpen', 'true');
      this.idle$.next();
      if (this.authService.getAccessToken()) {
        this.countdownTimerSub = timer(0, 1000).pipe(
          tap(tick => {
            const remaining = this.countdownTime - tick;
            this.countdown$.next(remaining);
            if (remaining <= 0) {
              this.countdownTimerSub?.unsubscribe();
              localStorage.setItem('logoutEvent', Date.now().toString());
              this.logout$.next();
            }
          })
        ).subscribe();
      }
    }
  }

  /** * Listen for storage events across multiple tabs */
  private listenForStorageEvents() {
    window.addEventListener('storage', (event) => {
      console.log(`Storage Event: ${event.key} - ${event.newValue}`);
      if (event.key === 'logoutEvent') {
        this.logout$.next();
      }
      if (event.key === 'lastActiveTime') {
        this.resetTimers();
      }
      if (event.key === 'idleModalClosed') {
        localStorage.removeItem('popupOpen');
        this.idle$.next();
      }
    });
  }

  /** * Return idle state observable */
  getIdleEvents() {
    return this.idle$.asObservable();
  }

  /** * Return countdown observable */
  getCountdown() {
    return this.countdown$.asObservable();
  }

  /** * Close idle modal in all tabs and reset popupOpen flag */
  closeModal() {
    localStorage.setItem('idleModalClosed', 'true');
    localStorage.removeItem('popupOpen');
    this.idle$.next();
  }
}
