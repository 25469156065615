import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { AppHeaderComponent } from './components/app-header/app-header.component';
// import { AppFooterComponent } from './components/app-footer/app-footer.component';
// import { AppNavComponent } from './components/app-nav/app-nav.component';

import {
  AppFooterComponent,
  AppSubHeaderComponent
} from "./components/";
import { DatePipe } from '@angular/common';
import { CurrencyPipe } from '@angular/common';
import { NgSelectModule } from "@ng-select/ng-select";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';

@NgModule({
  declarations: [
    AppFooterComponent,
    CommonNotificationComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgSelectModule
  ],
  exports: [
    AppFooterComponent,
    CommonNotificationComponent,
  ],
  providers: [DatePipe, CurrencyPipe]
})
export class SharedModule { }
