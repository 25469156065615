import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { faCaretUp, faCaretDown, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import { PagerService } from 'src/app/core/common/pager.service';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-candidate-search',
  templateUrl: './candidate-search.component.html',
  styleUrls: ['./candidate-search.component.css']
})
export class CandidateSearchComponent {

  candidateList: any[] = [];
  searchQuery: string = '';
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  mouseOnColumnName: string = '';
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  rowIndex: any;
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  faArrowLeft = faArrowLeft;
  lastSortedField: string = '';
  lastSortedDirection: string = '';
  multipleSorting: any = [];
  totalRows: number = 0;
  isSearchable: boolean = true;
  searchName: any;
  showlength!: number;
  pager: any = {};
  sortfield: string = '';
  sortmethod: string = '';
  isSearchClicked: boolean = false;
  isClearClicked: boolean = false;
  pageNumber: number = 1;
  candidate_status_id: any;
  user_id: any;

  private hasScrolledToBottom = false;

  constructor(
      private candidateService: CandidateService,
      private pagerService: PagerService,
      private router: Router,
      private location: Location,
      private route: ActivatedRoute
    ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const requestData = this.candidateService.getCandidateSearchData();
      this.searchName = history.state.searchName;
      if (requestData) {
          this.setPage(requestData.page);
          this.candidate_status_id = requestData.candidate_status_id;
          this.sortfield = requestData.sort_field;
          this.sortmethod = requestData.sort_method;
          this.user_id = requestData.user_id;
          this.getCandidates(requestData);
      }
    });
  }

  setPage(page: number) {
    this.pageNumber = page;
  }

  @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
      if (this.candidateList.length < this.pager.totalItems) {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (scrollPosition + windowHeight >= documentHeight - 10) {
          if (!this.hasScrolledToBottom) {
            this.hasScrolledToBottom = true;
            this.setPage(this.pager.currentPage + 1);
            this.loadMoreCandidates();
          }
        } else {
          this.hasScrolledToBottom = false;
        }
      }
    }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
  }

  mouseLeave(columnname: any) {
    if (this.sortfield !== columnname) {
      this.iconVisible = false;
    }
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = '';
  }

   getCandidates(params: any): void {
     this.candidateService.getCandidateListOnStatus(params).subscribe({
       next: (response) => {
         this.candidateList = response.candidates?.data ?? [];
         this.totalRows = response.candidates.total;
         this.pager = this.pagerService.getPager(
           this.totalRows,
           this.pageNumber
         );
       },
       error: (error) => {
         console.error('Error fetching candidates:', error);
       }
     });
   }

   getCandidatesList(data: any): void {
//       const field = data.sort_field;
      this.sortmethod = this.sortmethod === 'asc' ? 'desc' : 'asc';
      this.sortfield = data.sort_field;
      this.pageNumber = 1;

//       if (!this.multipleSorting[field]) {
//         this.multipleSorting[field] = [1, 'asc'];
//       } else {
//         this.multipleSorting[field][1] = this.multipleSorting[field][1] === 'asc' ? 'desc' : 'asc';
//       }

      let query = {
        candidate_status_id: this.candidate_status_id,
        page: this.pageNumber,
        per_page: 25,
        user_id: this.user_id,
        sort_field: this.sortfield,
        sort_method: this.sortmethod
      };
      this.getCandidates(query);
    }

   loadMoreCandidates() {
    const queryParams = {
      candidate_status_id: this.candidate_status_id,
      user_id: this.user_id,
      sort_field: this.sortfield,
      sort_method: this.sortmethod,
      page: this.pageNumber,
      per_page: 25
    };

    this.candidateService.getCandidateListOnStatus(queryParams).subscribe({
      next: (response) => {
        this.candidateList = [...this.candidateList, ...response.candidates?.data ?? []];
        this.totalRows = response.candidates.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        this.hasScrolledToBottom = false;
      },
      error: (error) => {
        console.error('Error fetching candidates:', error);
        this.hasScrolledToBottom = false;
      }
    });
  }

  goToDashboard(): void {
    this.candidateService.clearClientSearchData();
    this.router.navigate(['/dashboard'], { state: { userId: this.user_id } });
  }

  onSearch(searchValue: string) {
    this.searchQuery = searchValue;
    if (this.searchQuery.trim()){
        let requestData = {
          candidate_status_id: this.candidate_status_id,
          user_id: this.user_id,
          search: this.searchQuery
        };
        this.getCandidates(requestData);
    } else {
      let requestData = this.candidateService.getCandidateSearchData();
      this.getCandidates(requestData);
    }
  }

  showCandidate(id: number | null) {
    if (id !== null) {
      this.router.navigate(['/candidate/' + id]);
    } else {
      console.error('Invalid candidate id');
    }
  }

  showProject(id: number) {
    this.router.navigate(['/search/' + id]);
  }

}
