import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { Location } from '@angular/common';
import { faCaretUp, faCaretDown, faArrowLeft  } from '@fortawesome/free-solid-svg-icons';
import { PagerService } from 'src/app/core/common/pager.service';

@Component({
  selector: 'app-client-drill-down',
  templateUrl: './client-drill-down.component.html',
  styleUrls: ['./client-drill-down.component.css']
})
export class ClientDrillDownComponent {
    clientList: any[] = [];
    searchQuery: string = '';
    iconVisible: boolean = true;
    iconVisibleAsc: boolean = false;
    iconVisibleDesc: boolean = false;
    mouseOnColumnName: string = '';
    staticarrowsVisible: boolean = false;
    currentSortedColumn: any;
    currentSortedMethod: any;
    sortedColumn: any;
    rowIndex: any;
    faArrowUp = faCaretUp;
    faArrowDown = faCaretDown;
    faArrowLeft = faArrowLeft;
    lastSortedField: string = '';
    lastSortedDirection: string = '';
    multipleSorting: any = [];
    totalRows: number = 0;
    isSearchable: boolean = true;
    searchName: any;
    showlength!: number;
    pager: any = {};
    sortfield: string = '';
    sortmethod: string = '';
    isSearchClicked: boolean = false;
    isClearClicked: boolean = false;
    pageNumber: number = 1;
    list_type: any;
    user_id: any;

    private hasScrolledToBottom = false;

    constructor(
        private candidateService: CandidateService,
        private pagerService: PagerService,
        private router: Router,
        private location: Location,
        private route: ActivatedRoute
      ) {}

    ngOnInit(): void {
      this.route.queryParams.subscribe(params => {
        const requestData = this.candidateService.getClientDrillData();
        if (requestData) {
            this.setPage(requestData.page);
            this.list_type = requestData.list_type;
            this.sortfield = requestData.sort_field;
            this.sortmethod = requestData.sort_method;
            this.user_id = requestData.user_id;
            this.getClients(requestData);
            if(requestData.list_type == 'itd'){
              this.searchName = "Inception To Date"
            } else if(requestData.list_type == 'ttm'){
              this.searchName = "Trailing 12 Months"
            }
        }
      });
    }

    setPage(page: number) {
      this.pageNumber = page;
    }

    goToDashboard(): void {
      this.candidateService.clearClientDrillData();
      this.router.navigate(['/dashboard'], { state: { userId: this.user_id } });
    }

    getClients(params: any): void {
       this.candidateService.getClientDrillDownList(params).subscribe({
         next: (response) => {
           this.clientList = response.clients?.data ?? [];
           this.totalRows = response.clients.total;
           console.log(response);
           this.pager = this.pagerService.getPager(
             this.totalRows,
             this.pageNumber
           );
         },
         error: (error) => {
           console.error('Error fetching candidates:', error);
         }
      });
    }

    onSearch(searchValue: string) {
     this.searchQuery = searchValue;
     if (this.searchQuery.trim()){
         let requestData = {
           user_id: this.user_id,
           search: this.searchQuery
         };
         this.getClients(requestData);
     } else {
       let requestData = this.candidateService.getClientDrillData();
       this.getClients(requestData);
     }
   }

   loadMoreClients() {
    const queryParams = {
      sort_field: this.sortfield,
      sort_method: this.sortmethod,
      page: this.pageNumber,
      list_type: this.list_type,
      user_id: this.user_id,
      per_page: 25
    };

    this.candidateService.getClientDrillDownList(queryParams).subscribe({
      next: (response) => {
        this.clientList = [...this.clientList, ...response.clients?.data ?? []];
        this.totalRows = response.clients.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        this.hasScrolledToBottom = false;
      },
      error: (error) => {
        console.error('Error fetching clients:', error);
        this.hasScrolledToBottom = false;
      }
    });
  }

  @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
      if (this.clientList.length < this.pager.totalItems) {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (scrollPosition + windowHeight >= documentHeight - 10) {
          if (!this.hasScrolledToBottom) {
            this.hasScrolledToBottom = true;
            this.setPage(this.pager.currentPage + 1);
            this.loadMoreClients();
          }
        } else {
          this.hasScrolledToBottom = false;
        }
      }
    }

    mouseEnter(columnname: any) {
      this.iconVisible = true;
      this.currentSortedColumn = columnname;
      this.mouseOnColumnName = columnname;
    }

    mouseLeave(columnname: any) {
      if (this.sortfield !== columnname) {
        this.iconVisible = false;
      }
      this.staticarrowsVisible = true;
      this.mouseOnColumnName = '';
    }

    getClientList(data: any): void {
        this.sortmethod = this.sortmethod === 'asc' ? 'desc' : 'asc';
        this.sortfield = data.sort_field;
        this.pageNumber = 1;
        let query = {
          page: this.pageNumber,
          per_page: 25,
          list_type: this.list_type,
          user_id: this.user_id,
          sort_field: this.sortfield,
          sort_method: this.sortmethod
        };
        this.getClients(query);
    }

    open(content: any, EditId: number) {
      if(content === 'OpenClient'){
        this.router.navigate([`/client/${EditId}`]);
        return;
      }
    }

    showCandidate(id: number | null) {
      if (id !== null) {
        this.router.navigate(['/candidate/' + id]);
      } else {
        console.error('Invalid candidate id');
      }
    }
}
