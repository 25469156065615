import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-client-status-list',
  templateUrl: './client-status-list.component.html',
  styleUrls: ['./client-status-list.component.css']
})
export class ClientStatusListComponent implements OnInit {
  FilterForm!: FormGroup;
  UpdateClientStatusForm!: FormGroup;
  showCreateButton: string = 'client-status';
  pageTitle: string = 'Client Stage';
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  currentSearchValue: any;
  clientStatusList: any = [];
  submitted: boolean = false;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  sortfield: any;
  sortmethod: any;
  mouseOnColumnName: string = "";
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  multipleSorting: any = [];
  getSearchList: any;

  imagepath: string = "";
  strengthId: any;
  imageselected: boolean = false;
  filterStrengthId: string = "";

  showFilterOptions: boolean = false;
  closeResult: string = "";
  updateCSErrorName: string = "";
  updateCSErrorDescription: string = "";
  updateCSError: string = "";
  csapicall: boolean = false;
  updateClientStatusData: any;
  createTagError: any;
  userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
  currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
  deleteClientStatusId: number = 0;

  deleteErrorMsg: any;

  get filterForm() { return this.FilterForm.controls; }
  get updateClientStatusForm() { return this.UpdateClientStatusForm.controls; }

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private clientStatusService: ClientService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private pagerService: PagerService

  ) { }

  ngOnInit(): void {
    this.onBuildForm();
    this.onLoad();
  }

  onBuildForm() {
    this.UpdateClientStatusForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      description: [null],
      sequence: [null, [Validators.pattern("^[0-9]*$")]],
      status: [null, [Validators.required]],
      type: [null, [Validators.required]],
      updateId: [null]
    });
    if (this.currentTenantId != '0') {
      this.updateClientStatusForm['name'].disable();
      this.updateClientStatusForm['description'].disable();
      this.updateClientStatusForm['type'].disable();
    } else {
      this.updateClientStatusForm['name'].enable();
      this.updateClientStatusForm['description'].enable();
      this.updateClientStatusForm['type'].enable();
    }
  }

  onLoad() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.clients) {
      let existing_filter = this.getSearchList.clients.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'search_current_position')
            this.FilterForm.controls['f_position'].setValue(f_option[1]);

          if (f_option[0] == 'search_current_company')
            this.FilterForm.controls['f_company'].setValue(f_option[1]);

          if (f_option[0] == 'search_in_gender')
            this.FilterForm.controls['f_gender'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_diversity')
            this.FilterForm.controls['f_diversity'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_compensation')
            this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

          if (f_option[0] == 'language_id')
            this.FilterForm.controls['f_language'].setValue(f_option[1]);

          if (f_option[0] == 'tag_id')
            this.FilterForm.controls['f_tag'].setValue(f_option[1]);

          if (f_option[0] == 'strength')
            this.showfilterimage('./assets/img/' + f_option[1] + '.png', f_option[1]);
        });
      }
      this.getClientStatusList(null, this.getSearchList.clients_status);
    } else
      this.getClientStatusList({});

  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(StorageKey.listSettings);
    if (listSettings !== "undefined" && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.clients) {
      delete this.getSearchList.client_status;
      this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
    }
    this.currentSearchValue = "";
    this.getClientStatusList({}, undefined, true);
  }

  getClientStatusList(data1: any, data = {
    page: this.pageNumber,
    sort_field: this.currentSortedColumn,
    sort_method: this.currentSortedMethod,
    column_name: this.sortedColumn,
    //apply_filter:this.currentAppliedFilter,
    search: this.currentSearchValue,
    //multipleSorting:this.multipleSorting
  }, reset: boolean = false) {

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
      if (data1.sort_field && data1.sort_method) {
        // this.multipleSorting[data1.sort_field] = [data1.sort_field, data1.sort_method];
        // data.multipleSorting = this.multipleSorting;
      }
    }


    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
    if (reset) {
      /*  if(this.getSearchList) {
         this.currentSearchValue = data.search;
         this.getSearchList.clients =  data;
         this.storageService.setValue('listSettings', JSON.stringify(this.getSearchList));
       } else
         this.storageService.setValue('listSettings', JSON.stringify({clients:data}));
     }else{
       data.search = ""; */
      data.page = 1;
    }

    this.clientStatusService.clientStatusListByFilter(data).subscribe({
      next: (response) => {
        this.clientStatusList = response.client_statuses.data;
        // this.clientListId = response.clients_ids;
        this.pageNumber = response.client_statuses.current_page;
        this.totalRows = response.client_statuses.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.pageNumber);
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
          }
          this.sortfield = response.client_statuses.sort_field;
          this.sortmethod = response.client_statuses.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });

  }

  mouseEnter(columnname: any) {

    this.iconVisible = true;
    // this.staticarrowsVisible = false;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;

    /* if (columnname == 'in_first_name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }

    if (columnname == 'current_position' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'current_company' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'projects_count' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'in_personal_phone' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'in_personal_email' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    } */
  }


  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = "";
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    /*  this.selectDeletedCandidte = value.in_first_name + " " +  value.in_last_name; */
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.deleteClientStatusId = deleteId;

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }



  deleteClientStatus1() {

    this.deleteErrorMsg = "";

    let deleteId = this.deleteClientStatusId;
    this.clientStatusService.deleteClientStatus(deleteId)
      .subscribe({
        next: (response) => {
          //  this.tagList = response.tags.data;
          this.modalService.dismissAll();
          this.notificationService.showSuccess(response.message);
          this.getClientStatusList({});
        },
        error: (error) => {
          // this.notificationService.showError(error.error.message);
          this.deleteErrorMsg = error.error.message;
        },
      });
  }


  cleardeleteErrorMsg() {
    this.deleteErrorMsg = "";
  }

  openUpdateModal(content: any, updateId: number, value: any) {
    this.clientStatusService.getClientStatusDetail(updateId).subscribe({
      next: (response: any) => {
        let clientStatusDetail = response.client_status;
        this.updateClientStatusForm['name'].setValue(clientStatusDetail.name);
        this.updateClientStatusForm['description'].setValue(clientStatusDetail.description);
        this.updateClientStatusForm['type'].setValue(clientStatusDetail.category);
        this.updateClientStatusForm['sequence'].setValue(clientStatusDetail.sequence);
        this.updateClientStatusForm['updateId'].setValue(updateId);
        this.updateClientStatusForm['status'].setValue(clientStatusDetail.is_active.toString());
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          },
        );
      }
    });

  }

  setPage(page: number) {
    this.pageNumber = page;

    this.getClientStatusList({ page: this.pageNumber, sort_field: this.currentSortedColumn, sort_method: this.currentSortedMethod, column_name: this.sortedColumn });
  }

  showfilterimage(data: string, strengthId: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.filterStrengthId = strengthId;


  }

  resetBackendCSerror() {
    this.updateCSErrorName = "";
    this.updateCSErrorDescription = "";
    this.updateCSError = "";
    this.UpdateClientStatusForm.reset();
  }

  updateClientStatus() {
    this.submitted = true;
    if (this.UpdateClientStatusForm.invalid) {
      return;
    }
    let id = this.updateClientStatusForm['updateId'].value
    this.updateClientStatusData = {
      name: this.updateClientStatusForm['name'].value,
      description: this.updateClientStatusForm['description'].value,
      sequence: this.updateClientStatusForm['sequence'].value,
      is_active: this.updateClientStatusForm['status'].value,
      category: this.updateClientStatusForm['type'].value
    }
    this.csapicall = true;
    this.clientStatusService.updateClientStatus(id, this.updateClientStatusData).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.UpdateClientStatusForm.reset();
        this.notificationService.showSuccess(response.message);

        this.csapicall = false;
        this.submitted = false;
        this.getClientStatusList({});

      },
      error: (error) => {
        this.updateCSError = error.error.message;
        this.csapicall = false;
        switch (error.status) {
          case 422:
            if (error.error.name) {
              this.updateCSErrorName = error.error.name[0];
            }
            if (error.error.description) {
              this.updateCSErrorDescription = error.error.description[0];
            }

            break;
          default:
          //Nothing
        }
        //   this.notificationService.showError(error.message);
      }
    });

  }
}
