import { Component, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidatejobsService } from 'src/app/core/services/candidatejobs/candidatejobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PagerService } from 'src/app/core/common/pager.service';
import { StorageService, StorageKey } from 'src/app/core/common/storage.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

interface Location {
  city: string;
  state: string;
}


@Component({
  selector: 'app-joblist',
  templateUrl: './joblist.component.html',
  styleUrls: ['./joblist.component.css']
})
export class JoblistComponent {

  publicjoblist: any = [];
  tenantlist: any = [];
  // openedJobId: number | null = null;
  tenant: any;

  pageNumber: number = 10;
  currentSearchValue: any;
  totalRows: number = 0;
  pager: any = {};
  closeResult: string = "";
  // selectedLocation: string = '';
  safeDescriptions: SafeHtml[] = [];
  @ViewChild('ApplyjobModal', { static: false }) ApplyjobModal: any;
  jobapplyForm: FormGroup;
  filteredJobs: any;
  submitted: boolean = false;
  candidateData: any;
  candidate_id: any
  linkedin_url: any;
  job_id: any;
  tenantid: any;

  applyJobError: any;
  project_idError: any;
  urlError: any;
  tenant_idError: any;
  applysuccessMsg: any;
  current_page: number = 1;
  pageSize: number = 3;
  // allLocations: any[] = [];
  allLocations: Location[] = [];

  showlinkedininput: boolean = false;

  files: any;
  fileName!: string;
  filePath!: string;
  showuploadButton: boolean = false;
  UpdateDocumentsError: any;
  CreatDocumentsError: any;

  AddDocumentForm!: FormGroup;
  uploadresumesuccess: any

  currentUser: any;
  openedJobId: string | null = null;
  selectedLocation: Location | null = null;



  constructor(
    private notificationService: NotificationService,
    private CandidatejobsService: CandidatejobsService,
    private route: ActivatedRoute,
    private router: Router,
    private pagerService: PagerService,
    private storageService: StorageService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,) {


    let loginUserDetail: any = this.storageService.getValue(StorageKey.currentUser)
    this.currentUser = JSON.parse(loginUserDetail)
    if (this.currentUser.role.name == "Candidate") {
      this.router.navigate(['jobslist']);
    } else {
      this.router.navigate(['dashboard']); // Navigate to the dashboard page
    }

    this.jobapplyForm = this.formBuilder.group({
      linkedinurl: [null],
      tenantid: [null],
    })

    this.AddDocumentForm = this.formBuilder.group({
      document: [null, Validators.required],
    })

    this.candidate_id = this.storageService.getValue('candidate_id')
  }

  get validation() { return this.jobapplyForm.controls; }
  get documentDetails() { return this.AddDocumentForm.controls }


  isLoggedIn(): boolean {
    let candidate_id = this.storageService.getValue(StorageKey.candidate_id);

    if (candidate_id)
      return false;
    else
      return true;

  }

  ngOnInit(): void {
    this.tenant = this.route.snapshot.paramMap.get('tenant');
    if (this.tenant) {
      this.getjobList(null, this.tenant);
    } else
      this.getjobList({});

    this.getlocations()
  }


  generateUniqueId(job: { id: number, tenant_id: string }): string {
    return `${job.id}-${job.tenant_id}`;
  }

  openJobDetails(uniqueId: any) {
    this.openedJobId = uniqueId;
    this.resetapplyerror();
  }

  // openJobDetails(jobId: number) {
  //   this.openedJobId = jobId;

  //   console.log(jobId)

  //   this.resetapplyerror()

  // }



  onSearchClick(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 10;
    this.current_page = 1;
    this.getjobList({
      per_page: this.pageNumber,
      search: value,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });
  }

  getjobList(data1: any, reset: boolean = false) {

    let data = {
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      location: data1 && data1.location ? data1.location : null,
      page: this.current_page,
      candidate_id: this.candidate_id
    };

    if (data1) {
      data = { ...data, ...data1 };
    }

    this.CandidatejobsService.getalltenentjobList(data).subscribe({
      next: (response: any) => {
        this.publicjoblist = response.projects;
        this.tenantlist = response.tenant;
        this.current_page = response.projects.current_page;
        this.totalRows = response.projects.total;
        this.pager = this.pagerService.getPager(this.totalRows, this.current_page);
        this.updateSafeDescriptions();

        // if (this.publicjoblist && this.publicjoblist.data.length > 0) {
        //   this.openJobDetails(this.publicjoblist.data[0].id);
        // }
        if (this.publicjoblist && this.publicjoblist.data.length > 0) {
          this.openJobDetails(this.generateUniqueId(this.publicjoblist.data[0]));
        }
      },
      error: (error: any) => {
        switch (error.status) {
          case 404:
            this.notificationService.showError("Tenant Not Found");
            break;
          case 500:
            this.notificationService.showError("Tenant Not Found");
            break;
          default:
            this.notificationService.showError(error.message);
            break;
        }
      }
    });


  }

  sanitizeDescription(description: any): string {
    if (description == null) {
      return 'No job description found';
    }
    return this.sanitizer.bypassSecurityTrustHtml(description) as string;
  }

  updateSafeDescriptions(): void {
    this.safeDescriptions = this.publicjoblist.data.map((job: any) => this.sanitizeDescription(job.description));
  }

  filterByLocation() {
    this.pageNumber = 10;
    this.current_page = 1;
    this.getjobList({
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });
  }


  applyjob(jobid: number) {
    this.job_id = jobid
    this.filteredJobs = this.publicjoblist.data.filter((ele: any) => ele.id == jobid);
    if (this.filteredJobs.length > 0) {
      this.tenantid = this.filteredJobs[0].tenant_id;
      this.onload();
      this.modalService.open(this.ApplyjobModal, { ariaLabelledBy: 'modal-basic-title', size: "md" }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, () => {
        this.closeResult = `Dismissed `;
      });
    } else {
      console.error("Job not found with id:", jobid);
    }
  }


  onload() {
    this.CandidatejobsService.candidateDetail(this.candidate_id).subscribe({
      next: (response: any) => {
        this.candidateData = response.candidate;
        this.linkedin_url = this.candidateData.linkedin_url;
        if (this.candidateData.in_public_identifier == null) {
          this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
          this.jobapplyForm.controls['linkedinurl'].enable();
          this.showlinkedininput = true
        }
        else {
          this.jobapplyForm.controls['linkedinurl'].setValue(this.linkedin_url);
          this.jobapplyForm.controls['linkedinurl'].disable();
          this.showlinkedininput = false
        }

        if (this.candidateData.documents && this.candidateData.documents.length > 0) {
          // console.log("document id present");
          this.showuploadButton = false
        } else {
          this.showuploadButton = true
          // console.log("document not present");
        }

      },
      error: (error) => {
        this.notificationService.showError(error.message);
      }
    });
  }


  onapplysubmit() {
    this.submitted = true;
    this.applysuccessMsg = ""
    this.applyJobError = ""
    this.urlError = ''
    this.project_idError = ''
    this.uploadresumesuccess = ''

    if (this.jobapplyForm.invalid) {
      return;
    }

    let applydata = {
      candidate_id: this.candidate_id,
      project_id: this.job_id,
      tenant_id: this.tenantid,
      url: this.jobapplyForm.controls['linkedinurl'].value,
    }





    this.CandidatejobsService.applyjob(applydata.candidate_id, applydata.project_id, applydata).subscribe({
      next: (response: any) => {
        this.jobapplyForm.reset();
        this.applysuccessMsg = response.message
        this.submitted = false;
        this.filteredJobs[0].applied_at = new Date().toISOString();
        // console.log(this.filteredJobs[0].applied_at)
        this.onload()
      },
      error: (error) => {
        this.applyJobError = error.error.message;
        switch (error.status) {
          case 422:
            if (error.error.project_id) {
              this.project_idError = error.error.project_id[0];
            }
            if (error.error.url) {
              this.urlError = error.error.url[0];
            }
            if (error.error.tenant_id) {
              this.tenant_idError = error.error.tenant_id[0];
            }
            break;
          default:
        }
        // this.notificationService.showError(error.message);
      }
    })

  }

  resetapplyerror() {
    this.project_idError = "";
    this.urlError = "";
    this.applyJobError = "";
    this.tenant_idError = "";
    this.applysuccessMsg = '';
    this.uploadresumesuccess = '';
  }


  setPage(page: number) {
    this.current_page = page;

    this.getjobList({
      per_page: this.pageNumber,
      search: this.currentSearchValue,
      location: this.selectedLocation,
      page: this.current_page,
      candidate_id: this.candidate_id
    });


  }

  getlocations() {
    this.CandidatejobsService.getlocations().subscribe({
      next: (response: any) => {
        this.allLocations = response.locations;
        // Sort locations only if allLocations is not null or undefined
        if (this.allLocations) {
          this.allLocations.sort((a, b) => {
            // Check if both a and b have the city property
            if (a.city && b.city) {
              return a.city.localeCompare(b.city);
            } else {
              // Handle the case where either a or b (or both) don't have the city property
              return 0; // or any other value depending on your sorting criteria
            }
          });
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }


  postJob() {

  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }



  uploadresume() {

    this.submitted = true;
    this.uploadresumesuccess = ''

    if (this.AddDocumentForm.invalid) {
      return;
    }

    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    formData.append('is_resume', '0');

    this.CandidatejobsService.createDocument(this.candidate_id, formData).subscribe({
      next: (response: any) => {
        this.showuploadButton = false
        // this.onLoad();
        // this.modalService.dismissAll();
        // this.notificationService.showSuccess(response.message);
        this.uploadresumesuccess = "Resume uploaded successfully. Now you can apply for this opportunity."
        this.submitted = false;
        // this.onLoad();
      },
      error: (error) => {
        this.CreatDocumentsError = error.error.message;
        // switch (error.status) {
        //   case 422:
        //     if (error.error.document) {
        //       this.CreatDocumentsErrorDocument = error.error.document[0];
        //     }
        //     if (error.error.description) {
        //       this.CreatDocumentsErrorDescription = error.error.description[0];
        //     }
        //     break;
        //   default:
        //   //Nothing
        // }
        // this.notificationService.showError(error.message);
      }
    })

  }


  formatJobLocation(job: { city: string | null, state: string | null }): string {
    if (job.city && job.state) {
      return `${job.city}, ${job.state}`;
    } else if (job.city) {
      return job.city;
    } else if (job.state) {
      return job.state;
    } else {
      return '';
    }
  }

  formatLocation(location: { city: string | null, state: string | null }): string {
    if (location.city && location.state) {
      return `${location.city}, ${location.state}`;
    } else if (location.city) {
      return location.city;
    } else if (location.state) {
      return location.state;
    } else {
      return '';
    }
  }


}
