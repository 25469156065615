<app-app-sub-header
  class="top-row d-flex justify-content-between"
  (onRefreshClicked)="resetListSettings()"
  (searchClicked)="searchData($event)"
  (resetListClicked)="resetListSettings()"
  [isSearchable]="isSearchable"
  [pageTitle]="pageTitle"
  [showCreateButton]="showCreateButton"
  (onShowFilter)="showFilter()"
></app-app-sub-header>

<div class="bottom-table">
  <form [formGroup]="FilterForm" *ngIf="showFilterOptions">
    <div class="row">
      <div class="col-2">
        <input
          type="text"
          formControlName="f_position"
          class="form-control"
          placeholder="Position"
        />
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_company"
          class="form-control"
          placeholder="Current company"
        />
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_past_company"
          class="form-control"
          placeholder="Past company"
        />
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_compensation"
          class="form-control"
          placeholder="Compensation"
        />
      </div>

      <div class="col-2">
        <div class="dropdown">
          <button
            class="btn btn-secondary custom-d w-100 bg-white border-0 dropdown-toggle text-start"
            type="button"
            id="dropdownMenuButto"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="strength" *ngIf="!imageselected">Select strength</span>
            <img *ngIf="imageselected" src="{{ imagepath }}" alt="" /><span
            *ngIf="imageselected"
            style="color: black"
          ><span *ngIf="filterStrengthId != '5'">& Up</span></span
          >
          </button>

          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li (click)="showfilterimage('./assets/img/1.png', '1')">
              <a class="dropdown-item">
                <img src="./assets/img/1.png" alt="" />&Up</a
              >
            </li>

            <li (click)="showfilterimage('./assets/img/2.png', '2')">
              <a class="dropdown-item"
              ><img src="./assets/img/2.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/3.png', '3')">
              <a class="dropdown-item"
              ><img src="./assets/img/3.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/4.png', '4')">
              <a class="dropdown-item"
              ><img src="./assets/img/4.png" alt="" />& Up</a
              >
            </li>
            <li (click)="showfilterimage('./assets/img/5.png', '5')">
              <a class="dropdown-item"
              ><img src="./assets/img/5.png" alt=""
              /></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="col-2">
        <input
          type="text"
          formControlName="f_city"
          class="form-control"
          placeholder="City"
        />
      </div>
    </div>

    <div class="row" style="margin-top: 5px; margin-bottom: 5px">
      <div class="col-2">
        <ng-select
          [items]="stateList"
          class="form-control form-select"
          placeholder="State"
          bindLabel="name"
          formControlName="f_state"
          bindValue="name"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          [items]="genderList"
          class="form-control form-select"
          placeholder="Gender"
          bindLabel="name"
          formControlName="f_gender"
          bindValue="name"
        >
        </ng-select>
      </div>
      <div class="col-2">
        <ng-select
          [items]="diverseList"
          class="form-control form-select"
          placeholder="Diverse candidate"
          bindLabel="name"
          formControlName="f_diversity"
          bindValue="value"
        >
        </ng-select>
      </div>

      <div class="col-2">
        <ng-select
          [items]="languageList.languages"
          class="form-control form-select"
          placeholder="Language"
          bindLabel="name"
          formControlName="f_language"
          bindValue="id"
          #f_language
          (keydown)="getLanguageList(f_language)"
          (click)="getLanguageList()"
        >
        </ng-select>
      </div>

      <div class="col-2">
        <!-- <input placeholder="Contact After" type="text" formControlName="f_contactAfter" class="form-control contactAfter">
            <i style="position: relative;bottom: 50%;left: 90%;cursor: pointer;" class="fa fa-calendar"></i> -->
        <!-- <input type="date" formControlName="f_contactAfter" class="form-control contactAfter"> -->
        <input
          [ngStyle]="{
            color:
              FilterForm.get('f_contactAfter')?.value || isFocused
                ? 'black'
                : 'transparent'
          }"
          type="date"
          formControlName="f_contactAfter"
          class="form-control contactAfter"
          #dateInput
          (click)="isFocused = true"
          (blur)="isFocused = false"
        />

        <!-- Placeholder-like label -->
        <label
          (click)="isFocused = true; dateInput.focus()"
          [ngStyle]="{
            color:
              !FilterForm.get('f_contactAfter')?.value && !isFocused
                ? 'black'
                : 'transparent'
          }"
          style="position: relative; bottom: 48%; left: 5%"
          class="placeholder-label"
          [class.active]="dateInput.value"
          for="dateInput"
        >
          Last contacted after
        </label>
      </div>

      <div class="col-2">
        <ng-select
          [items]="tagList"
          class="form-control form-select"
          placeholder="Tag"
          bindLabel="name"
          formControlName="f_tag"
          bindValue="id"
          #f_tag
          (keydown)="getTagList(f_tag)"
          (click)="getTagList()"
        >
        </ng-select>
      </div>
    </div>
    <div class="row">
      <div style="text-align: right">
        <button
          type="button"
          class="filter me-3"
          (click)="
            this.FilterForm.reset();
            this.filterStrengthId = '';
            this.currentAppliedFilter = null;
            this.filterStrengthId = '';
            this.getCandidateList({});
            this.imageselected = false;
            this.showFilterOptions = false
          "
        >
          Clear
        </button>
        <button type="button" class="blue-btn ms-2" (click)="applyFilter()">
          Apply
        </button>
      </div>
    </div>
  </form>

  <hr *ngIf="sendToSearchDiv && showFilterOptions" />

  <div style="padding: 5px">
    <div
      style="display: flex; align-items: first baseline; margin-bottom: 10px"
    >
      <span
      >Selected {{ selectedItems.length }} out of
        {{ totalRows }} candidates</span
      >
      <button
        [disabled]="selectedItems.length === 0"
        type="button"
        class="blue-btn ms-2"
        (click)="openPopupOfSearch(UpdateTag, selectedItems)"
        style="margin-bottom: 7px"
      >
        Add to Search
      </button>
    </div>
  </div>

  <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col">
        <div style="padding-left: 15px !important">
          <input
            type="checkbox"
            [(ngModel)]="selectAll"
            (change)="toggleSelectAll()"
          />
          <!-- Select All -->
        </div>
      </th>
      <th
        scope="col"
        style="width: 15%"
        (mouseenter)="mouseEnter('in_last_name')"
        (mouseleave)="mouseLeave('in_last_name')"
      >
        Name
        <span
          (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'desc',
                column_name: 'in_last_name'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['in_last_name'] &&
              mouseOnColumnName == 'in_last_name'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'asc',
                column_name: 'in_last_name'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_last_name'] &&
                multipleSorting['in_last_name'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'in_last_name',
                sort_method: 'desc',
                column_name: 'in_last_name'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_last_name'] &&
                multipleSorting['in_last_name'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 20%"
        (mouseenter)="mouseEnter('current_position')"
        (mouseleave)="mouseLeave('current_position')"
      >
        Current Position
        <span
          (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'desc',
                column_name: 'current_position'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['current_position'] &&
              mouseOnColumnName == 'current_position'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'asc',
                column_name: 'current_position'
              })
            "
          [hidden]="
              !(
                multipleSorting['current_position'] &&
                multipleSorting['current_position'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'current_position',
                sort_method: 'desc',
                column_name: 'current_position'
              })
            "
          [hidden]="
              !(
                multipleSorting['current_position'] &&
                multipleSorting['current_position'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 15%"
        (mouseenter)="mouseEnter('current_company')"
        (mouseleave)="mouseLeave('current_company')"
      >
        Current Company
        <span
          (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'desc',
                column_name: 'current_company'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['current_company'] &&
              mouseOnColumnName == 'current_company'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'asc',
                column_name: 'current_company'
              })
            "
          [hidden]="
              !(
                multipleSorting['current_company'] &&
                multipleSorting['current_company'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'current_company',
                sort_method: 'desc',
                column_name: 'current_company'
              })
            "
          [hidden]="
              !(
                multipleSorting['current_company'] &&
                multipleSorting['current_company'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th scope="col" style="width: 5%">Resume</th>
      <th
        scope="col"
        style="width: 8%"
        (mouseenter)="mouseEnter('projects_count')"
        (mouseleave)="mouseLeave('projects_count')"
      >
        Searches
        <span
          (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'desc',
                column_name: 'projects_count'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['projects_count'] &&
              mouseOnColumnName == 'projects_count'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'asc',
                column_name: 'projects_count'
              })
            "
          [hidden]="
              !(
                multipleSorting['projects_count'] &&
                multipleSorting['projects_count'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'projects_count',
                sort_method: 'desc',
                column_name: 'projects_count'
              })
            "
          [hidden]="
              !(
                multipleSorting['projects_count'] &&
                multipleSorting['projects_count'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 5%"
        (mouseenter)="mouseEnter('strength')"
        (mouseleave)="mouseLeave('strength')"
      >
        Strength
        <span
          (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'desc',
                column_name: 'strength'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['strength'] && mouseOnColumnName == 'strength'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'asc',
                column_name: 'strength'
              })
            "
          [hidden]="
              !(
                multipleSorting['strength'] &&
                multipleSorting['strength'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'strength',
                sort_method: 'desc',
                column_name: 'strength'
              })
            "
          [hidden]="
              !(
                multipleSorting['strength'] &&
                multipleSorting['strength'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 10%"
        (mouseenter)="mouseEnter('in_personal_phone')"
        (mouseleave)="mouseLeave('in_personal_phone')"
      >
        Phone
        <span
          (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'desc',
                column_name: 'in_personal_phone'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['in_personal_phone'] &&
              mouseOnColumnName == 'in_personal_phone'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'asc',
                column_name: 'in_personal_phone'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_personal_phone'] &&
                multipleSorting['in_personal_phone'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'in_personal_phone',
                sort_method: 'desc',
                column_name: 'in_personal_phone'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_personal_phone'] &&
                multipleSorting['in_personal_phone'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 15%"
        (mouseenter)="mouseEnter('in_personal_email')"
        (mouseleave)="mouseLeave('in_personal_email')"
      >
        Email
        <span
          (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'desc',
                column_name: 'in_personal_email'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['in_personal_email'] &&
              mouseOnColumnName == 'in_personal_email'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'asc',
                column_name: 'in_personal_email'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_personal_email'] &&
                multipleSorting['in_personal_email'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'in_personal_email',
                sort_method: 'desc',
                column_name: 'in_personal_email'
              })
            "
          [hidden]="
              !(
                multipleSorting['in_personal_email'] &&
                multipleSorting['in_personal_email'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th
        scope="col"
        style="width: 12%"
        (mouseenter)="mouseEnter('last_contacted_at')"
        (mouseleave)="mouseLeave('last_contacted_at')"
      >
        Last Contacted
        <span
          (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'desc',
                column_name: 'last_contacted_at'
              })
            "
          class="sortbyspanr"
          [hidden]="!iconVisible"
          *ngIf="
              !multipleSorting['last_contacted_at'] &&
              mouseOnColumnName == 'last_contacted_at'
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: grey; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanr"
          (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'asc',
                column_name: 'last_contacted_at'
              })
            "
          [hidden]="
              !(
                multipleSorting['last_contacted_at'] &&
                multipleSorting['last_contacted_at'][1] == 'desc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowUp"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
        <span
          class="sortbyspanl"
          (click)="
              getCandidateList({
                sort_field: 'last_contacted_at',
                sort_method: 'desc',
                column_name: 'last_contacted_at'
              })
            "
          [hidden]="
              !(
                multipleSorting['last_contacted_at'] &&
                multipleSorting['last_contacted_at'][1] == 'asc'
              )
            "
        >
            <fa-icon
              [icon]="faArrowDown"
              style="color: black; font-size: x-large"
            ></fa-icon>
          </span>
      </th>
      <th style="width: 5%">Action</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let cl of candidateList">
      <td style="vertical-align: middle">
        <label style="padding-left: 12px !important">
          <input
            type="checkbox"
            value="{{ cl.id }}"
            (change)="toggleItemSelection(cl.id)"
            [checked]="selectedItems.includes(cl.id)"
          />
        </label>
      </td>

      <td
        (click)="showCandidate(cl.id)"
        onKeyPress="handleKeyPress(event)"
        onKeyDown="handleKeyDown(event)"
        onKeyUp="handleKeyUp(event)"
      >
        <div class="form-check">
          <label class="form-check-label" for="flexCheckDefault">
            <div style="cursor: pointer;" class="top-left-details w-60 d-flex align-middle">
              <div class="top_profile">
                <img src="{{ cl?.in_profile_pic_url }}" alt="" />
              </div>
              <div class="top_right_profile ps-3 d-inline-block">
                <p>
                    <span style="padding-left: 0px">
                      {{ cl?.in_first_name }} {{ cl?.in_last_name }}</span
                    >
                </p>
              </div>
            </div>
          </label>
        </div>
      </td>

      <td
        (click)="showCandidate(cl.id)"
        onKeyPress="handleKeyPress(event)"
        onKeyDown="handleKeyDown(event)"
        onKeyUp="handleKeyUp(event)"
      >
        {{ cl?.current_position }}
      </td>
      <!-- here -->
      <td
        (click)="openClientPage(cl?.client_id)"
        [ngStyle]="{
            color: cl?.client_id ? 'blue' : 'black'
          }"
        onKeyPress="handleKeyPress(event)"
        onKeyDown="handleKeyDown(event)"
        onKeyUp="handleKeyUp(event)"
      >
        {{ cl?.current_company }}
      </td>

      <td>
        <ng-container *ngIf="cl?.resume_documents.length === 0"
        >-
        </ng-container>
        <ng-container
          *ngFor="let doc of cl?.resume_documents; let last = last"
        >
          <a
            title="Click to open resume in new tab"
            target="_blank"
            [href]="doc.path"
          >
            <i class="fa fa-file-text-o" aria-hidden="true"></i>
          </a>
          <span *ngIf="!last">&nbsp;&nbsp;</span>
        </ng-container>
      </td>

      <td title="Click to see searches">
          <span
            class="circle-num"
            (click)="
              openSerchedproject(SearchProject, cl.id, cl.projects_count, cl)
            "
            onKeyPress="handleKeyPress(event)"
            onKeyDown="handleKeyDown(event)"
            onKeyUp="handleKeyUp(event)"
          >{{ cl?.projects_count }}</span
          >
      </td>

      <td (click)="showCandidate(cl.id)">
        <img
          alt=""
          *ngIf="cl.strength"
          src="{{ './assets/img/' + cl.strength + '.png' }}"
        />
      </td>
      <td (click)="showCandidate(cl.id)">
        {{ cl?.in_personal_phone }} <br />
        {{ cl?.in_work_phone }}
      </td>
      <td (click)="showCandidate(cl.id)">
        {{ cl?.in_personal_email }} <br />
        {{ cl?.in_work_email }}
      </td>
      <td (click)="showCandidate(cl.id)">
        {{ (cl?.last_contacted_at | date : "M/d/yyyy") || "-" }}
      </td>

      <td style="vertical-align: middle">
        <a (click)="openDeleteModal(content, cl.id, cl)"
        ><em
          class="bi bi-trash"
          style="margin-left: 9px"
          title="Delete"
        ></em
        ></a>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div style="margin-bottom: 10%" class="pagination-main">
  <label for="totalRows" class="list-totalrecords"
  >Records: 1 to {{ candidateList.length }} of {{ totalRows }}</label
  >
</div>

<!--<div class="pagination-main">-->
<!--  <label for="totalRows" class="list-totalrecords"-->
<!--    >Total Records: {{ totalRows }}</label-->
<!--  >-->
<!--  <ul-->
<!--    *ngIf="pager.pages && pager.pages.length && totalRows > pageSize"-->
<!--    class="pagination justify-content-end m-0"-->
<!--  >-->
<!--    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">-->
<!--      <a-->
<!--        *ngIf="pager.currentPage === 1; else elseFirst"-->
<!--        class="page-link page-show"-->
<!--      >-->
<!--        <<</a-->
<!--      >-->
<!--      <ng-template #elseFirst>-->
<!--        <a (click)="setPage(1)" class="page-link"><<</a>-->
<!--      </ng-template>-->
<!--    </li>-->
<!--    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">-->
<!--      <a-->
<!--        *ngIf="pager.currentPage === 1; else elsePrevious"-->
<!--        class="page-link page-show"-->
<!--      >-->
<!--        <</a-->
<!--      >-->
<!--      <ng-template #elsePrevious>-->
<!--        <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>-->
<!--      </ng-template>-->
<!--    </li>-->

<!--    <li class="page-item">-->
<!--      <a class="page-link">{{ pager.currentPage }}</a>-->
<!--    </li>-->
<!--    <li-->
<!--      class="page-item"-->
<!--      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"-->
<!--    >-->
<!--      <a-->
<!--        *ngIf="pager.currentPage === pager.totalPages; else elseNext"-->
<!--        class="page-link page-show"-->
<!--        >></a-->
<!--      >-->
<!--      <ng-template #elseNext>-->
<!--        <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>-->
<!--      </ng-template>-->
<!--    </li>-->
<!--    <li-->
<!--      class="page-item"-->
<!--      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"-->
<!--    >-->
<!--      <a-->
<!--        *ngIf="pager.currentPage === pager.totalPages; else elseLast"-->
<!--        class="page-link page-show"-->
<!--        >>></a-->
<!--      >-->
<!--      <ng-template #elseLast>-->
<!--        <a (click)="setPage(pager.totalPages)" class="page-link">>></a>-->
<!--      </ng-template>-->
<!--    </li>-->
<!--  </ul>-->
<!--</div>-->

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Candidte</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>Are you sure you want to delete {{ selectDeletedCandidte }} ?</p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-danger dangerbtn"
      (click)="modal.close('Ok click')"
    >
      Delete
    </button>
  </div>
</ng-template>

<ng-template #SearchProject let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Searches of {{ fullname }}
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body scrollsearch">
    <div
      class="experience row"
      *ngFor="let project of projectList; let lastItem = last"
      style="cursor: pointer; padding: 2px"
    >
      <div class="experience-left col-lg-9" style="float: left">
        <h6
          (click)="showProject(project.id); modal.dismiss('Cross click')"
          style="margin-bottom: 2px"
        >
          {{ project.name }} [ {{ project.client }} ]
        </h6>
        <p class="small m-0">
          Updated by {{ project.updated_by }} on
          {{ project.updated_at | date : "MM/dd/yyyy" }}
        </p>
      </div>
      <div class="inner-edit col-lg-2" style="float: right">
        <button
          (click)="showProject(project.id); modal.dismiss('Cross click')"
          type="button"
          class="blue-btn ng-star-inserted btn-sm"
          style="cursor: pointer"
        >
          Open
        </button>
      </div>
      <div class="col-lg-1" style="display: none"></div>
      <hr *ngIf="!lastItem" style="margin-top: 10px" />
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="filter" (click)="modal.close('Save click')">
      Close
    </button>
  </div>
</ng-template>

<ng-template #UpdateTag let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Candidates in Search</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="CreateTagForm">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-12">
          <small class="text-primary">
            Adding <strong>{{ selectedItems.length }}</strong> candidates in to
            selected Search.
          </small>
        </div>
      </div>
      <div class="mb-3">
        <label for="project_main">Searches</label>
        <ng-select
          [items]="projectList2"
          class="form-control form-select"
          placeholder="Select Search"
          bindLabel="dropdown"
          bindValue="id"
          (search)="onProjectSearch($event)"
          formControlName="projectId"
        >
        </ng-select>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="filter"
        (click)="modal.close('Save click')"
        *ngIf="!projectapicall"
      >
        Cancel
      </button>
      <button
        type="button"
        class="blue-btn ms-"
        (click)="attachMultipleCandidatetoSearchForBulkAttach()"
        *ngIf="!projectapicall"
      >
        Add
      </button>
    </div>
  </form>
</ng-template>

<ng-template #UpdateClient let-modal style="width: 1000px">
  <div style="width: 1000px" class="new">
    <div class="row">
      <div class="col-lg-12">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Client</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="
              modal.dismiss('Cross click');
              this.uploadsubmitted = false;
              this.submitted = false;
              resetBackendClienterror()
            "
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <form [formGroup]="CreateClientForm">
          <div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientError"
            >
              {{ createClientError }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorName"
            >
              {{ createClientErrorName }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorIndustry"
            >
              {{ createClientErrorIndustry }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorContactnumber"
            >
              {{ createClientErrorContactnumber }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorEmail"
            >
              {{ createClientErrorEmail }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorAddress"
            >
              {{ createClientErrorAddress }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorPostal"
            >
              {{ createClientErrorPostal }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorState"
            >
              {{ createClientErrorState }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWebsite"
            >
              {{ createClientErrorWebsite }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorDescription"
            >
              {{ createClientErrorDescription }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorPrimarycontact"
            >
              {{ createClientErrorPrimarycontact }}
            </div>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="">Client Name</label>
              <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter client name"
                formControlName="name"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && client['name'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  client['name'] &&
                  client['name'].errors &&
                  client['name'].errors['required']
                "
              >
                Client Name is required.
              </div>
            </div>

            <div class="mb-3">
              <label for="">Account Owner/Originator</label>
              <span class="text-danger" style="font-size: 14px"
              >&nbsp;<strong>*</strong></span
              >
              <ng-select
                [items]="userList"
                class="form-control form-select"
                bindLabel="name"
                bindValue="id"
                formControlName="user_id"
                placeholder="Select Account Owner/Originator"
                (clear)="getUsersList()"
                (search)="getUsersList($event)"
              >
              </ng-select>
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  client['user_id'] &&
                  client['user_id'].errors &&
                  client['user_id'].errors['required']
                "
              >
                Account Owner/Originator is required.
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label for="">Industry</label>
                <input
                  type="text"
                  placeholder="Enter industry"
                  formControlName="industry"
                  class="form-control"
                />
              </div>

              <div class="col-6">
                <label for="">No. of Searches</label>
                <input
                  type="text"
                  formControlName="no_of_searches"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label for="">Client Stage</label>
                <select
                  name=""
                  id=""
                  class="form-select"
                  formControlName="client_status_id"
                >
                  <option value="0">Select Client Stage</option>
                  <option
                    value="{{ cs.id }}"
                    *ngFor="let cs of clientStatusList"
                  >
                    {{ cs.name }}
                  </option>
                </select>
              </div>

              <div class="col-6">
                <label for="">Client Probability</label>
                <select
                  name=""
                  id=""
                  class="form-select"
                  formControlName="client_probability_status_id"
                >
                  <option value="0">Select Probability</option>
                  <option
                    *ngFor="let status of ClientProbabiltylist"
                    [value]="status.id"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-check pt-1 mb-3">
              <input
                class="form-check-input"
                title="is_referenceable"
                type="checkbox"
                value=""
                formControlName="is_referenceable"
              />
              <label
                class="form-check-label"
                title="is_referenceable"
                for="flexCheckDefault"
              >
                Referenceable Client
              </label>
            </div>

            <div class="mb-3">
              <div class="row">
                <label for="" style="margin: 0px">Primary Contact </label>
                <div style="margin-left: 46%; font-size: x-small">OR</div>
                <div class="col-6">
                  <ng-select
                    [items]="candidateList"
                    (change)="populateEmail($event)"
                    (keyup)="populateEmail($event)"
                    class="form-control form-select"
                    bindLabel="full_name"
                    bindValue="full_name"
                    formControlName="primary_contact"
                    placeholder="Pick from Candidates"
                  >
                  </ng-select>
                </div>

                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    formControlName="primary_contact_static"
                    placeholder="Enter Contact Name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Phone</label>
                  <input
                    type="text"
                    maxlength="14"
                    placeholder="Enter contact number"
                    formControlName="contact_number"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['contact_number'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['contact_number'] &&
                      client['contact_number'].errors &&
                      client['contact_number'].errors['pattern']
                    "
                  >
                    Please enter valid phone number.
                  </div>
                </div>

                <div class="mb-3" hidden>
                  <label for="">Candidate Id</label>
                  <input
                    type="text"
                    formControlName="candidate_id"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Email</label>
                  <input
                    type="text"
                    placeholder="Enter email "
                    formControlName="contact_email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['contact_email'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['contact_email'] &&
                      client['contact_email'].errors &&
                      client['contact_email'].errors['pattern']
                    "
                  >
                    Please enter valid Email address.
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="">Street address</label>
              <input
                type="text"
                placeholder="Enter street address"
                formControlName="street_address"
                class="form-control"
              />
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Postal code</label>
                  <input
                    type="text"
                    placeholder="Enter postal code"
                    formControlName="postal_code"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">City</label>
                  <input
                    type="text"
                    placeholder="Enter city"
                    formControlName="city"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['city'].errors
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">State</label>
                  <ng-select
                    [items]="stateList"
                    class="form-control form-select"
                    bindLabel="name"
                    bindValue="name"
                    formControlName="state"
                    placeholder="Select state"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Website</label>
                  <input
                    type="text"
                    placeholder="Enter website"
                    formControlName="website"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="">Description</label>
              <textarea
                type="text"
                placeholder="Enter description"
                formControlName="description"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
              <span class="visually-hidden">Loading...</span>
            </div>
            <button
              type="button"
              class="filter"
              (click)="
                modal.close('Save click');
                this.uploadsubmitted = false;
                this.submitted = false;
                resetBackendClienterror()
              "
              *ngIf="!projectapicall"
            >
              Cancel
            </button>
            <button
              type="button"
              class="blue-btn ms-"
              (click)="UpdateClients()"
              *ngIf="!projectapicall"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <div class="col-lg-1" style="width: 0.33333%">
        <div class="vl"></div>
      </div>

      <div class="col-lg-5" style="padding: 0px 0px 0px 10px">
        <div
          class="modal-header"
          style="border-bottom: unset !important; padding-bottom: 0px"
        >
          <div style="display: flex">
            <h5 class="modal-title" id="modal-basic-title">
              <strong>Documents</strong>
            </h5>
          </div>
        </div>
        <form [formGroup]="CreateClientForm">
          <div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="CreatDocumentsErrorDocuments"
            >
              {{ CreatDocumentsErrorDocuments }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinkone"
            >
              {{ createClientErrorWeblinkone }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinktwo"
            >
              {{ createClientErrorWeblinktwo }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinkthree"
            >
              {{ createClientErrorWeblinkthree }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorLogo"
            >
              {{ createClientErrorLogo }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="CreatDocumentsErrorDocumentsname"
            >
              {{ CreatDocumentsErrorDocumentsname }}
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-8">
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx"
                  placeholder="Document is required"
                  max-file-size="10000000"
                  formControlName="document"
                  class="form-control"
                  (change)="attachFiles2($event)"
                />
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="blue-btn ms-"
                  (click)="UploadDocuments2()"
                  style="float: right"
                  *ngIf="!projectapicall2 && showUploadFileButton"
                >
                  Upload
                </button>
              </div>
            </div>

            <div class="row" style="margin-top: 15px">
              <div class="col-lg-12">
                <div *ngIf="projectapicall">{{ fileName }}</div>
                <div class="row">
                  <div
                    id="panelsStayOpen-collapsesix"
                    class="accordion-collapse collapse show scrolldoc"
                    aria-labelledby="panelsStayOpen-headingsix"
                  >
                    <div
                      class="accordion-body"
                      *ngFor="let document of documentData"
                    >
                      <div class="experience d-flex justify-content-between">
                        <div class="experience-left col-lg-10">
                          <h6 class="modal-title" id="modal-basic-title">
                            <a
                              href="{{ document?.path }}"
                              target="_blank"
                              style="
                                color: gray;
                                text-decoration: unset !important;
                                font-size: 14px;
                              "
                            >
                              {{ document?.name }}</a
                            >
                          </h6>
                        </div>
                        <div
                          class="inner-edit col-lg-2"
                          style="text-align: right"
                        >
                          <a
                            href="javascript:void(0)"
                            (click)="deleteDocuments1(document.id)"
                          >
                            <em
                              class="bi bi-trash"
                              title="Delete"
                              style="color: black"
                            ></em
                            ></a>
                        </div>
                      </div>
                      <hr style="margin-top: 2px" />
                    </div>
                  </div>
                  <div
                    class="accordion-body"
                    *ngIf="documentData?.length == 0"
                    style="margin-left: 20px"
                  >
                    No Documents added
                  </div>

                  <div style="margin-top: 10px">
                    <div class="spinner-border" *ngIf="projectapicall2">
                      <span class="visually-hidden">Uploading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="">Web link 1</label>
                <label for="" *ngIf="showexternallink1 && !editexternallink1"
                ><span
                  (click)="
                      editexternallink1 = true; showexternallink1 = false
                    "
                >{{ weblink1 | slice : 0 : 40
                    }}{{
                      weblink1.length > 40
                        ? "...
                    "
                        : " "
                    }}</span
                ><a
                  href="{{ weblink1 }}"
                  target="_blank"
                  title="Open web link"
                ><em
                  class="fa fa-external-link"
                  aria-hidden="true"
                ></em> </a
                ></label>
                <input
                  form=""
                  *ngIf="!showexternallink1 && editexternallink1"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_one"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_one'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_one'] &&
                    client['web_link_one'].errors &&
                    client['web_link_one'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="">Web link 2</label>
                <label for="" *ngIf="showexternallink2 && !editexternallink2"
                ><span
                  (click)="
                      editexternallink2 = true; showexternallink2 = false
                    "
                >{{ weblink2 | slice : 0 : 40
                    }}{{
                      weblink2.length > 40
                        ? "...
                    "
                        : " "
                    }} </span
                ><a
                  href="{{ weblink2 }}"
                  target="_blank"
                  title="Open web link"
                >
                  <em class="fa fa-external-link" aria-hidden="true"></em> </a
                ></label>

                <input
                  form=""
                  *ngIf="!showexternallink2 && editexternallink2"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_two"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_two'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_two'] &&
                    client['web_link_two'].errors &&
                    client['web_link_two'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="" *ngIf="!showexternallink3">Web link 3</label>
                <label for="" *ngIf="showexternallink3 && !editexternallink3"
                ><span
                  (click)="
                      editexternallink3 = true; showexternallink3 = false
                    "
                >{{ weblink3 | slice : 0 : 40
                    }}{{
                      weblink3.length > 40
                        ? "...
                    "
                        : " "
                    }}</span
                >
                  <a href="{{ weblink3 }}" target="_blank" title="Open web link"
                  ><em
                    class="fa fa-external-link"
                    aria-hidden="true"
                  ></em> </a
                  ></label>

                <input
                  *ngIf="!showexternallink3 && editexternallink3"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_three"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_three'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_three'] &&
                    client['web_link_three'].errors &&
                    client['web_link_three'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div>
                <h5
                  class="modal-title"
                  id="modal-basic-title"
                  style="float: left"
                >
                  <strong>Client Logo</strong>
                </h5>
                <button
                  type="button"
                  class="blue-btn"
                  (click)="selectFile2.click()"
                  *ngIf="logoBox2"
                  style="font-size: 14px !important; float: right"
                >
                  Change
                </button>
              </div>
              <input
                type="file"
                id="file-input2"
                style="display: none"
                accept="image/*"
                #selectFile2
                max-file-size="1024"
                formControlName="logo"
                class="form-control"
                (change)="attachLogoFiles2($event)"
              />
            </div>

            <div
              class="row"
              style="
                margin-top: 10px;
                background-color: #a8d9f7;
                background-position: cover;
                border: 3px solid grey;
                height: 120px;
                width: 384px;
                justify-content: center;
              "
            >
              <div
                *ngIf="rectangleBox"
                id="panelsStayOpen-collapsesix"
                class="accordion-collapse collapse show scrolldocument"
                aria-labelledby="panelsStayOpen-headingsix"
              >
                <label
                  style="position: relative; left: 38%; top: 30%"
                  for="file-input3"
                  class="accordion-body clientlogobg file full-width-label"
                >
                  <span>Choose File</span>
                  <input
                    type="file"
                    id="file-input3"
                    style="display: none"
                    accept="image/*"
                    #selectFile3
                    max-file-size="1024"
                    class="form-control"
                    (change)="attachLogoFiles2($event)"
                  />
                </label>
              </div>
              <div
                class="col-lg-12 row"
                style="margin-top: 10px"
                *ngIf="logoBox2"
              >
                <div class="col-12" style="padding-right: 0px">
                  <img
                    [src]="url2"
                    *ngIf="url2 !== null"
                    height="120"
                    class="clientlogobgimg img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
