<div class="d-flex justify-content-between align-items-center mt-3 mb-3">

  <div class="d-flex align-items-center gap-3">
    <a href="javascript:void(0)" class="radiusbtn d-flex align-items-center" (click)="goToDashboard()">
      <fa-icon [icon]="faArrowLeft" class="me-2"></fa-icon>
      <span>Go back to Dashboard</span>
    </a>

    <h4 class="m-0">Candidates with "{{ searchName }}" status ({{ totalRows }})</h4>
  </div>

  <!-- Right Search Box -->
  <div class="top-right-sec w-45 d-flex" style="width: 20%;" *ngIf="isSearchable">
    <div class="input-group small-search me-3">
      <input type="text" class="form-control" placeholder="Search" tabindex="-1"
             (keyup.enter)="onSearch(inputsearch.value)"   #inputsearch aria-label="Search" />
      <div class="input-group-append rounded">
        <button class="btn" type="button" (click)="onSearch(inputsearch.value)">
          <img src="./assets/img/search-black.svg" alt="" />
        </button>
      </div>
    </div>
    <hr>
  </div>
</div>


<hr style="clear: both;">

<div class="bottom-table">

  <table class="table table-bordered">
    <thead>
    <tr>

      <th scope="col" style="width: 11%; text-align: center;"
          (mouseenter)="mouseEnter('in_last_name')"
          (mouseleave)="mouseLeave('in_last_name')">
        Name
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'in_last_name' })">
    <fa-icon
      *ngIf="sortfield !== 'in_last_name' && mouseOnColumnName === 'in_last_name'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'in_last_name' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'in_last_name' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 11%; text-align: center;"
          (mouseenter)="mouseEnter('project_name')"
          (mouseleave)="mouseLeave('project_name')">
        Search
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'project_name' })">
    <fa-icon
      *ngIf="sortfield !== 'project_name' && mouseOnColumnName === 'project_name'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'project_name' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'project_name' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 9%; text-align: center;">
        Status
      </th>

      <th scope="col" style="width: 12%; text-align: center;"
          (mouseenter)="mouseEnter('strength')"
          (mouseleave)="mouseLeave('strength')">
        Strength
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'strength' })">
    <fa-icon
      *ngIf="sortfield !== 'strength' && mouseOnColumnName === 'strength'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'strength' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'strength' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('current_position')"
          (mouseleave)="mouseLeave('current_position')">
        Current Position
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'current_position' })">
    <fa-icon
      *ngIf="sortfield !== 'current_position' && mouseOnColumnName === 'current_position'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'current_position' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'current_position' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 16%; text-align: center;"
          (mouseenter)="mouseEnter('current_company')"
          (mouseleave)="mouseLeave('current_company')">
        Current Company
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'current_company' })">
    <fa-icon
      *ngIf="sortfield !== 'current_company' && mouseOnColumnName === 'current_company'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'current_company' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'current_company' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 5%; text-align: center;">Resume</th>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('last_contacted_at')"
          (mouseleave)="mouseLeave('last_contacted_at')">
        Last Contacted
        <span class="sortbyspanr"
              (click)="getCandidatesList({ sort_field: 'last_contacted_at' })">
    <fa-icon
      *ngIf="sortfield !== 'last_contacted_at' && mouseOnColumnName === 'last_contacted_at'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'last_contacted_at' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'last_contacted_at' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngIf="candidateList.length === 0">
      <td colspan="9" class="text-center">No candidates available</td>
    </tr>
    <tr *ngFor="let cl of candidateList">

      <td
        [ngStyle]="{
            color: cl?.candidate_id ? 'blue' : 'black'
          }"
        (click)="showCandidate(cl.candidate_id)"
      >
        <div class="form-check">
          <label class="form-check-label" for="flexCheckDefault">
            <div style="cursor: pointer;" class="top-left-details w-60 d-flex align-middle">
              <div class="top_right_profile ps-3 d-inline-block">
                <p>
                    <span style="padding-left: 0px">
                      {{ cl?.in_first_name }} {{ cl?.in_last_name }}</span
                    >
                </p>
              </div>
            </div>
          </label>
        </div>
      </td>
      <td [ngStyle]="{
            color: cl?.project_id ? 'blue' : 'black'
          }"
          (click)="showProject(cl.project_id)">
        {{ cl?.project_name }} {{ cl?.client_name ? '[' + cl.client_name + ']' : '' }}
      </td>
      <td>
        {{ cl?.status_name }}
      </td>
      <td>
        <img
          alt=""
          *ngIf="cl.strength"
          src="{{ './assets/img/' + cl.strength + '.png' }}"
        />
      </td>
      <td>
        {{ cl?.current_position }}
      </td>
      <!-- here -->
      <td
        [ngStyle]="{
            color: cl?.client_id ? 'blue' : 'black'
          }"
      >
        {{ cl?.current_company }}
      </td>

      <td>
        <ng-container *ngIf="!cl?.resume_paths">-</ng-container>
        <ng-container *ngIf="cl?.resume_paths">
          <ng-container *ngFor="let doc of cl.resume_paths.split('!'); let last = last">
            <a title="Click to open resume in new tab" target="_blank" [href]="doc">
              <i class="fa fa-file-text-o" aria-hidden="true"></i>
            </a>
            <span *ngIf="!last">&nbsp;&nbsp;</span>
          </ng-container>
        </ng-container>
      </td>

      <td>
        {{ (cl?.last_contacted_at | date : "M/d/yyyy") || "-" }}
      </td>

    </tr>
    </tbody>
  </table>
</div>

<div style="margin-bottom: 10%" class="pagination-main">
  <label for="totalRows" class="list-totalrecords"
  >Records: {{ candidateList.length }} of {{ totalRows }}</label
  >
</div>
