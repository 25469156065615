import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from '../../common/storage.service';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private notificationUpdate = new Subject<void>();
  private storageKey = 'notificationList';
  constructor(
    private toastrService: ToastrService,
    private httpClient: HttpClient,
    private storageService: StorageService
  ) { }

  showSuccess(message: string): void {
    // this.toastrService.success(message,timeOut: 2000);
    this.toastrService.success(message, "", {
      timeOut: 5000
    });
  }

  showWarning(message: string): void {
    this.toastrService.warning(message,"",{
      timeOut: 5000
    });
  }

  showError(message: string): void {
    this.toastrService.error(message);
  }

  showInfo(message: string): void {
    this.toastrService.info(message);
  }

  showDefault(message: string): void {
    this.toastrService.show(message);
  }

  createNotification(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}notification`, data);
  }

  getNotificationData() {
    const data = localStorage.getItem(this.storageKey);
    return data ? JSON.parse(data) : null;
  }

  setNotificationData(data: any) {
    localStorage.setItem(this.storageKey, JSON.stringify(data));
  }

  clearNotificationData() {
    localStorage.removeItem(this.storageKey);
  }

  getNotificationList(params: any = {}): Observable<any> {
   let httpParams = new HttpParams();
   Object.keys(params).forEach(key => {
     if (params[key] !== undefined && params[key] !== null) {
        httpParams = httpParams.append(key, params[key]);
     }
   });
   return this.httpClient.get<any>(`${environment.apiUrl}notification`, { params: httpParams });
  }

  updateNotification(id:any,data:any) {
    return this.httpClient.put(`${environment.apiUrl}notification/`+id,data);
  }

  deleteNotification(id:number): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}notification/`+id);
  }

  getNotifications(): Observable<any> {
     return this.httpClient.get<any>(`${environment.apiUrl}notification/unread/list`);
  }

  readNotification(id:any) {
    return this.httpClient.post(`${environment.apiUrl}notification/user-read`, id);
  }

  getNotificationUpdateListener() {
    return this.notificationUpdate.asObservable();
  }

  triggerNotificationUpdate() {
    this.notificationUpdate.next();
  }

}
