import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { PagerService } from 'src/app/core/common/pager.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
 import { Subscription } from 'rxjs';
 import { filter } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/users/user.service';
import { birthDateNotFuture } from 'src/app/shared/components/date.validators';
@Component({
  selector: 'app-list',
  templateUrl: './candidate-list.component.html',
  styleUrls: ['./candidate-list.component.css'],
})
export class CandidateListComponent implements OnInit {
  //tooltipHover:any=[];
  private routeSubscription: Subscription = new Subscription();
  FilterForm!: FormGroup;
  candidateList: any = [];
  candidateListId: any = [];
  projectList: any = [];
  genderList: any = [{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }];
  diverseList: any = [
    { name: 'Yes', value: '1' },
    { name: 'No', value: 0 },
  ];
  languageList: any = [];
  tagList: any = [];
  stateList: any = [];
  activePage: number = 1;
  closeResult: string = '';
  total: number = 10;
  to: number = 10;
  deleteId!: number;
  projectId!: number;
  searchClicked: string = '';
  deleteName: any;
  pageTitle: string = 'Candidates';
  isSearchable: boolean = true;
  showCreateButton: string = 'candidate';
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  selectSerchedProject: string | undefined;
  showlength!: number;
  selectDeletedCandidte: string | undefined;
  // for sorting list
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  lastSortedField: string = '';
  lastSortedDirection: string = '';
  isFocused = false;
  filterData: any;
  sortfield: any;
  sortmethod: any;
  multipleSorting: any = [];
  mouseOnColumnName: string = '';
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  rowIndex: any;
  showit!: boolean;
  tdElement: any;
  fullname: any;
  len!: number;
  currentSearchValue: string = '';
  currentAppliedFilter: any;
  showFilterOptions: boolean = false;
  languageSearchValue: string = '';
  sendToSearchDiv: boolean = false;
  projectapicall: boolean = false;
  url2: any;
  get filterForm() {
    return this.FilterForm.controls;
  }
  getSearchList: any;
  selectedItems: number[] = [];
  selectAll: boolean = false;
  isChecked: boolean = false;
  CreateTagForm!: FormGroup;
  submitted: boolean = false;
  projectList2: any = [];
  createTagData: any = {};
  imagepath: string = '';
  strengthId: any;
  imageselected: boolean = false;
  filterStrengthId: string = '';

  showUploadFileButton: boolean = false;
  CreatDocumentsErrorDocumentsname: any;
  createClientErrorLogo: any;
  createClientErrorWeblinkthree: any;
  createClientErrorWeblinkone: any;
  createClientErrorWeblinktwo: any;
  clientList: any = [];
  uploadsubmitted: boolean = false;
  files: any;
  fileName!: string;
  filePath!: string;
  projectapicall2: boolean = false;
  ClientId!: number;
  documentData: any;
  CreateClientForm!: FormGroup;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  ph3 = /[- +()0-9]+/;
  CreatDocumentsErrorDocuments: any;
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorContactnumber: any;
  createClientErrorEmail: any;
  createClientErrorAddress: any;
  createClientErrorPostal: any;
  createClientErrorCity: any;
  createClientErrorState: any;
  createClientErrorWebsite: any;
  createClientErrorDescription: any;
  createClientErrorPrimarycontact: any;
  createClientError: string = '';
  userList: any;
  ClientStatuslist: any = [];
  clientStatusList: any = [];
  ClientProbabiltylist: any = [];
  static_primary_contact: boolean = true;
  CreateCandidateDetailsForm!: FormGroup;
  candidateData: any;
  files2: any;
  fileName2: any;
  filePath2: any;
  rectangleBox: boolean = true;
  logoBox: boolean = false;
  logoBox2: boolean = false;
  openLogoSection: boolean = false;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;
  weblink1: string = '';
  weblink2: string = '';
  weblink3: string = '';
  isSearchClicked: boolean = false;
  isClearClicked: boolean = false;
  resetButtonClicked: boolean = false;
  private hasScrolledToBottom = false;

  @ViewChild('UpdateClient', { static: false }) ViewClientModal: any;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authservice: AuthService,
    private storageService: StorageService,
    private candidateService: CandidateService,
    private tagService: TagserviceService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      contact_email: [null, [Validators.pattern(this.emailPattern)]],
      //contact_number:[null,[Validators.pattern("[0-9 ]{12}")]],
      contact_number: [null, [Validators.pattern(this.ph3)]],
      postal_code: [null],
      street_address: [null],
      description: [null],
      website: [null],
      primary_contact: [null],
      primary_contact_static: [null],
      client_status_id: [0],
      client_probability_status_id: [0],
      no_of_searches: [null],
      web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
      // web_link_one:[null,[this.validateUrl]],
      document: [null],
      logo: [null],
      candidate_id: [null],
      is_referenceable: [null],
    });
  }

  @HostListener('window:scroll', ['$event'])
    onWindowScroll(event: any) {
      if (this.candidateList.length < this.pager.totalItems) {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;

        if (scrollPosition + windowHeight >= documentHeight - 10) {
          if (!this.hasScrolledToBottom) {
            this.hasScrolledToBottom = true;
            this.setPage(this.pager.currentPage + 1);
          }
        } else {
          this.hasScrolledToBottom = false;
        }
      }
    }

  ngOnInit(): void {
    this.onBuildForm();
    this.onLoad();
    //  let listSettings: any = this.storageService.getValue(
    //       StorageKey.listSettings
    //     );
    //     if (listSettings !== 'undefined' && listSettings)
    //       this.getSearchList = JSON.parse(listSettings);
    //     if (this.getSearchList && this.getSearchList.candidates) {
    //       let existing_filter = this.getSearchList.candidates.apply_filter;
    //       if (existing_filter) {
    //         this.showFilterOptions = true;
    //         let apply_filter = existing_filter.split('&');
    //         apply_filter.forEach((e: any) => {
    //           let f_option = e.split('=');
    //           if (f_option[0] == 'current_position')
    //             this.FilterForm.controls['f_position'].setValue(f_option[1]);

    //           if (f_option[0] == 'current_company')
    //             this.FilterForm.controls['f_company'].setValue(f_option[1]);

    //           if (f_option[0] == 'in_last_name')
    //             this.FilterForm.controls['f_past_company'].setValue(f_option[1]);

    //           if (f_option[0] == 'search_city')
    //             this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

    //           if (f_option[0] == 'search_state')
    //             this.FilterForm.controls['f_city'].setValue(f_option[1]);
    //         });

    //         this.getCandidateList(null, this.getSearchList.candidates, false);
    //       }
    //     }

    //     this.pageTitle = localStorage.getItem('page_name') || 'Candidates';
    //     // this.is_public = this.pageTitle === 'Job Posting' ? 1 : 0;
    //     this.setCandidateList(); // Call the function to set project list initially

    //     this.routeSubscription = this.router.events
    //       .pipe(filter((event) => event instanceof NavigationEnd))
    //       .subscribe(() => {
    //         let typeQueryParam =
    //           this.router.routerState.snapshot.root.queryParams['type'] || '';
    //         if (
    //           (typeQueryParam === 'candidate-list' && this.pageTitle === 'Candidates')
    //         ) {
    //           window.location.reload();
    //         }
    //         if (typeQueryParam === 'candidate-list') {
    //           this.pageTitle = 'Candidate';
    //           // this.is_public = 0;
    //         } /* else if (typeQueryParam === 'jobposting') {
    //           this.pageTitle = 'Job Posting';
    //           this.is_public = 1;
    //         } */
    //         this.setCandidateList(); // Call the function to set project list after updating variables
    //       });
  }

  createCandidateform() {
    this.CreateCandidateDetailsForm = this.formBuilder.group({
      id: [null],
      // firstname: [null, Validators.required],
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      gender: [null],
      strength: [null],
      current_company: [null],
      current_position: [null],
      // in_birth_date: [null],
      in_birth_date: [null, [birthDateNotFuture()]],
      in_state: [null],
      in_city: [null],
      in_work_phone: [null],
      // in_personal_email: [null],
      in_personal_email: [null, [Validators.pattern(this.emailPattern)]],
      in_personal_phone: [null],
      in_work_email: [null, [Validators.pattern(this.emailPattern)]],
      country: [null],
      is_in_personal_phone_preffered: [null],
      is_in_personal_email_preffered: [null],
      is_in_work_phone_preffered: [null],
      is_in_work_email_preffered: [null],
      language_ids: [null],
      // linkedin_url:[{value:"",disabled:true}],
      linkedin_url: [null],
      custom_diversity: [null],
      custom_compensation_details: [null],
      source: [null],
    });
  }

  get f() {
    return this.CreateCandidateDetailsForm.controls;
  }
  get client() {
    return this.CreateClientForm.controls;
  }

  // setCandidateList() {
  //   let data = {
  //     page: this.pageNumber,
  //     sort_field: this.currentSortedColumn,
  //     sort_method: this.currentSortedMethod,
  //     multipleSorting: this.multipleSorting,
  //     column_name: this.sortedColumn,
  //     search: this.currentSearchValue,
  //     apply_filter: this.currentAppliedFilter,
  //     user_id: null,
  //   };
  //   this.getCandidateList(null, data, false);
  // }

  onLoad() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    // console.log('page loaded', listSettings)
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.candidates) {
      let existing_filter = this.getSearchList.candidates.apply_filter;
      if (existing_filter) {
        this.showFilterOptions = true;
        let apply_filter = existing_filter.split('&');
        apply_filter.forEach((e: any) => {
          let f_option = e.split('=');
          if (f_option[0] == 'search_current_position')
            this.FilterForm.controls['f_position'].setValue(f_option[1]);

          if (f_option[0] == 'search_current_company')
            this.FilterForm.controls['f_company'].setValue(f_option[1]);

          if (f_option[0] == 'past_company')
            this.FilterForm.controls['f_past_company'].setValue(f_option[1]);

          if (f_option[0] == 'search_in_gender')
            this.FilterForm.controls['f_gender'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_diversity')
            this.FilterForm.controls['f_diversity'].setValue(f_option[1]);

          if (f_option[0] == 'search_custom_compensation')
            this.FilterForm.controls['f_compensation'].setValue(f_option[1]);

          if (f_option[0] == 'language_id')
            this.FilterForm.controls['f_language'].setValue(f_option[1]);

          if (f_option[0] == 'tag_id')
            this.FilterForm.controls['f_tag'].setValue(f_option[1]);

          if (f_option[0] == 'contact_after')
            this.FilterForm.controls['f_contactAfter'].setValue(f_option[1]);

          if (f_option[0] == 'strength')
            this.showfilterimage(
              './assets/img/' + f_option[1] + '.png',
              f_option[1]
            );
        });
      }
       this.pageNumber = 1;
       this.getSearchList.candidates.page = 1;
       this.getCandidateList(null, this.getSearchList.candidates);
    } else {
      this.pageNumber = 1;
      this.getCandidateList({});
    }
  }

  // this.setCandidateList();

  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  get createTagForm() {
    return this.CreateTagForm.controls;
  }

  open(content: any, EditId: number) {
    if (content === 'openClient') {
      this.router.navigate([`/client/${EditId}`]);
    } else {
      this.getclientprobability();
      this.getclientStatus();
      this.getClientStatuses();
      this.getStateList();
      this.showclient(EditId);
    }
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
  }

  getDocumentData() {
    this.clientService.getDocumentData(this.ClientId).subscribe({
      next: (response) => {
        this.documentData = response.client.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getclientStatus() {
    this.clientService.getClientStatus().subscribe({
      next: (response: any) => {
        this.ClientStatuslist = response.client_statuses.data;
      },
      error: (error: any) => {},
    });
  }

  attachFiles2(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
    this.showUploadFileButton = true;
  }

  deleteDocuments1(deleteId: any) {
    this.clientService.deleteDocument(this.ClientId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();
      },
      error: (err: any) => {},
    });
  }

  attachLogoFiles2(event: any) {
    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox2 = true;
      this.rectangleBox = false;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url2 = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  showclient(clientid: number) {
    this.clientService.getClientData({ id: clientid }).subscribe({
      next: (data) => {
        const response = data.client;
        this.ClientId = data.client.id;
        this.getCandidatePhoneNumber();
        this.getDocumentData();
        this.getStateList();
        this.getUsersList({ term: response.owner?.name });
        this.url2 = data.client.logo_path;
        this.rectangleBox = true;
        this.openLogoSection = false;
        if (this.url2 == null) {
          this.rectangleBox = true;
          this.logoBox2 = false;
        } else if (this.url2 != null) {
          this.rectangleBox = false;
          this.logoBox2 = true;
        }

        if (response.web_link_one !== 'null' && response.web_link_one != null) {
          this.CreateClientForm.controls['web_link_one'].setValue(
            response.web_link_one
          );
          this.showexternallink1 = true;
          this.weblink1 = response.web_link_one;
          this.editexternallink1 = false;
        } else {
          this.CreateClientForm.controls['web_link_one'].setValue('');
          this.showexternallink1 = false;
          this.weblink1 = '';
          this.editexternallink1 = true;
        }

        if (response.website !== 'null' && response.website != null) {
          this.CreateClientForm.controls['website'].setValue(response.website);
        } else {
          this.CreateClientForm.controls['website'].setValue('');
        }

        if (response.web_link_two !== 'null' && response.web_link_two != null) {
          this.CreateClientForm.controls['web_link_two'].setValue(
            response.web_link_two
          );
          this.showexternallink2 = true;
          this.weblink2 = response.web_link_two;
          this.editexternallink2 = false;
        } else {
          this.CreateClientForm.controls['web_link_two'].setValue('');
          this.showexternallink2 = false;
          this.weblink2 = '';
          this.editexternallink2 = true;
        }

        if (
          response.web_link_three !== 'null' &&
          response.web_link_three != null
        ) {
          this.CreateClientForm.controls['web_link_three'].setValue(
            response.web_link_three
          );
          this.showexternallink3 = true;
          this.weblink3 = response.web_link_three;
          this.editexternallink3 = false;
        } else {
          this.CreateClientForm.controls['web_link_three'].setValue('');
          this.showexternallink3 = false;
          this.weblink3 = '';
          this.editexternallink3 = true;
        }

        if (response.description !== 'null' && response.description != null) {
          this.CreateClientForm.controls['description'].setValue(
            response.description
          );
        } else {
          this.CreateClientForm.controls['description'].setValue('');
        }

        if (response.name == null) {
          this.CreateClientForm.controls['name'].setValue(null);
        } else {
          this.CreateClientForm.controls['name'].setValue(response.name);
        }

        if (response.owner == null) {
          this.CreateClientForm.controls['user_id'].setValue(null);
        } else {
          this.CreateClientForm.controls['user_id'].setValue(response.owner.id);
        }

        if (response.industry !== 'null' && response.industry != null) {
          this.CreateClientForm.controls['industry'].setValue(
            response.industry
          );
        } else {
          this.CreateClientForm.controls['industry'].setValue('');
        }

        if (
          response.projects_count !== 'null' &&
          response.projects_count != null
        ) {
          this.CreateClientForm.controls['no_of_searches'].setValue(
            response.projects_count
          );
        } else {
          this.CreateClientForm.controls['no_of_searches'].setValue('');
        }
        this.client['no_of_searches'].disable();

        if (response.no !== 'null' && response.client_status_id != 0) {
          this.CreateClientForm.controls['client_status_id'].setValue(
            response.client_status_id
          );
        } else {
          this.CreateClientForm.controls['client_status_id'].setValue(0);
        }

        // Proability staus Id
        if (
          response.no !== 'null' &&
          response.client_probability_status_id != 0
        ) {
          this.CreateClientForm.controls[
            'client_probability_status_id'
          ].setValue(response.client_probability_status_id);
        } else {
          this.CreateClientForm.controls[
            'client_probability_status_id'
          ].setValue(0);
        }

        if (
          response.street_address !== 'null' &&
          response.street_address != null
        ) {
          this.CreateClientForm.controls['street_address'].setValue(
            response.street_address
          );
        } else {
          this.CreateClientForm.controls['street_address'].setValue('');
        }

        if (response.postal_code !== 'null' && response.postal_code != null) {
          this.CreateClientForm.controls['postal_code'].setValue(
            response.postal_code
          );
        } else {
          this.CreateClientForm.controls['postal_code'].setValue('');
        }

        if (response.city !== 'null' && response.city != null) {
          this.CreateClientForm.controls['city'].setValue(response.city);
        } else {
          this.CreateClientForm.controls['city'].setValue('');
        }

        if (response.state !== 'null' && response.state != null) {
          this.CreateClientForm.controls['state'].setValue(response.state);
        } else {
          this.CreateClientForm.controls['state'].setValue(null);
        }

        if (
          response.contact_email !== 'null' &&
          response.contact_email != null
        ) {
          this.CreateClientForm.controls['contact_email'].setValue(
            response.contact_email
          );
        } else {
          this.CreateClientForm.controls['contact_email'].setValue('');
        }

        if (
          response.contact_number !== 'null' &&
          response.contact_number != null
        ) {
          this.CreateClientForm.controls['contact_number'].setValue(
            response.contact_number
          );
        } else {
          this.CreateClientForm.controls['contact_number'].setValue('');
        }

        if (response.candidate_id == null) {
          this.CreateClientForm.controls['candidate_id'].setValue('');
        } else {
          this.CreateClientForm.controls['candidate_id'].setValue(
            response.candidate_id
          );
        }

        this.CreateClientForm.controls['ClientId'].setValue(response.id);

        if (
          response.primary_contact !== 'null' &&
          response.primary_contact != null
        ) {
          this.CreateClientForm.controls['primary_contact_static'].setValue(
            response.primary_contact
          );
          this.CreateClientForm.controls['primary_contact'].setValue(
            response.primary_contact
          );
          this.client['primary_contact_static'].disable();
        } else {
        }

        this.CreateClientForm.controls['is_referenceable'].setValue(
          response.is_referenceable !== null ? response.is_referenceable : null
        );

        this.modalService
          .open(this.ViewClientModal, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md',
          })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              //${this.getDismissReason(reason)}
              this.closeResult = `Dismissed `;
            }
          );
      },

      error: (err) => {
        console.error('Error fetching client data:', err);
      },
    });
  }

  getclientprobability() {
    this.clientService.getclientprobability().subscribe({
      next: (response: any) => {
        this.ClientProbabiltylist = response.client_probability_statuses.data;
      },
      error: (error) => {},
    });
  }

  UpdateClients() {
    this.submitted = true;

    if (this.CreateClientForm.invalid) {
      return;
    }

    let UpdatedClientDetails = {
      id: this.CreateClientForm.controls['ClientId'].value,
      name: this.CreateClientForm.controls['name'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,
      industry: this.CreateClientForm.controls['industry'].value,
      city: this.CreateClientForm.controls['city'].value,
      state: this.CreateClientForm.controls['state'].value,
      contact_email: this.CreateClientForm.controls['contact_email'].value,
      contact_number: this.CreateClientForm.controls['contact_number'].value,
      postal_code: this.CreateClientForm.controls['postal_code'].value,
      street_address: this.CreateClientForm.controls['street_address'].value,
      description: this.CreateClientForm.controls['description'].value,
      website: this.CreateClientForm.controls['website'].value,
      primary_contact: this.CreateClientForm.controls['primary_contact'].value
        ? this.CreateClientForm.controls['primary_contact'].value
        : this.CreateClientForm.controls['primary_contact_static'].value,
      client_status_id:
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value,
      web_link_one: this.CreateClientForm.controls['web_link_one'].value,
      web_link_two: this.CreateClientForm.controls['web_link_two'].value,
      web_link_three: this.CreateClientForm.controls['web_link_three'].value,
      candidate_id: this.CreateClientForm.controls['candidate_id'].value,
      client_probability_status_id:
        this.CreateClientForm.controls['client_probability_status_id'].value ==
        null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id']
              .value,
      is_referenceable: this.CreateClientForm.controls['is_referenceable'].value
        ? 1
        : 0,
    };

    this.projectapicall = true;
    const formData: FormData = new FormData();

    if (this.fileName2 != null) {
      formData.append('logoname', this.fileName2);
      formData.append('logo', this.files2);
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append(
        'contact_email',
        this.CreateClientForm.controls['contact_email'].value
      );
      formData.append(
        'contact_number',
        this.CreateClientForm.controls['contact_number'].value
      );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      formData.append(
        'primary_contact',
        this.CreateClientForm.controls['primary_contact'].value
          ? this.CreateClientForm.controls['primary_contact'].value
          : this.CreateClientForm.controls['primary_contact_static'].value
      );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      formData.append(
        'candidate_id',
        this.CreateClientForm.controls['candidate_id'].value
      );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    } else if (this.fileName2 == null) {
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append(
        'contact_email',
        this.CreateClientForm.controls['contact_email'].value
      );
      formData.append(
        'contact_number',
        this.CreateClientForm.controls['contact_number'].value
      );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      formData.append(
        'primary_contact',
        this.CreateClientForm.controls['primary_contact'].value
          ? this.CreateClientForm.controls['primary_contact'].value
          : this.CreateClientForm.controls['primary_contact_static'].value
      );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      formData.append(
        'candidate_id',
        this.CreateClientForm.controls['candidate_id'].value
      );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    }

    this.clientService
      .updateClientData(UpdatedClientDetails.id, formData)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.CreateClientForm.reset();
          this.notificationService.showSuccess(response.message);
          //  window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
          // this.onLoad();
          // window.location.reload();
          //  this.getClientList({});
          for (let i = 0; i < this.clientList.length; i++) {
            if (this.clientList[i].id === UpdatedClientDetails.id) {
              this.clientList[i] = response.client;
              break;
            }
          }
        },
        error: (error) => {
          this.projectapicall = false;
          this.createClientError = error.error.message;
          // this.createClientError = error.error.url;
          if (error.status === 422) {
            if (error.error.name) {
              this.createClientErrorName = error.error.name[0];
            }
            if (error.error.industry) {
              this.createClientErrorIndustry = error.error.industry[0];
            }
            if (error.error.contact_number) {
              this.createClientErrorContactnumber =
                error.error.contact_number[0];
            }
            if (error.error.contact_email) {
              this.createClientErrorEmail = error.error.contact_email[0];
            }
            if (error.error.street_address) {
              this.createClientErrorAddress = error.error.street_address[0];
            }
            if (error.error.postal_code) {
              this.createClientErrorPostal = error.error.postal_code[0];
            }
            if (error.error.city) {
              this.createClientErrorCity = error.error.city[0];
            }
            if (error.error.state) {
              this.createClientErrorState = error.error.state[0];
            }
            if (error.error.website) {
              this.createClientErrorWebsite = error.error.website[0];
            }
            if (error.error.description) {
              this.createClientErrorDescription = error.error.description[0];
            }
            if (error.error.primary_contact) {
              this.createClientErrorPrimarycontact =
                error.error.primary_contact[0];
            }
            if (error.error.web_link_one) {
              this.createClientErrorWeblinkone = error.error.web_link_one[0];
            }
            if (error.error.web_link_two) {
              this.createClientErrorWeblinktwo = error.error.web_link_two[0];
            }
            if (error.error.web_link_three) {
              this.createClientErrorWeblinkthree =
                error.error.web_link_three[0];
            }
            if (error.error.logo) {
              this.createClientErrorLogo = error.error.logo[0];
            }
          }
        },
      });
  }

  getCandidatePhoneNumber(selected_value = '') {
    this.candidateService.listByFilter({ search: selected_value }).subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;
        this.candidateList.forEach((element: any) => {
          element.full_name =
            element.in_first_name +
            ' ' +
            element.in_last_name +
            ' ' +
            (element.current_company
              ? '[' + element.current_company + ']'
              : '');
        });
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  populateEmail(event: any) {
    if (event && event.id != undefined) {
      this.f['primary_contact_static'].disable();
      this.static_primary_contact = false;
      this.candidateService.candidateDetail(event.id).subscribe({
        next: (response: any) => {
          this.candidateData = response.candidate;
          this.f['contact_number'].setValue('');
          this.f['contact_email'].setValue('');
          this.f['primary_contact_static'].setValue('');
          this.f['candidate_id'].setValue('');

          if (this.candidateData.is_in_personal_email_preffered) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.is_in_work_email_preffered) {
            this.f['contact_email'].setValue(event.in_work_email);
          } else if (this.candidateData.in_personal_email) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.in_work_email) {
            this.f['contact_email'].setValue(event.in_work_email);
          }

          if (this.candidateData.is_in_personal_phone_preffered) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.is_in_work_phone_preffered) {
            this.f['contact_number'].setValue(event.in_work_phone);
          } else if (this.candidateData.in_personal_phone) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.in_work_phone) {
            this.f['contact_number'].setValue(event.in_work_phone);
          }

          this.f['primary_contact_static'].setValue(event.full_name);
          this.f['candidate_id'].setValue(event.id);

          this.getCandidatePhoneNumber();
        },
        error: () => {},
      });
    } else {
      this.f['primary_contact_static'].enable();
      /*  if((event.type != "keyup") && (event.type == "blur" && this.f['primary_contact'].value != "")){
         this.static_primary_contact=true;
         this.f['primary_contact'].setValue("");
       }
       this.candidateData="";
       if(event.type != "blur")
       this.f['primary_contact_static'].setValue(event.target.value); */
      if (event && event.target)
        this.getCandidatePhoneNumber(event.target.value);
      this.f['contact_number'].setValue('');
      this.f['contact_email'].setValue('');
      this.f['primary_contact_static'].setValue('');
      this.f['candidate_id'].setValue('');
    }
  }

  getClientStatuses() {
    this.clientService.clientStatusListByFilter({}).subscribe({
      next: (response) => {
        this.clientStatusList = response.client_statuses.data;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getUsersList(term: any = null) {
    let currentTenant = this.storageService.getValue(
      StorageKey.currentTenantId
    );
    if (currentTenant == '0') {
      return;
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(
          StorageKey.currentUser
        );
        let currentUser = JSON.parse(loginUserDetail);
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(
            StorageKey.currentUserid
          );
          let isUserIdInList: boolean = false;
          this.userList.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          });
          if (!isUserIdInList) {
            this.userList.push(currentUser);
          }
        }
      },
      error: (error) => {},
    });
  }

  UploadDocuments2() {
    this.showUploadFileButton = false;
    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    this.projectapicall2 = true;

    this.clientService.createDocument(this.ClientId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall2 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.CreateClientForm.controls['document'].setValue(null);
      },
      error: (error) => {
        this.projectapicall2 = false;
        if (error.status === 422) {
          if (error.error.document) {
            this.CreatDocumentsErrorDocuments = error.error.document[0];
          }

          if (error.error.name) {
            this.CreatDocumentsErrorDocumentsname = error.error.name[0];
          }
        }
      },
    });
  }

  onBuildForm() {
    this.CreateTagForm = this.formBuilder.group({
      projectId: [null],
    });

    this.FilterForm = this.formBuilder.group({
      f_position: [null],
      f_gender: [null],
      f_diversity: [null],
      f_city: [null],
      f_language: [null],
      f_state: [null],
      f_company: [null],
      f_past_company: [null],
      f_tag: [null],
      f_compensation: [null],
      f_contactAfter: [null],
    });
  }

  getStateList() {
    this.projectService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getLanguageList(searchTerm: any = '') {
    let data: any;
    if (searchTerm.length >= 2) data = { search: this.languageSearchValue };
    this.candidateService.languageList(data).subscribe({
      next: (response) => {
        this.languageList = response;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getTagList(searchTerm: any = '') {
    let data: any;
    if (searchTerm.length >= 2) data = { search: this.languageSearchValue };
    this.tagService.searchTagList(data).subscribe({
      next: (response: any) => {
        if (response && response.tags.data) this.tagList = response.tags.data;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getCandidateList(
    data1: any,

    data = {
      page: this.pageNumber,
      pageSize: this.pageSize,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: this.currentSearchValue,
      apply_filter: this.currentAppliedFilter,
      multipleSorting: this.multipleSorting,
      user_id: null,
    },
    reset: boolean = false
  ) {
    console.log(this.currentAppliedFilter);
    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
      if (data1.sort_field && data1.sort_method) {
        this.multipleSorting[data1.sort_field] = [
          data1.sort_field,
          data1.sort_method,
        ];
        data.multipleSorting = this.multipleSorting;
      }
    }

    if (!reset) {
      if (this.getSearchList) {
        this.currentSearchValue = data.search;
        this.getSearchList.candidates = data;
        this.storageService.setValue(
          'listSettings',
          JSON.stringify(this.getSearchList)
        );
      } else
        this.storageService.setValue(
          'listSettings',
          JSON.stringify({ candidates: data })
        );
    } else {
      data.search = "";
      data.page = 1;
    }

    let userRole = this.storageService.getValue(StorageKey.currentUserRoleId);
    let currentUserId: any = this.storageService.getValue(
      StorageKey.currentUserid
    );

    const listSetting = localStorage.getItem('listSettings');
    let parsedList: any;
    if (listSetting) {
      parsedList = JSON.parse(listSetting).candidates;
    }

    if (parsedList) {
      parsedList.sort_field = parsedList.sort_field || '';
      parsedList.sort_method = parsedList.sort_method || '';
    }

    if (parsedList) {
      if (parsedList.sort_method && parsedList.sort_method !== '') {
        if (
          (!this.lastSortedField && parsedList.sort_field) ||
          this.lastSortedField !== parsedList.sort_field ||
          this.lastSortedDirection !== parsedList.sort_method ||
          this.isSearchClicked ||
          this.isClearClicked ||
          this.resetButtonClicked
        ) {
          data.page = 1;
        }
      }
    }

    if (userRole == '2') {
      data.user_id = currentUserId;
    }

    this.candidateService.listByFilter(data).subscribe({
      next: (response) => {
        if (
          this.isSearchClicked ||
          this.isClearClicked ||
          this.resetButtonClicked
        ) {
          this.candidateList = response.candidates.data;
        } else if (data.page === 1) {
          this.candidateList = response.candidates.data;
        } else {
          this.candidateList = [...this.candidateList, ...response.candidates.data];
        }

        if (parsedList && parsedList.sort_field) {
          this.lastSortedField = parsedList.sort_field;
          this.lastSortedDirection = parsedList.sort_method;
        }

        this.isSearchClicked = false;
        this.isClearClicked = false;
        this.resetButtonClicked = false;

        this.pageNumber = response.candidates.current_page;
        this.totalRows = response.candidates.total;
        this.pager = this.pagerService.getPager(
          this.totalRows,
          this.pageNumber
        );

        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;
          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
          }
          this.sortfield = response.candidates.sort_field;
          this.sortmethod = response.candidates.sort_method;
        }
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  // getCandidateList(
  //   data1: any,
  //   data = {
  //     page: this.pageNumber,
  //     sort_field: this.currentSortedColumn,
  //     sort_method: this.currentSortedMethod,
  //     column_name: this.sortedColumn,
  //     apply_filter: this.currentAppliedFilter,
  //     search: this.currentSearchValue,
  //     multipleSorting: this.multipleSorting,
  //   },
  //   reset: boolean = false
  // ) {
  //   if (data1) {
  //     data.sort_field = data1.sort_field;
  //     data.sort_method = data1.sort_method;
  //     data.column_name = data1.column_name;
  //     // if (data1.sort_field && data1.sort_method) {
  //     //   this.multipleSorting[data1.sort_field] = [
  //     //     data1.sort_field,
  //     //     data1.sort_method,
  //     //   ];
  //     //   data.multipleSorting = this.multipleSorting;
  //     // }
  //   }
  //   this.filterData = data;
  //   //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}
  //   if (!reset) {
  //     if (this.getSearchList) {
  //       // this.currentSearchValue = data.search;
  //       this.getSearchList.candidates = data;
  //       this.storageService.setValue(
  //         'listSettings',
  //         JSON.stringify(this.getSearchList)
  //       );
  //     } else
  //       this.storageService.setValue(
  //         'listSettings',
  //         JSON.stringify({ candidates: data })
  //       );
  //   } else {
  //     // data.search = '';
  //     data.page = 1;
  //   }

  //   const listSetting = localStorage.getItem('listSettings');
  //   let parsedList: any;
  //   if (listSetting) {
  //     parsedList = JSON.parse(listSetting).candidates;
  //   }

  //   if (parsedList) {
  //     parsedList.sort_field = parsedList.sort_field || '';
  //     parsedList.sort_method = parsedList.sort_method || '';
  //   }

  //   if (parsedList) {
  //     if (parsedList.sort_method && parsedList.sort_method !== '') {
  //       if (
  //         (!this.lastSortedField && parsedList.sort_field) ||
  //         this.lastSortedField !== parsedList.sort_field ||
  //         this.lastSortedDirection !== parsedList.sort_method ||
  //         this.isSearchClicked ||
  //         this.isClearClicked ||
  //         this.resetButtonClicked
  //       ) {
  //         data.page = 1;
  //       }
  //     }
  //   }

  //   this.candidateService.listByFilter(data).subscribe({
  //     next: (response) => {
  //       this.candidateListId = response.candidates_ids;
  //       this.pageTitle = 'Candidates'
  //       console.log('lastSortedField: ',this.lastSortedField,'----------','lastSortedDirection: ',this.lastSortedDirection);
  //       console.log('sort_field',parsedList.sort_field, '----------','sort_direction',parsedList.sort_direction);
  //       // console.log('isSearchClicked',this.isSearchClicked, 'isClearClicked', this.isClearClicked,'resetButtonClicked', this.resetButtonClicked);
  //       if (
  //         this.isSearchClicked ||
  //         this.isClearClicked ||
  //         this.resetButtonClicked
  //       ) {
  //         this.candidateList = response.candidates.data;
  //         console.log('condition 1')
  //       } else {
  //         if (
  //           (!this.lastSortedField && parsedList.sort_field) ||
  //           (this.lastSortedField &&
  //             this.lastSortedField !== parsedList.sort_field) ||
  //           (this.lastSortedDirection &&
  //             this.lastSortedDirection != parsedList.sort_method)
  //         ) {
  //         console.log('condition 2')
  //         this.candidateList = response.candidates.data;
  //         } else {
  //           console.log('default condition')
  //         this.candidateList = [...this.candidateList, ...response.candidates.data];
  //         }
  //       }

  //       if (parsedList && parsedList.sort_field) {
  //         this.lastSortedField = parsedList.sort_field;
  //         this.lastSortedDirection = parsedList.sort_method;
  //       }
  //       this.isSearchClicked = false;
  //       this.isClearClicked = false;
  //       this.resetButtonClicked = false;

  //       this.pageNumber = response.candidates.current_page;
  //       this.totalRows = response?.candidates?.total;
  //       this.pager = this.pagerService.getPager(
  //         this.totalRows,
  //         this.pageNumber
  //       );

  //       if (data.sort_field) {
  //         this.iconVisible = false;
  //         this.staticarrowsVisible = true;
  //         this.currentSortedColumn = data.column_name;
  //         this.sortedColumn = data.column_name;
  //         if (data.sort_method === 'desc') {
  //           this.iconVisibleDesc = true;
  //           this.iconVisibleAsc = false;
  //         }
  //         if (data.sort_method === 'asc') {
  //           this.iconVisibleAsc = true;
  //           this.iconVisibleDesc = false;
  //         }
  //         this.sortfield = response.candidates.sort_field;
  //         this.sortmethod = response.candidates.sort_method;
  //       }
  //     },
  //     error: (error) => {
  //       console.log(error);
  //       //this.notificationService.showError(error.message);
  //     },
  //   });

  //   // this.candidateService.listByFilter(data).subscribe({
  //   //   next: (response) => {
  //   //     this.candidateListId = response.candidates_ids;
  //   //     this.pageNumber = response.candidates.current_page;
  //   //     if(this.pageNumber == 1) {
  //   //       this.candidateList = response.candidates.data;
  //   //     } else{
  //   //       this.candidateList = [...this.candidateList, ...response.candidates.data];
  //   //       console.log('scroll---');
  //   //     }
  //   //     this.totalRows = response.candidates.total;
  //   //     this.pager = this.pagerService.getPager(
  //   //       this.totalRows,
  //   //       this.pageNumber
  //   //     );
  //   //     if (data.sort_field) {
  //   //       this.iconVisible = false;
  //   //       this.staticarrowsVisible = true;
  //   //       this.currentSortedColumn = data.column_name;
  //   //       this.sortedColumn = data.column_name;
  //   //       if (data.sort_method === 'desc') {
  //   //         this.iconVisibleDesc = true;
  //   //         this.iconVisibleAsc = false;
  //   //       }
  //   //       if (data.sort_method === 'asc') {
  //   //         this.iconVisibleAsc = true;
  //   //         this.iconVisibleDesc = false;
  //   //       }
  //   //       this.sortfield = response.candidates.sort_field;
  //   //       this.sortmethod = response.candidates.sort_method;
  //   //     }
  //   //   },
  //   //   error: (error) => {
  //   //     this.notificationService.showError(error.message);
  //   //   },
  //   // });
  // }

  resetBackendClienterror() {
    this.createClientErrorName = '';
    this.createClientErrorIndustry = '';
    this.createClientErrorContactnumber = '';
    this.createClientErrorEmail = '';
    this.createClientErrorAddress = '';
    this.createClientErrorPostal = '';
    this.createClientErrorCity = '';
    this.createClientErrorState = '';
    this.createClientErrorWebsite = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorCity = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorWeblinkone = '';
    this.createClientErrorWeblinktwo = '';
    this.createClientErrorWeblinkthree = '';
    this.createClientErrorLogo = '';
    this.CreatDocumentsErrorDocuments = '';
    this.createClientError = '';
    this.CreatDocumentsErrorDocumentsname = '';
    this.CreateClientForm.reset();
  }

  resetListSettings() {
    this.resetButtonClicked = true;
    this.lastSortedField = '';
    this.pageNumber = 1;
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    console.log('listSettings', listSettings);
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.candidates) {
      this.lastSortedDirection = this.getSearchList.candidates.sort_method;
      delete this.getSearchList.candidates;
      this.storageService.setValue(
        'listSettings',
        JSON.stringify(this.getSearchList)
      );
    }
    this.currentSearchValue = '';
    this.multipleSorting = [];
    this.FilterForm.reset();
    this.currentAppliedFilter = null;
    this.imageselected = false;
    this.showFilterOptions = false;
    this.getCandidateList({}, undefined, true);
  }

  openSerchedProjectView(projectId: number, i: number, count: number) {
    this.projectId = projectId;
    if (count > 0) {
      this.candidateService
        .showProjectsCandidatewise(this.projectId)
        .subscribe({
          next: (response) => {
            this.projectList = response.projects;
          },
          error: (error) => {
            this.notificationService.showError(error.message);
          },
        });
    }
  }

  applyFilter(page: number = 1) {
    let apply_filter = '';
    this.pageNumber = page;
    if (this.FilterForm.controls['f_position'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_current_position=' +
        this.FilterForm.controls['f_position'].value;
    }

    if (this.FilterForm.controls['f_company'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_current_company=' + this.FilterForm.controls['f_company'].value;
    }

    if (this.FilterForm.controls['f_past_company'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'past_company=' + this.FilterForm.controls['f_past_company'].value;
    }

    if (this.FilterForm.controls['f_gender'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_in_gender=' + this.FilterForm.controls['f_gender'].value;
    }

    if (this.FilterForm.controls['f_city'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_in_city=' + this.FilterForm.controls['f_city'].value;
    }

    if (this.FilterForm.controls['f_state'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_in_state=' + this.FilterForm.controls['f_state'].value;
    }

    if (this.FilterForm.controls['f_diversity'].value != undefined) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_custom_diversity=' +
        this.FilterForm.controls['f_diversity'].value;
    }

    if (this.FilterForm.controls['f_compensation'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'search_custom_compensation=' +
        this.FilterForm.controls['f_compensation'].value;
    }

    if (this.FilterForm.controls['f_language'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'language_id=' + this.FilterForm.controls['f_language'].value;
    }

    if (this.FilterForm.controls['f_tag'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter += 'tag_id=' + this.FilterForm.controls['f_tag'].value;
    }

    if (this.FilterForm.controls['f_contactAfter'].value) {
      if (apply_filter != '') apply_filter += '&';
      apply_filter +=
        'contact_after=' + this.FilterForm.controls['f_contactAfter'].value;
    }

    if (this.filterStrengthId) {
      if (apply_filter != '') apply_filter += '&';

      apply_filter += 'strength=' + this.filterStrengthId;
    }
    this.currentAppliedFilter = apply_filter;

    let data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      apply_filter: apply_filter,
    };

    this.getCandidateList(data);
  }

  openClientPage(clientId:any){
    if(!clientId){
      return;
    }
    this.router.navigate(['/client/'+clientId]);
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = '';
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedCandidte = value.in_first_name + ' ' + value.in_last_name;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          this.deleteCandidate(deleteId);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  deleteCandidate(deleteId: number) {
    this.candidateService.deleteCandidateData(deleteId).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.getCandidateList({});
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  showCandidate(id: number) {
    this.router.navigate(['/candidate/' + id]);
  }

  public showFilter() {
    this.getStateList();
    this.showFilterOptions = !this.showFilterOptions;
    if (!this.showFilterOptions) {
      this.currentAppliedFilter = null;
    }
  }

  public clearFilterForm() {
    this.isClearClicked = true;
    this.currentAppliedFilter = '';
    this.FilterForm.reset();
    this.showFilterOptions = false;
    this.getCandidateList({});
  }

  public searchData(value: any) {
    this.isSearchClicked = true;
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getCandidateList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: value,
    });
  }

  setPage(page: number) {
    this.pageNumber = page;
    this.applyFilter(page);
  }

  showProject(id: number) {
    this.router.navigate(['/search/' + id]);
  }

  openSerchedproject(
    content: any,
    projectId: number,
    count: number,
    value: any
  ) {
    if (count > 0) {
      this.selectSerchedProject = value.name;
      this.fullname = value?.in_first_name + ' ' + value?.in_last_name;
      this.serchedProjectViewCandidatewise(projectId, count);
      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  serchedProjectViewCandidatewise(projectId: number, count: number) {
    this.projectId = projectId;

    this.projectList = [];
    if (count > 0) {
      this.candidateService
        .showProjectsCandidatewise(this.projectId)
        .subscribe({
          next: (response) => {
            this.projectList = response.projects;

            let length = this.projectList.length;
            this.len = length - 1;
            this.showlength = this.len;
          },
          error: (error) => {
            this.notificationService.showError(error.message);
          },
        });
    }
  }

  toggleItemSelection(id: number) {
    if (this.selectedItems.includes(id)) {
      this.selectedItems = this.selectedItems.filter((item) => item !== id);
    } else {
      this.selectedItems.push(id);
    }

    this.sendToSearchDiv = this.selectedItems.length > 0;

    // Move the sort operation to separate variables
    const sortedSelectedItems = this.selectedItems.sort().join(',');
    const sortedCandidateListId = this.candidateListId.sort().join(',');

    if (sortedSelectedItems !== sortedCandidateListId) {
      this.selectAll = false;
    }
  }

  toggleSelectAll() {
    if (this.selectAll) {
      // If "Select All" checkbox is checked, select all items
      this.selectedItems = this.candidateListId.map(
        (element: number) => element
      );
    } else {
      // If "Select All" checkbox is unchecked, clear the selection
      this.selectedItems = [];
    }
    this.sendToSearchDiv = this.selectedItems.length > 0;
  }

  openPopupOfSearch(content: any, selectedItems: number[]) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
    this.getProjectList();
  }

  getProjectList() {
    let currentTenant = this.storageService.getValue(
      StorageKey.currentTenantId
    );
    if (currentTenant == '0') {
      return;
    }
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectList2 = response.projects.data;
        this.projectList2 = this.projectList2.filter(
          (ele: any) => ele.project_status_id == 1
        );
        this.projectList2.forEach((ele: any) => {
          ele['dropdown'] =
            ele?.name + (ele.client?.name ? ' [' + ele.client?.name + ']' : '');
        });
      },
      error: (error) => {},
    });
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;

    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.projectService.searchProjectwiseList(data).subscribe({
        next: (response: any) => {
          this.projectList2 = response.projects.data;
          this.projectList2 = this.projectList2.filter(
            (ele: any) => ele.project_status_id == 1
          );
          this.projectList2.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name +
              (ele.client?.name ? ' [' + ele.client?.name + ']' : '');
          });
        },
        error: (err) => {},
      });
    }
  }

  attachMultipleCandidatetoSearchForBulkAttach() {
    this.submitted = true;
    if (this.CreateTagForm.invalid) {
      return;
    }

    if (this.createTagForm['projectId'].value) {
      this.createTagData.project_id = this.createTagForm['projectId'].value;
    }

    let candId = this.selectedItems;

    this.createTagData.candidate_ids = candId;

    if (
      this.createTagData.candidate_ids == 'null' ||
      this.createTagData.candidate_ids == ''
    ) {
      return;
    }

    this.projectapicall = true;
    this.candidateService
      .attachMultipleCandidatetoSearch(this.createTagData)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.CreateTagForm.reset();
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          this.selectedItems = [];
          this.sendToSearchDiv = false;
        },
        error: (error) => {
          this.projectapicall = false;
        },
      });
  }

  showimage(data: string, strengthId: any, candidateId: any, changetype: any) {
    this.imagepath = data;
    this.strengthId = strengthId;
  }

  showfilterimage(data: string, strengthId: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.filterStrengthId = strengthId;
  }

  // showimage(data:string,strengthId:any,candidateId:any,changetype:any)
  // {
  //   this.imagepath=data;
  //   // this.imageselected=true;
  //   this.strengthId=strengthId;
  //   this.candidatechange(candidateId,changetype);

  // }

  // candidatechange(candidateid:any = "",changetype:any,selectedStatus?:any)
  // {
  //   let projectId = this.route.snapshot.params['id']
  //   let responseMessage:string = "";
  //   // let data={
  //   //   project_id:projectId,
  //   //   // strength:0,
  //   //   rank:0,
  //   //   // candidate_status_id:0,
  //   //   // allow_export:0
  //   // };
  //   switch (changetype){
  //     case 'candidateRank':

  //       let inputValue=(<HTMLInputElement>document.getElementById('candidateRank_'+candidateid)).value;
  //       this.projectRank= Number(inputValue);
  //       this.projectUpdate={
  //         project_id:projectId,
  //         rank:this.projectRank,
  //       }
  //       responseMessage = "Candidate rank updated";

  //     break;

  //     case 'candidateStatus':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         candidate_status_id:selectedStatus=='null'?null:selectedStatus
  //       }

  //     break;
  //     case 'candidate_strength':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         strength:this.strengthId
  //       }

  //     break;
  //     case 'allow_export':
  //       this.projectUpdate={
  //         project_id:projectId,
  //         allow_export:this.allowExportcheck
  //       }

  //     break;
  //   }

  //   this.candidateService.updateProjectStatus(candidateid,this.projectUpdate).subscribe({
  //     next:(response:any)=>{
  //       this.onLoad();
  //       // window.location.reload()
  //       this.notificationService.showSuccess(responseMessage);
  //     },
  //     error:(err:any)=>{

  //     }
  //   })

  // }
}
