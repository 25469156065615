import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, NgZone, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/services/common/notification.service';

@Component({
  selector: 'app-common-notification',
  templateUrl: './common-notification.component.html',
  styleUrls: ['./common-notification.component.css']
})
export class CommonNotificationComponent implements OnInit {
  megaphoneIcon = 'assets/img/TATMegaphone.png';
  selectedBarNotification: any | null = null;
  barNotificationList: any[] = [];
  popupNotification: any | null = null;
  unreadCount: number = 0;
  isPopupOpen: boolean = false;

  @ViewChild('previewModal', { static: true }) previewModal!: TemplateRef<any>;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private notificationService: NotificationService,
    private cdRef: ChangeDetectorRef,
    private ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.fetchNotifications();
    this.notificationService.getNotificationUpdateListener().subscribe(() => {
      this.fetchNotifications();
    });
  }

  fetchNotifications(): void {
    this.notificationService.getNotifications().subscribe(
      (response) => {
        if (response && response.notifications.length > 0) {
          this.popupNotification = response.notifications.find((n: any) => n.type === 'popup') || null;
          this.barNotificationList = response.notifications.filter((n: any) => n.type !== 'popup');

          if (this.popupNotification) {
            this.isPopupOpen = true;
            this.modalService.open(this.previewModal).result.then(() => {
              this.isPopupOpen = false;
              this.displayBarNotifications();
            }, () => {
              this.isPopupOpen = false;
              this.displayBarNotifications();
            });
          } else {
            this.displayBarNotifications();
          }
        } else {
          this.selectedBarNotification = null;
          this.barNotificationList = [];
          this.popupNotification = null;
          this.unreadCount = 0;
        }
      },
      (error) => {
        console.error('Error fetching notifications:', error);
      }
    );
  }

  displayBarNotifications(): void {
    if (!this.isPopupOpen && this.barNotificationList.length > 0) {
      this.selectedBarNotification = this.barNotificationList[0];
      setTimeout(() => {
        this.openPreview(this.selectedBarNotification);
      }, 500);
      this.unreadCount = this.barNotificationList.filter(notification => !notification.isRead).length;
    }
  }

  getNotificationClass(barType: string): string {
    switch (barType) {
      case 'action': return 'action-bar';
      case 'warning': return 'warning-bar';
      case 'informational': return 'informational-bar';
      case 'success': return 'success-bar';
      default: return '';
    }
  }

  getNotificationBtnClass(barType: string): string {
    switch (barType) {
      case 'action': return 'action-button-btn';
      case 'warning': return 'warning-button-btn';
      case 'informational': return 'informational-button-btn';
      case 'success': return 'success-button-btn';
      default: return '';
    }
  }

  markNotificationAsRead(notification: any): void {
      if (notification) {
        notification.isRead = true;
        this.barNotificationList = this.barNotificationList.filter(n => n.id !== notification.id);
        this.unreadCount = this.barNotificationList.filter(n => !n.isRead).length;
        this.selectedBarNotification = this.barNotificationList.find(n => !n.isRead) || null;
        let data = {
          notification_id: notification.id,
        };
        this.notificationService.readNotification(data).subscribe(
          () => {
            console.log('Notification marked as read successfully');
            this.notificationService.triggerNotificationUpdate();
            this.ngZone.run(() => {
              this.cdRef.detectChanges();
            });
          },
          (error) => {
            console.error('Error marking notification as read:', error);
          }
        );
        if (this.selectedBarNotification) {
          setTimeout(() => {
            this.openPreview(this.selectedBarNotification);
          }, 500);
        }
      }
  }

  markPopupAsRead(): void {
    if (this.popupNotification) {
      let data = {
        notification_id: this.popupNotification.id,
      }
      this.notificationService.readNotification(data).subscribe(
        () => {
          console.log('Popup notification marked as read successfully');
          this.popupNotification = null;
        },
        (error) => {
          console.error('Error marking popup notification as read:', error);
        }
      );
    }
  }

  closeNotificationBar(): void {
    this.selectedBarNotification = null;
  }

  closeNotificationPopup(): void {
    this.popupNotification = null;
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  openPreview(notification: any) {
    if (notification.type === 'popup') {
      this.modalService.open(this.previewModal);
    }
  }
}
