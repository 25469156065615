import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StorageService, StorageKey } from '../../common/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CandidateService {
  private storageKey = 'candidateSearchData';
  private storageClientKey = 'clientSearchData';
  private storageClientDrillKey = 'clientDrillData';
  private storageCandidateDrillKey = 'candidateDrillData';
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService) { }

  create(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}candidates`, data);
  }

  createManually(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}candidates/manual`, data);
  }

  createCandidateStatus(data:any): Observable<any>{
    data.is_active=1;
    return this.httpClient.post(`${environment.apiUrl}candidate-statuses`, data);
  }

  candidateBulkImport(data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}candidates/import`, data);
  }
  candidateSampleFile()
  {
    return this.httpClient.get(`${environment.apiUrl}candidate-import-sample-file`);
  }

  listAll(): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}candidates`);
  }

  listPagewise(data?: any): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}candidates?page=`+ data.page);
  }
  listSearch(data?: any): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}candidates?search=`+ data.search);
  }

  listSort(data?: any): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}candidates?sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method);
  }

  candidateDetail(id:number){
    return this.httpClient.get(`${environment.apiUrl}candidates/`+ id);
  }

  getCandidateStatus(){
    return this.httpClient.get(`${environment.apiUrl}candidate-statuses`);
  }

  getCandidateStatusDetail(id:number){
    return this.httpClient.get(`${environment.apiUrl}candidate-statuses/`+id);
  }

  updateCandidateStatus(id:number,data:any): Observable<any>{
    return this.httpClient.put(`${environment.apiUrl}candidate-statuses/`+id, data);
  }

  updateCandidateData(id:number,data:any): Observable<any>{
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id, data);
  }


  deleteCandidateData(id:number): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+id);
  }

  deleteCandidateStatus(id:number): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidate-statuses/`+id);
  }
  createExperience(id:number,data:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/experiences", data);
  }
  updateExperienceData(candidateid:number,experienceId:number,data:any): Observable<any>{
    return this.httpClient.put(`${environment.apiUrl}candidates/`+candidateid+"/experiences/"+experienceId, data);
  }

  deleteExperience(id:number,deleteId:any): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+id +"/experiences/" +deleteId);
  }
  attachProject(id:number,project_id:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/projects/attach", {project_id:project_id});
  }
  detachProject(id:number,project_id:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/projects/detach", {project_id:project_id});
  }
  createCertificate(id:number,data:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/certifications", data);
  }
  updateCertificate(id:number,certificateId:any,data:any)
  {
    return this.httpClient.put(`${environment.apiUrl}candidates/`+id+"/certifications/"+certificateId, data);
  }
  deleteCertificate(id:number,certificateId:any): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+id +"/certifications/" +certificateId);
  }
  getstate(){
    return this.httpClient.get(`${environment.apiUrl}states`);
  }
  createEducation(id:number,data:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/qualifications", data);
  }
  updateEducation(id:number,certificateId:any,data:any)
  {
    return this.httpClient.put(`${environment.apiUrl}candidates/`+id+"/qualifications/"+certificateId, data);
  }
  deleteEducation(id:number,educationId:any): Observable<any>{
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+id +"/qualifications/" +educationId);
  }
  createDocument(id:number,data:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/documents",data);
  }
  updateDocument(id:number,documentId:any,data:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/documents/"+documentId,data);
  }
  deleteDocument(id:number,documentId:any)
  {
    return this.httpClient.delete(`${environment.apiUrl}candidates/`+id+"/documents/"+documentId);
  }
  linkedinRefresh(id:number)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/refresh`,{id:id});
  }
  updateProjectStatus(id:any,data:any)
  {
    return this.httpClient.put(`${environment.apiUrl}candidates/`+id+"/projects/update",data);
  }
  attachtag(id:number,tag_id:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/tags/attach",{tag_id:tag_id});
  }
  dettachtag(id:number,tag_id:any)
  {
    return this.httpClient.post(`${environment.apiUrl}candidates/`+id+"/tags/detach",{tag_id:tag_id});
  }
  getlanguageData()
  {
    return this.httpClient.get(`${environment.apiUrl}languages`);
  }

  languageList(data?:any){
    let get_params:string = '';
    if(data && data.search){
      get_params += `search=`+ data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}languages?`+get_params);
  }
  userProjectList(data?:any){
    let get_params:string = '';
    if(data && data.search){
      get_params += `search=`+ data.search;
    }
    return this.httpClient.get(`${environment.apiUrl}global?`+get_params);
  }



  listByFilter(data?: any): Observable<any>{
    let get_params:string = '';
    if(data.sort_field && data.sort_method){
      get_params += `sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method;
    }
    if(data.multipleSorting ){
      let i=0;
      for (var key in data.multipleSorting) {
         if(get_params != ''){
            get_params +=`&`
          }
        get_params += `multiple_sorting[${i}][0]=`+ data.multipleSorting[key][0]+`&multiple_sorting[${i}][1]=`+ data.multipleSorting[key][1];
        i++;
      }
     }
    if(data.page){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `page=`+ data.page ;
    }
    if(data.search){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `search=`+ data.search;
    }
    if(data.apply_filter){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += data.apply_filter;
    }
    return this.httpClient.get(`${environment.apiUrl}candidates?`+get_params);
  }

  candidateStatusListByFilter(data?: any): Observable<any>{
    let get_params:string = '';
    if(data.sort_field && data.sort_method){
      get_params += `sort_field=`+ data.sort_field + `&sort_method= `+ data.sort_method;
    }

    if(data.page){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `page=`+ data.page ;
    }
    if(data.search){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += `search=`+ data.search;
    }
    if(data.apply_filter){
      if(get_params != ''){
        get_params +=`&`
      }
      get_params += data.apply_filter;
    }
    return this.httpClient.get(`${environment.apiUrl}candidate-statuses?`+get_params);
  }

  showProjectsCandidatewise(id:number): Observable<any>{
    return this.httpClient.get(`${environment.apiUrl}candidates/`+ id + "/projects");
  }

  attachMultipleCandidatetoSearch(data:any): Observable<any>{

    let data1 =  {candidate_ids : data.candidate_ids}
     let projectid = data.project_id
    return this.httpClient.post(`${environment.apiUrl}projects/`+ projectid +"/candidates/attach", data1);
  }

   getCandidateListOnStatus(params: any = {}): Observable<any> {
     let httpParams = new HttpParams();
     Object.keys(params).forEach(key => {
       if (params[key] !== undefined && params[key] !== null) {
         httpParams = httpParams.append(key, params[key]);
       }
     });
     return this.httpClient.get<any>(`${environment.apiUrl}candidate-status-wise`, { params: httpParams });
   }

  getClientListOnStatus(params: any = {}): Observable<any> {
     let httpParams = new HttpParams();
     Object.keys(params).forEach(key => {
       if (params[key] !== undefined && params[key] !== null) {
          httpParams = httpParams.append(key, params[key]);
       }
     });
      console.log(httpParams);
     return this.httpClient.get<any>(`${environment.apiUrl}clients`, { params: httpParams });
   }

    setCandidateSearchData(data: any) {
      localStorage.setItem(this.storageKey, JSON.stringify(data));
    }

    getCandidateSearchData() {
      const data = localStorage.getItem(this.storageKey);
      return data ? JSON.parse(data) : null;
    }

    clearCandidateSearchData() {
      localStorage.removeItem(this.storageKey);
    }

    setClientSearchData(data: any) {
      localStorage.setItem(this.storageClientKey, JSON.stringify(data));
    }

    getClientSearchData() {
      const data = localStorage.getItem(this.storageClientKey);
      return data ? JSON.parse(data) : null;
    }

    clearClientSearchData() {
      localStorage.removeItem(this.storageClientKey);
    }

    setClientDrillData(data: any) {
      localStorage.setItem(this.storageClientDrillKey, JSON.stringify(data));
    }

    getClientDrillData() {
      const data = localStorage.getItem(this.storageClientDrillKey);
      return data ? JSON.parse(data) : null;
    }

    setCandidateDrillData(data: any) {
      localStorage.setItem(this.storageCandidateDrillKey, JSON.stringify(data));
    }

    getCandidateDrillData() {
      const data = localStorage.getItem(this.storageCandidateDrillKey);
      return data ? JSON.parse(data) : null;
    }

    clearClientDrillData() {
      localStorage.removeItem(this.storageClientDrillKey);
    }

    clearCandidateDrillData() {
      localStorage.removeItem(this.storageCandidateDrillKey);
    }

    getClientDrillDownList(params: any = {}): Observable<any> {
     let httpParams = new HttpParams();
     Object.keys(params).forEach(key => {
       if (params[key] !== undefined && params[key] !== null) {
          httpParams = httpParams.append(key, params[key]);
       }
     });
      console.log(httpParams);
     return this.httpClient.get<any>(`${environment.apiUrl}client-drill-down-list`, { params: httpParams });
    }

    getCandidateDrillDownList(params: any = {}): Observable<any> {
     let httpParams = new HttpParams();
     Object.keys(params).forEach(key => {
       if (params[key] !== undefined && params[key] !== null) {
          httpParams = httpParams.append(key, params[key]);
       }
     });
      console.log(httpParams);
     return this.httpClient.get<any>(`${environment.apiUrl}candidate-drill-down-list`, { params: httpParams });
    }
}


export interface Candidates{
  in_first_name: any,
  current_position:any
  }
