import { Component, OnInit, ViewChild, TemplateRef, ChangeDetectorRef, NgZone, Renderer2, OnDestroy } from '@angular/core';
import { faCaretUp, faCaretDown, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { StorageKey, StorageService } from 'src/app/core/common/storage.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { PagerService } from 'src/app/core/common/pager.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  isSearchable: boolean = true;
  faArrowUp = faCaretUp;
  faArrowDown = faCaretDown;
  faArrowLeft = faArrowLeft;
  pageTitle = "Notifications";
  notificationList: any[] = [];
  totalRows: number = 0;
  sortfield: string = '';
  sortmethod: string = '';
  isSearchClicked: boolean = false;
  isClearClicked: boolean = false;
  pageNumber: number = 1;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  iconVisible: boolean = true;
  iconVisibleDesc: boolean = false;
  mouseOnColumnName: string = '';
  staticarrowsVisible: boolean = false;
  apicall = false;
  currentTenantId: string | null = null;
  notificationForm!: FormGroup;
  editNotificationForm!: FormGroup;
  notificationTypes: string[] = [];
  selectedNotification: any;
  megaphoneIcon = 'assets/img/TATMegaphone.png';
  showBarNotification = false;
  isBarSelected: boolean = false;
  @ViewChild('previewModal', { static: true }) previewModal!: TemplateRef<any>;
  @ViewChild('barNotification', { static: true }) barNotification!: TemplateRef<any>;
  @ViewChild('deleteConfirmModal') deleteConfirmModal!: TemplateRef<any>;
  minStartDate: string = new Date().toISOString().split('T')[0];
  minEndDate!: string;
  isTypeShown: boolean = false;
  pager: any = {};
  is_active: any;
  per_page: any;
  disable_future: any = 0;
  searchQuery: any;
  selectedNotificationId: number | null = null;
  selectedNotificationToDelete: any;
  titleCharCount = 0;
  descriptionCharCount = 0;
  titleMaxLength = 50;
  descriptionMaxLength = 500;
  private titleSubscription!: Subscription;
  private descriptionSubscription!: Subscription;
  pageSize: number = 3;

  constructor(
    private modalService: NgbModal,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private notificationService: NotificationService,
    private pagerService: PagerService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer,
  ) {}

  editorConfig = {
    editable: true,
    spellcheck: true,
    height: '100px',
    minHeight: '50px',
    placeholder: 'Enter description here...',
    translate: 'no',
    sanitize: false,
    toolbarHiddenButtons: [
      ['insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat'],
      ['subscript', 'superscript', 'strikeThrough', 'color', 'fontName', 'fontSize', 'toggleEditorMode', 'heading', 'textColor', 'foregroundColorPicker-', 'backgroundColorPicker-']
    ]
  };

  onBuildForm() {
    this.notificationForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      type: ['', [Validators.required]],
      is_active: [true],
      selectedMessageType: [''],
      startDate: [new Date(), Validators.required],
      endDate: ['', Validators.required]
    });

    this.editNotificationForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(50)]],
      type: ['', [Validators.required]],
      description: ['', [Validators.required, Validators.maxLength(500)]],
      is_active: [false],
      selectedMessageType: ['action', [Validators.required]],
      startDate: ['', Validators.required],
      endDate: ['', Validators.required]
    });

    this.notificationForm.get('type')?.valueChanges.subscribe(value => {
      const selectedMessageTypeControl = this.notificationForm.get('selectedMessageType');
      if (value === 'bar') {
        selectedMessageTypeControl?.setValidators([Validators.required]);
        selectedMessageTypeControl?.setValue('action');
        this.ngZone.run(() => {
          this.isBarSelected = true;
        });
      } else {
        selectedMessageTypeControl?.clearValidators();
        selectedMessageTypeControl?.setValue('');
        this.ngZone.run(() => {
          this.isBarSelected = false;
        });
      }

      selectedMessageTypeControl?.updateValueAndValidity();

      this.cdr.detectChanges();
    });
    this.notificationForm.patchValue({
        startDate: new Date().toISOString().split('T')[0]
    });

    const initialEndDate = new Date();
    initialEndDate.setDate(initialEndDate.getDate() + 1);
    this.minEndDate = initialEndDate.toISOString().split('T')[0];

//     this.notificationForm.get('startDate')?.valueChanges.subscribe(startDate => {
//       if (startDate) {
//         const nextDay = new Date(startDate);
//         nextDay.setDate(nextDay.getDate() + 1);
//         this.minEndDate = nextDay.toISOString().split('T')[0];
//         this.notificationForm.get('endDate')?.updateValueAndValidity();
//       }
//     });
this.setupStartDateListener(this.notificationForm);
    this.setupStartDateListener(this.editNotificationForm);

    this.setDynamicValidation();
    this.titleCharCount = 0;
    this.descriptionCharCount = 0;
  }

  setupStartDateListener(form: FormGroup) {
      form.get('startDate')?.valueChanges.subscribe(startDate => {
        if (startDate) {
          const nextDay = new Date(startDate);
          nextDay.setDate(nextDay.getDate() + 1);
          this.minEndDate = nextDay.toISOString().split('T')[0];

          console.log('Start Date changed:', startDate);

          // ✅ Empty endDate when user selects a new startDate
          form.patchValue({ endDate: '' });

          form.get('endDate')?.updateValueAndValidity();
        }
      });
  }

  toggleDismiss(event: any) {
    this.notificationForm.patchValue({
      is_active: event.target.checked ? true : false
    });
  }

  ngOnDestroy() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
    }
    if (this.descriptionSubscription) {
      this.descriptionSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.currentTenantId = this.storageService.getValue(StorageKey.currentTenantId);
    this.onBuildForm();
    this.setDynamicValidation();
    if (this.currentTenantId != '0') {
      this.notificationTypes = ['bar'];
      this.isTypeShown = false;
    } else {
      this.notificationTypes = ['bar', 'popup'];
      this.isTypeShown = true;
    }
    this.notificationForm.patchValue({
      type: 'bar'
    });

    this.route.queryParams.subscribe(params => {
      const queryParams = {
          //sort_field: 'created_at',
          sort_field: '',
          sort_method: '',
          page: 1,
          is_active: null,
          per_page: 10,
          disable_future: 0,
      };
      if (queryParams) {
          this.notificationService.setNotificationData(queryParams);
          this.setPage(queryParams.page);
          this.sortfield = queryParams.sort_field;
          this.sortmethod = queryParams.sort_method;
          this.is_active  = queryParams.is_active;
          this.per_page = queryParams.per_page;
          this.disable_future = queryParams.disable_future;
          this.getNotificationList(queryParams);
      }
    });
  }

  setPage(page: number) {
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    this.pageNumber = page;
    this.getNotificationList({ page: this.pageNumber, pageSize: this.pageSize, disable_future: this.disable_future });
  }

  closeBarNotification(): void {
    this.showBarNotification = false;
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
  }

  mouseLeave(columnname: any) {
    if (this.sortfield !== columnname) {
      this.iconVisible = false;
    }
    this.staticarrowsVisible = true;
    this.mouseOnColumnName = '';
  }

  onRefreshClick() {
    let requestData = this.notificationService.getNotificationData();
    this.setPage(requestData.page);
    this.sortfield = requestData.sort_field;
    this.sortmethod = requestData.sort_method;
    this.is_active = requestData.is_active;
    this.per_page = requestData.is_active;
    this.disable_future = requestData.disable_future;
    this.getNotificationList(requestData);
  }

  displayNotification(notification: any) {
    this.selectedNotification = notification;
    if (notification.type === 'popup') {
      this.modalService.open(this.previewModal);
    }
  }

  getNotificationClass(barType: string): string {
    switch (barType) {
      case 'action':
        return 'action-bar';
      case 'warning':
        return 'warning-bar';
      case 'informational':
        return 'informational-bar';
      case 'success':
        return 'success-bar';
      default:
        return '';
    }
  }

  getNotificationBtnClass(barType: string): string {
    switch (barType) {
      case 'action':
        return 'action-button-btn';
      case 'warning':
        return 'warning-button-btn';
      case 'informational':
        return 'informational-button-btn';
      case 'success':
        return 'success-button-btn';
      default:
        return '';
    }
  }

  getNotifications(data: any): void {
      this.sortmethod = this.sortmethod === 'asc' ? 'desc' : 'asc';
      this.sortfield = data.sort_field;
      let query = {
        page: this.pager?.currentPage || 1,
        per_page: this.per_page,
        sort_field: this.sortfield,
        sort_method: this.sortmethod,
        disable_future: 0,
      };
      this.getNotificationList(query);
  }

  closeNotificationBar() {
    this.selectedNotification = null;
  }

  getNotificationList(params: any): void {
    this.notificationService.getNotificationList(params).subscribe({
      next: (response) => {
        this.notificationList = response.notifications?.data ?? [];
        this.totalRows = response.notifications.total;
        this.pager = this.pagerService.getPager(
          this.totalRows,
          this.pageNumber
        );
        this.cdr.detectChanges();
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 0);
      },
      error: (error) => {
        console.error('Error fetching candidates:', error);
      }
    });
  }

  openModal(content: any) {
    this.onBuildForm();
    this.closeNotificationBar();
    this.modalService.open(content, { size: 'lg', backdrop: 'static'});
  }

  openEditModal(notification: any, modal: any) {
    this.closeNotificationBar();
    this.selectedNotificationId = notification.id;
    this.editNotificationForm.patchValue({
      title: notification.title,
      type: notification.type,
      selectedMessageType: notification.bar_type,
      description: notification.description,
      is_active: notification.is_active,
      startDate: notification.start_date,
      endDate: notification.end_date || ''
    });
    this.editNotificationForm.get('endDate')?.updateValueAndValidity();
    const selectedMessageTypeControl = this.editNotificationForm.get('selectedMessageType');
      if (notification.bar_type) {
        selectedMessageTypeControl?.setValidators([Validators.required]);
        selectedMessageTypeControl?.setValue(notification.bar_type || 'action');
        this.isBarSelected = true;
      } else {
        selectedMessageTypeControl?.clearValidators();
        selectedMessageTypeControl?.setValue(notification.bar_type || '');
        this.isBarSelected = false;
      }
      this.titleCharCount = notification.title ? notification.title.length : 0;
      this.descriptionCharCount = notification.description ? notification.description.length : 0;
      selectedMessageTypeControl?.updateValueAndValidity();
      this.cdr.detectChanges();
      this.updateSelectedColorOnEdit();
      //this.modalService.open(modal);
      this.modalService.open(modal, { size: 'lg', backdrop: 'static'});
  }

  openPreview(notification: any) {
    this.selectedNotification = notification;
    if (notification.type === 'popup') {
      this.modalService.open(this.previewModal);
    } else if (notification.type === 'bar') {
      this.modalService.open(this.barNotification);
    }
  }

  saveNotification() {
    const oldData = this.notificationForm.value;
    let bar_type = oldData.selectedMessageType;
    if (this.currentTenantId != '0') {
        this.notificationForm.patchValue({
          type: 'bar'
        });
    }
    if (this.notificationForm.invalid) {
      this.notificationForm.markAllAsTouched();
      return;
    }
    const formData = this.notificationForm.value;
    let dataToSend = {
      title: formData.title,
      description: formData.description,
      type: formData.type.toLowerCase(),
      bar_type: bar_type,
      is_active: formData.is_active ? 1 : 0,
      start_date: formData.startDate,
      end_date: formData.endDate,
    };
    this.notificationService.createNotification(dataToSend).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.closeModalAndRefresh();
        this.notificationService.triggerNotificationUpdate();
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });
      },
      error: (error) => {
//         this.notificationService.showError("Failed to create notification");
           this.notificationService.showError(error.error.message);
      }
    });
  }

  updateNotification() {
    if (this.editNotificationForm.invalid) {
      this.editNotificationForm.markAllAsTouched();
      return;
    }
    const updatedData = this.editNotificationForm.value;
    let dataToSend = {
      title: updatedData.title,
      description: updatedData.description,
      type: updatedData.type.toLowerCase(),
      bar_type: updatedData.selectedMessageType,
      start_date: updatedData.startDate,
      end_date: updatedData.endDate,
      is_active: updatedData.is_active = updatedData.is_active ? 1 : 0,
    };
    this.notificationService
    .updateNotification(this.selectedNotificationId, dataToSend)
    .subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);        
        this.closeModalAndRefresh();
        this.notificationService.triggerNotificationUpdate();
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });
      },
      error: (err: any) => {
        this.notificationService.showError(err.error.message);
      },
    });
  }

  setDynamicValidation() {
    const setValidators = (formGroup: FormGroup) => {
      formGroup.get('type')?.valueChanges.subscribe((selectedType) => {
        const titleControl = formGroup.get('title');
        const descriptionControl = formGroup.get('description');

        if (titleControl && descriptionControl) {
          titleControl.clearValidators();
          descriptionControl.clearValidators();

          if (selectedType === 'bar') {
            this.titleMaxLength = 50;
            this.descriptionMaxLength = 500;
            titleControl.setValidators([Validators.required, Validators.maxLength(50)]);
            descriptionControl.setValidators([
              Validators.required,
              this.textLengthValidator(500)
            ]);
          } else if (selectedType === 'popup') {
            this.titleMaxLength = 50;
            this.descriptionMaxLength = 700;
            titleControl.setValidators([Validators.required, Validators.maxLength(50)]);
            descriptionControl.setValidators([
              Validators.required,
              this.textLengthValidator(700)
            ]);
          }

          // Update character count using stripped text
          this.titleCharCount = this.stripHtmlTags(titleControl.value).length || 0;
          this.descriptionCharCount = this.stripHtmlTags(descriptionControl.value).length || 0;

          Promise.resolve().then(() => {
            titleControl.updateValueAndValidity();
            descriptionControl.updateValueAndValidity();

            const strippedTextLength = this.stripHtmlTags(descriptionControl.value).length || 0;
            const currentErrors = descriptionControl.errors;
            if (strippedTextLength > this.descriptionMaxLength) {
                descriptionControl.setErrors({
                  ...currentErrors,
                  maxlength: {
                    requiredLength: this.descriptionMaxLength,
                    actualLength: strippedTextLength,
                  },
                });
              } else {
                if (currentErrors?.['maxlength']) {
                  delete currentErrors['maxlength'];
                  descriptionControl.setErrors(Object.keys(currentErrors).length ? currentErrors : null);
                }
              }
          });
        }
      });
    };

    setValidators(this.notificationForm);
    setValidators(this.editNotificationForm);
  }

  closeModalAndRefresh() {
    this.modalService.dismissAll();
    let requestData = this.notificationService.getNotificationData();
    this.setPage(requestData.page);
    this.sortfield = requestData.sort_field;
    this.sortmethod = requestData.sort_method;
    this.is_active = requestData.is_active;
    this.per_page = requestData.per_page;
    this.disable_future = requestData.disable_future;
    this.getNotificationList(requestData);
  }

  openDeleteModal(notification: any) {
    this.closeNotificationBar();
    this.selectedNotificationToDelete = notification;
    this.modalService.open(this.deleteConfirmModal, { centered: true });
  }

  confirmDelete(modal: any) {
    if (!this.selectedNotificationToDelete) return;

    this.notificationService.deleteNotification(this.selectedNotificationToDelete.id).subscribe({
      next: (response) => {
        this.notificationService.showSuccess("Notification deleted successfully!");
        this.closeModalAndRefresh();
        this.notificationService.triggerNotificationUpdate();
        this.ngZone.run(() => {
          this.cdr.detectChanges();
        });
      },
      error: (error) => {
        this.notificationService.showError("Failed to delete notification.");
      }
    });
  }

  stripHtmlTags(html: string): string {
    if (!html) return '';

    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  sanitizeHtml(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

  updateSelectedColor(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOption = selectElement.options[selectElement.selectedIndex];
    const selectedColor = selectedOption.getAttribute('data-color');
    if (selectedColor) {
      selectElement.style.color = selectedColor;
    }
  }

  updateSelectedColorOnEdit() {
    const selectedMessageTypeControl = this.editNotificationForm.get('selectedMessageType');

    if (!selectedMessageTypeControl) return;

    const selectedValue = selectedMessageTypeControl.value;
    let selectedColor = '';

    switch (selectedValue) {
      case 'action':
        selectedColor = '#C03C2C';
        break;
      case 'warning':
        selectedColor = '#FFF2CC';
        break;
      case 'informational':
        selectedColor = '#46546F';
        break;
      case 'success':
        selectedColor = '#34835A';
        break;
      default:
        selectedColor = '';
    }

    setTimeout(() => {
      const selectElement = document.querySelector('select[formControlName="selectedMessageType"]') as HTMLSelectElement;
      if (selectElement) {
        selectElement.style.color = selectedColor;
      }
    });
  }

  onTitleChange(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    this.titleCharCount = inputValue.length;
  }

  updateDescriptionValidation(descriptionControl: AbstractControl | null, maxLength: number) {
    if (!descriptionControl) return;

    const strippedTextLength = this.stripHtmlTags(descriptionControl.value).length || 0;
    const currentErrors = descriptionControl.errors || {};

    if (strippedTextLength > maxLength) {
      descriptionControl.setErrors({
        ...currentErrors,
        maxlength: {
          requiredLength: maxLength,
          actualLength: strippedTextLength, // ✅ Correct length without HTML tags
        },
      });
    } else {
      if (currentErrors['maxlength']) {
        delete currentErrors['maxlength']; // ✅ Remove maxlength error if within limit
        descriptionControl.setErrors(Object.keys(currentErrors).length ? currentErrors : null);
      }
    }
  }


  onDescriptionChange(value: string) {
    //this.descriptionCharCount = this.stripHtmlTags(value).length;
    const descriptionControl = this.notificationForm.get('description');
    if (descriptionControl) {
      this.updateDescriptionValidation(descriptionControl, this.descriptionMaxLength);
      this.descriptionCharCount = this.stripHtmlTags(value).length;
    }
  }

  textLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) return null;

      const plainTextLength = control.value.replace(/<[^>]*>/g, '').trim().length;

      return plainTextLength > maxLength
        ? { maxlength: { requiredLength: maxLength, actualLength: plainTextLength } }
        : null;
    };
  }

}
