import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './modules/login/pages/login/login.component';
import { CandidateComponent } from './modules/candidate/pages/candidate/candidate.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CandidateListComponent } from './modules/candidate/pages/candidate-list/candidate-list.component';
import { ProjectListComponent } from './modules/projects/pages/project-list/project-list.component';
import { AdminComponent } from './modules/admin/pages/admin/admin.component';
import { UserListComponent } from './modules/users/pages/user-list/user-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClientListComponent } from './modules/client/pages/client-list/client-list.component';
import { TagsListComponent } from './modules/tags/pages/tags-list/tags-list.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingInterceptor } from './core/interceptors/loading.interceptor';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { NgChartsModule } from 'ng2-charts';
import { LogoutDialogComponent } from './modules/logout-dialog/logout-dialog.component';
import { IdleService } from './core/services/idle/idle.service';
import { NotificationModule } from './modules/notification/notification.module';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SpinnerComponent,
    LogoutDialogComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      timeOut: 500000000000,
      preventDuplicates: true,
      // positionClass: 'toast-top-full-width',


    }),
    CoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule,
    NgxUiLoaderHttpModule.forRoot({ showForeground: true }),
    NgSelectModule,
    FontAwesomeModule,
    NgChartsModule,
    NotificationModule,
    NgbAccordionModule,
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
  },
    IdleService],
  bootstrap: [AppComponent],
})
export class AppModule { }
