<app-app-sub-header class="top-row d-flex justify-content-between" [pageTitle]="pageTitle" [showCreateButton]="(currentTenantId=='0')?showCreateButton:''" (onRefreshClicked)="resetListSettings()"></app-app-sub-header>
<div class="bottom-table">
    <table class="table table-bordered">
        <thead>
          <tr>
            <th scope="col">Sequence</th>
            <th scope="col" style="width:30%" >Name</th>
            <th scope="col" style="width:30%" >Description</th>
            <th scope="col" style="width:14%">Type</th>
            <th scope="col" style="width:20%">Status</th>
            <th scope="col" style="width:15%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cl of clientStatusList">
            <td style="text-align: center !important;">
              {{cl?.sequence}}
            </td>
            <td >{{cl?.name}} </td>
            <td >{{cl?.description}} </td>
            <td >{{cl?.category}} </td>
            <td style="text-align: center !important;">
              <span *ngIf="cl.is_active === 1" class="badge text-bg-success">Active</span>
              <span *ngIf="cl.is_active === 0" class="badge text-bg-warning">Inactive</span>
            </td>
            <td style="vertical-align: middle;">
              <a href="javascript:void(0)" (click)="openUpdateModal(UpdateClientStatus,cl.id,cl)">
                <em class="bi bi-pencil-square me-2"></em></a>
              <a *ngIf=" currentTenantId=='0'" (click)="openDeleteModal(deleteClientStatus,cl.id,cl)">
                <em class="bi bi-trash" style="margin-left:9px;" title="Delete"></em></a>
            </td>
          </tr>
        </tbody>
      </table>
</div>

<div class="pagination-main">
  <label for="totalRows" class="list-totalrecords">Total Records: {{totalRows}}</label>
  <ul *ngIf="pager.pages && pager.pages.length && totalRows > pageSize" class="pagination justify-content-end m-0">
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elseFirst" class="page-link page-show"><<</a>
        <ng-template #elseFirst>
            <a (click)="setPage(1)" class="page-link"><<</a>
        </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === 1}">
        <a *ngIf="pager.currentPage === 1; else elsePrevious" class="page-link page-show"> <</a>
        <ng-template #elsePrevious>
            <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
        </ng-template>
    </li>

    <li class="page-item ">
      <a class="page-link">{{pager.currentPage}}</a>
  </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseNext" class="page-link page-show">></a>
        <ng-template #elseNext>
            <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
        </ng-template>
    </li>
    <li class="page-item" [ngClass]="{disabled:pager.currentPage === pager.totalPages}">
        <a *ngIf="pager.currentPage === pager.totalPages; else elseLast" class="page-link page-show">>></a>
        <ng-template #elseLast>
            <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
        </ng-template>
    </li>
</ul>
</div>

  <ng-template #deleteClientStatus let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Client Stage</h4>
      <button type="button" class="close" aria-label="Close"
        (click)="modal.dismiss('Cross click');cleardeleteErrorMsg();">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <p>Do you want to remove client Stage?</p>
      </div>
      <div style="padding:5px; font-size: 14px;padding-left:0px" class="text-danger" *ngIf="deleteErrorMsg">
        {{deleteErrorMsg}}</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="filter" (click)="modal.dismiss('Cross click');cleardeleteErrorMsg();">Cancel</button>
      <button type="button" class="blue-btn ms-2" (click)="deleteClientStatus1()">Delete</button>
    </div>
  </ng-template>

  <ng-template #UpdateClientStatus let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Update Client Stage</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click') ; this.submitted = false ; resetBackendCSerror()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <form [formGroup]="UpdateClientStatusForm">
      <div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="updateCSError">{{updateCSError}}</div>
        <div style="padding:5px; font-size: 14px;padding-left: 20px;"  class="text-danger" *ngIf="updateCSErrorName">{{updateCSErrorName}}</div>
        <div style="padding:10px; font-size: 14px;padding-left: 20px;" class="text-danger" *ngIf="updateCSErrorDescription">{{updateCSErrorDescription}}</div>
      </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="name">Name</label>
        <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
        <input type="text" placeholder="Enter name " formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && updateClientStatusForm['name'].errors }" >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && updateClientStatusForm['name'] && updateClientStatusForm['name'].errors && updateClientStatusForm['name'].errors['required']">
          Name is required.
          </div>
      </div>
      <div class="mb-3">
        <label for="description">Description</label>
        <textarea type="text" placeholder="Enter description" formControlName="description" class="form-control" rows="3"  ></textarea>
      </div>
      <div class="mb-3">
        <label for="type">Type</label>
        <ng-select [items]="[{name:'New',value:'New'},{name:'Expansion',value:'Expansion'}]" class="form-control form-select " placeholder="Category"
                   bindLabel="name" formControlName="type" bindValue="value" [clearable]="false">
        </ng-select >
      </div>
      <div class="mb-3">
        <label for="sequence">Sequence</label>
        <input type="number"  placeholder="Enter Sequence" formControlName="sequence" class="form-control"  >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && updateClientStatusForm['sequence'] && updateClientStatusForm['sequence'].errors ">
          Sequence must be integer
          </div>
      </div>
      <div class="mb-3">
        <label for="status">Status</label>
        <ng-select [items]="[{name:'Active',value:'1'},{name:'Inactive',value:'0'}]" class="form-control form-select " placeholder="Status"
        bindLabel="name" formControlName="status" bindValue="value"  [ngClass]="{ 'is-invalid': submitted && updateClientStatusForm['status'].errors }" >
        </ng-select >
        <div style="color: red; font-size: 14px;" *ngIf="submitted && updateClientStatusForm['status'] && updateClientStatusForm['status'].errors && updateClientStatusForm['status'].errors['required']">
          Status is required.
          </div>
      </div>
      <input type="hidden" formControlName="updateId">
    </div>


    <div class="modal-footer">
      <div class="spinner-border" *ngIf="csapicall">
        <span class="visually-hidden">Loading...</span>
      </div>
      <button type="button" class="filter" (click)="modal.close('Save click') ; this.submitted = false ; resetBackendCSerror()" *ngIf="!csapicall">Cancel</button>
      <button type="button" class="blue-btn" (click)="updateClientStatus()"  *ngIf="!csapicall">Save</button>

    </div>
  </form>
  </ng-template>
