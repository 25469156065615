import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
  TemplateRef,
  HostListener,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PagerService } from 'src/app/core/common/pager.service';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { AbstractControl, FormBuilder, FormGroup, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { UserService } from 'src/app/core/services/users/user.service';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { TenantserviceService } from 'src/app/core/services/tenants/tenantservice.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.css'],
})
export class TenantsListComponent {
  activePage: number = 1;
  tenantList: any = [];
  total: number = 10;
  closeResult: string = '';
  createUserError: string = '';
  CreateTenantForm!: FormGroup;
  showCreateButton: string = 'tenant';
  EditId!: number;
  pageNumber: number = 1;
  pageSize: number = 3;
  totalRows: number = 0;
  pager: any = {};
  pageTitle: string = 'Tenants';
  isSearchable: boolean = true;
  to: number = 10;
  submitted: boolean = false;
  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  selectDeletedUser: string | undefined;
  droppedImage: boolean = false;
  projectapicall: boolean = false;
  files: any;
  filePath!: string;
  showCount = false;
  otpCount: any;
  fileName!: string;
  rectangleBox: boolean = true;
  url: any;
  sub!: Subscription;
  length: any;
  DeleteTenantError = '';
  createTenantErroruserPassword = '';
  createTenantErroruserEmail = '';
  createTenantErroruserName = '';
  createTenantErrorName = '';
  createTenantErrorId = '';
  createTenantError = '';
  currentSearchValue: string = '';
  deleteSuccessMsg = '';
  tenantDeleted = false;
  dropimg: boolean = false;
  previewImg: boolean = true;
  // for sorting list
  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;
  sortfield: any;
  sortmethod: any;
  iconVisible: boolean = true;
  iconVisibleAsc: boolean = false;
  iconVisibleDesc: boolean = false;
  staticarrowsVisible: boolean = false;
  currentSortedColumn: any;
  currentSortedMethod: any;
  sortedColumn: any;
  getSearchList: any;
  selectFile: any;
  userid!: number | any;
  createUserErrorAvatar: any;
  createUserErrorName: any;
  createUserErrorEmail: any;
  createUserErrorRoleid: any;
  otpmessage = '';
  createUserErrorIsactive: any;
  showPassword: boolean = false;
  logoBox: boolean = false;
  showUserdeleteicon: boolean = true;
  deleteuserId: number | any;
  myuserid: any;
  files2: any;
  deleteClicked = false;
  deleteotp: any;
  deleteTenantId: any;
  fileName2: any;
  filePath2: any;
  imgErrorMessage: any;
  // url2!:any ;
  url2 = '../assets/img/avatar-placeholder.png';
  url3: number | any = 1;
  userShortname!: string | null;
  sortname!: string;
  editedInitials: any;
  mouseOnColumnName: string = '';
  tenantWebsiteError: string = '';
  tenantAdminError: string = '';

  selectedItems: any[] = [];
  items: any[] = [];

  constructor(
    private userservice: UserService,
    private tenantservice: TenantserviceService,
    private notificationService: NotificationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private pagerService: PagerService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.tenant) {
      this.getTenantList(null, this.getSearchList.tenant);
    } else this.getTenantList({});
    let currentUserid = this.storageService.getValue(StorageKey.currentUserid);
    this.userid = currentUserid;
  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  get f() {
    return this.CreateTenantForm.controls;
  }

  generateForm() {
    this.CreateTenantForm = this.formBuilder.group({
      tanentId: [null],
      name: [null, [Validators.required]],
      is_active: [null],
      logo: [null],
      description: [null],
      website: [
        null,
        Validators.pattern(
          '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$'
        ),
      ],
      disable_access_to_client_module: [null],
      is_public_search: [null],
      admins: [[], [Validators.required, this.minArrayLength(1)]],
    });
  }

  minArrayLength(min: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !Array.isArray(control.value)) {
        return { minArrayLength: { requiredLength: min, actualLength: 0 } };
      }
      if (control.value.length >= min) {
        return null;
      }
      return { minArrayLength: { requiredLength: min, actualLength: control.value.length } };
    };
  }

  getTenantList(
    data1: any,
    data = {
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: this.currentSearchValue,
    },
    reset: boolean = false
  ) {
    //data={page :this.pageNumber, sort_field: string = '', sort_method: string ='', column_name: string=''}

    if (data1) {
      data.sort_field = data1.sort_field;
      data.sort_method = data1.sort_method;
      data.column_name = data1.column_name;
    }
    if (!reset) {
      if (this.getSearchList) {
        this.currentSearchValue = data.search;
        this.getSearchList.tenant = data;
        this.storageService.setValue(
          'listSettings',
          JSON.stringify(this.getSearchList)
        );
      } else
        this.storageService.setValue(
          'listSettings',
          JSON.stringify({ tenant: data })
        );
    } else {
      data.search = '';
      data.page = 1;
    }

    this.tenantservice.listByFilter(data).subscribe({
      next: (response: any) => {
        // console.log(response);

        this.tenantList = response.tenants.data;
        this.tenantList.forEach((element: any) => {
          element['initialss'] = this.getInitials(element.name);
        });

        this.pageNumber = response.tenants.current_page;
        this.totalRows = response.tenants.total;
        this.pager = this.pagerService.getPager(
          this.totalRows,
          this.pageNumber
        );
        if (data.sort_field) {
          this.iconVisible = false;
          this.staticarrowsVisible = true;

          this.currentSortedColumn = data.column_name;
          this.sortedColumn = data.column_name;
          if (data.sort_method === 'desc') {
            this.iconVisibleDesc = true;
            this.iconVisibleAsc = false;
            this.currentSortedMethod = 'desc';
          }
          if (data.sort_method === 'asc') {
            this.iconVisibleAsc = true;
            this.iconVisibleDesc = false;
            this.currentSortedMethod = 'asc';
          }
          this.sortfield = response.tenants.sort_field;
          this.sortmethod = response.tenants.sort_method;
        }
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  openDeleteModal(content: any, deleteId: number, value: any) {
    this.selectDeletedUser = value.name;
    this.deleteTenantId = deleteId;

    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
          // this.deleteUser(deleteId);
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  mouseEnter(columnname: any) {
    this.iconVisible = true;
    this.staticarrowsVisible = false;
    //this.currentSortedColumn = columnname;
    this.mouseOnColumnName = columnname;
    if (columnname == 'name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'description' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'id' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'admin_email' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'admin_name' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
    if (columnname == 'is_active' && this.sortedColumn != columnname) {
      this.staticarrowsVisible = true;
    }
  }

  mouseLeave(columnname: any) {
    this.iconVisible = false;
    this.staticarrowsVisible = true;
  }

  EditTenant(content: any, EditId: any) {
    let responce = this.tenantList.filter((ele: any) => {
      return ele.id == EditId;
    });

    let response = responce[0];
    // console.log(response,"res");

    if (EditId != undefined || EditId != null) {
      this.url = response.logo_path;
      response['initial'] = this.getInitials(response.name);
      this.editedInitials = response.initial;

      this.url = response.logo_path;
      this.rectangleBox = true;
      // this.openLogoSection =false;
      if (this.url == null) {
        this.rectangleBox = true;
        this.logoBox = false;
      } else if (this.url != null) {
        this.rectangleBox = false;
        this.logoBox = true;
      }
      this.CreateTenantForm.controls['tanentId'].setValue(response.id);
      this.CreateTenantForm.controls['name'].setValue(response.name);
      this.CreateTenantForm.controls['is_active'].setValue(response.is_active);
      this.CreateTenantForm.controls[
        'disable_access_to_client_module'
      ].setValue(response.is_client_module_access_disabled);
      this.CreateTenantForm.controls['description'].setValue(
        response.description == ('null' || null) ? '' : response.description
      );
      this.CreateTenantForm.controls['website'].setValue(response.website);
      this.CreateTenantForm.controls['is_public_search'].setValue(
        response.is_public_search
      );
      // this.CreateTenantForm.controls['is_public_search'].setValue(1);
      let data = {id: EditId};
      this.tenantservice.getTenentData(data).subscribe({
        next: (response: any) => {
          this.items = Array.isArray(response.users) ? response.users : [];
          this.selectedItems = this.items
            .filter((admin: any) => admin.role_id === 1)
            .map((admin: any) => admin.id);
          this.CreateTenantForm.controls['admins'].setValue(this.selectedItems);
        },
        error: (err) => {
          console.error("Error fetching data:", err);
        }
      });

      this.modalService
        .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md' })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed `;
          }
        );
    }
  }
  open(content: any, EditId: number) {
    // console.log("open model");
    this.generateForm();
    this.EditTenant(content, EditId);
  }
  attachLogoFiles(event: any): void {
    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox = true;
      this.rectangleBox = false;

      var reader = new FileReader();

      reader.onload = (event: any) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const maxWidth = 800;
          const maxHeight = 800;

          const width = img.width;
          const height = img.height;

          if (width === height) {
            if (width > maxWidth) {
              this.imgErrorMessage = `Error: Square image dimensions must not exceed 800 X 800 pixels.`;
              return;
            }
          } else {
            if (width > maxWidth || height > maxHeight) {
              this.imgErrorMessage = `Error: Image dimensions must not exceed ${maxWidth}x${maxHeight} pixels.`;
              return;
            }
          }
          this.imgErrorMessage = '';
          this.url = event.target.result;
        };
      };

      reader.readAsDataURL(event.target.files[0]);
    }
  }

  updateTenanats() {
    this.submitted = true;

    let UpdatedTenantDetails = {
      id: this.CreateTenantForm.controls['tanentId'].value,
      name: this.CreateTenantForm.controls['name'].value,
      is_active: this.CreateTenantForm.controls['is_active'].value,
      description: this.CreateTenantForm.controls['description'].value,
      website: this.CreateTenantForm.controls['website'].value,
      is_client_module_access_disabled: this.CreateTenantForm.controls[
        'disable_access_to_client_module'
      ].value
        ? 1
        : 0,
      is_public_search: this.CreateTenantForm.controls['is_public_search'].value
        ? 1
        : 0,
    };

    const formData: FormData = new FormData();

    if (this.url == null) {
      formData.append('is_logo_removed', '1');
      formData.append(
        'tanentId',
        this.CreateTenantForm.controls['tanentId'].value
      );
      formData.append('name', this.CreateTenantForm.controls['name'].value);
      formData.append(
        'description',
        this.CreateTenantForm.controls['description'].value
      );
      if (this.CreateTenantForm.controls['website'].value) {
        formData.append(
          'website',
          this.CreateTenantForm.controls['website'].value
        );
      }

      formData.append(
        'is_client_module_access_disabled',
        this.CreateTenantForm.controls['disable_access_to_client_module'].value
          ? '1'
          : '0'
      );
      formData.append(
        'is_active',
        this.CreateTenantForm.controls['is_active'].value
      );
      formData.append(
        'is_public_search',
        this.CreateTenantForm.controls['is_public_search'].value ? '1' : '0'
      );
    } else if (this.fileName2 != null) {
      formData.append('logo', this.files2);
      formData.append(
        'tanentId',
        this.CreateTenantForm.controls['tanentId'].value
      );
      formData.append('name', this.CreateTenantForm.controls['name'].value);
      formData.append(
        'description',
        this.CreateTenantForm.controls['description'].value
      );
      if (this.CreateTenantForm.controls['website'].value) {
        formData.append(
          'website',
          this.CreateTenantForm.controls['website'].value
        );
      }
      formData.append(
        'is_client_module_access_disabled',
        this.CreateTenantForm.controls['disable_access_to_client_module'].value
          ? '1'
          : '0'
      );
      formData.append(
        'is_active',
        this.CreateTenantForm.controls['is_active'].value
      );
      formData.append(
        'is_public_search',
        this.CreateTenantForm.controls['is_public_search'].value ? '1' : '0'
      );
      // formData.append('state', this.CreateClientForm.controls['state'].value);
      // formData.append('contact_email', this.CreateClientForm.controls['contact_email'].value);
      // formData.append('contact_number', this.CreateClientForm.controls['contact_number'].value);
      // formData.append('postal_code', this.CreateClientForm.controls['postal_code'].value);
      // formData.append('street_address', this.CreateClientForm.controls['street_address'].value);
      // formData.append('description', this.CreateClientForm.controls['description'].value);
      // formData.append('website', this.CreateClientForm.controls['website'].value);
      // formData.append('primary_contact', this.CreateClientForm.controls['primary_contact'].value);
      // formData.append('web_link_one', this.CreateClientForm.controls['web_link_one'].value);
      // formData.append('web_link_two', this.CreateClientForm.controls['web_link_two'].value);
      // formData.append('web_link_three', this.CreateClientForm.controls['web_link_three'].value);
    } else if (this.fileName2 == null) {
      formData.append(
        'tanentId',
        this.CreateTenantForm.controls['tanentId'].value
      );
      formData.append('name', this.CreateTenantForm.controls['name'].value);
      formData.append(
        'description',
        this.CreateTenantForm.controls['description'].value
      );
      if (this.CreateTenantForm.controls['website'].value) {
        formData.append(
          'website',
          this.CreateTenantForm.controls['website'].value
        );
      }
      formData.append(
        'is_client_module_access_disabled',
        this.CreateTenantForm.controls['disable_access_to_client_module'].value
          ? '1'
          : '0'
      );
      formData.append(
        'is_active',
        this.CreateTenantForm.controls['is_active'].value
      );
      formData.append(
        'is_public_search',
        this.CreateTenantForm.controls['is_public_search'].value ? '1' : '0'
      );
      // formData.append('industry', this.CreateClientForm.controls['industry'].value);
      // formData.append('city', this.CreateClientForm.controls['city'].value);
      // formData.append('state', this.CreateClientForm.controls['state'].value);
      // formData.append('contact_email', this.CreateClientForm.controls['contact_email'].value);
      // formData.append('contact_number', this.CreateClientForm.controls['contact_number'].value);
      // formData.append('postal_code', this.CreateClientForm.controls['postal_code'].value);
      // formData.append('street_address', this.CreateClientForm.controls['street_address'].value);
      // formData.append('description', this.CreateClientForm.controls['description'].value);
      // formData.append('website', this.CreateClientForm.controls['website'].value);
      // formData.append('primary_contact', this.CreateClientForm.controls['primary_contact'].value);
      // formData.append('web_link_one', this.CreateClientForm.controls['web_link_one'].value);
      // formData.append('web_link_two', this.CreateClientForm.controls['web_link_two'].value);
      // formData.append('web_link_three', this.CreateClientForm.controls['web_link_three'].value);
    }
    //     if(this.fileName != null && (this.CreateTenantForm.controls['password'].value != undefined || this.CreateTenantForm.controls['password'].value != null )){
    //       formData.append('name', this.fileName);
    //       formData.append('avatar', this.files);
    //       formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //       formData.append('name', this.CreateTenantForm.controls['name'].value);
    //       formData.append('email', this.CreateTenantForm.controls['email'].value);
    //       formData.append('password', this.CreateTenantForm.controls['password'].value);
    //       formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //       formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //     } else if(this.fileName == null && (this.CreateTenantForm.controls['password'].value != undefined || this.CreateTenantForm.controls['password'].value != null && this.url == null)) {
    //    //  alert(4);
    //      formData.append('is_avatar_removed', this.url3 );
    //       formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //       formData.append('name', this.CreateTenantForm.controls['name'].value);
    //       formData.append('email', this.CreateTenantForm.controls['email'].value);
    //       formData.append('password', this.CreateTenantForm.controls['password'].value);
    //       formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //       formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //     } else if((this.CreateTenantForm.controls['password'].value != undefined || this.CreateTenantForm.controls['password'].value != null )  && this.fileName == null && this.url == null){
    //    //   alert(1);
    //      // formData.append('avatar_path', this.url2);
    //       formData.append('is_avatar_removed', this.url3 );
    //       formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //       formData.append('name', this.CreateTenantForm.controls['name'].value);
    //       formData.append('email', this.CreateTenantForm.controls['email'].value);
    //       formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //       formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //     }
    //     else if(this.fileName != null && (this.CreateTenantForm.controls['password'].value != undefined || this.CreateTenantForm.controls['password'].value != null ) && this.url == null){
    //    // formData.append('avatar_path', this.url2);
    //  // alert(2);
    //     formData.append('is_avatar_removed', this.url3 );
    //     formData.append('name', this.fileName);
    //     formData.append('avatar', this.files);
    //     formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //     formData.append('name', this.CreateTenantForm.controls['name'].value);
    //     formData.append('email', this.CreateTenantForm.controls['email'].value);
    //     formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //     formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //   }
    //   else if(this.CreateTenantForm.controls['password'].value == undefined && this.fileName == null && this.url == null){
    //     // formData.append('avatar_path', this.url2);
    //   //  alert(3);
    //     formData.append('is_avatar_removed', this.url3 );
    //     formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //     formData.append('name', this.CreateTenantForm.controls['name'].value);
    //     formData.append('email', this.CreateTenantForm.controls['email'].value);
    //     formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //     formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //    }

    //      else if(this.CreateTenantForm.controls['password'].value == undefined && this.fileName == null ){
    //       formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //       formData.append('name', this.CreateTenantForm.controls['name'].value);
    //       formData.append('email', this.CreateTenantForm.controls['email'].value);
    //       formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //       formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //     } else if(this.fileName != null && this.CreateTenantForm.controls['password'].value == undefined) {
    //       formData.append('name', this.fileName);
    //       formData.append('avatar', this.files);
    //       formData.append('UserId', this.CreateTenantForm.controls['UserId'].value);
    //       formData.append('name', this.CreateTenantForm.controls['name'].value);
    //       formData.append('email', this.CreateTenantForm.controls['email'].value);
    //       formData.append('role_id', this.CreateTenantForm.controls['role_id'].value);
    //       formData.append('is_active', this.CreateTenantForm.controls['is_active'].value);
    //     }
    let user_ids_array: number[] = this.CreateTenantForm.controls['admins'].value;

    if (!user_ids_array || user_ids_array.length === 0) {
      return;
    }

    user_ids_array.forEach((id: number) => {
      formData.append('user_ids[]', id.toString());
    });
    this.projectapicall = true;
    this.tenantservice
      .updateTenentData(UpdatedTenantDetails.id, formData)
      .subscribe({
        next: (response) => {
          // console.log("responce 2000",response)
          this.modalService.dismissAll();
          this.CreateTenantForm.reset();
          // this.dropimg = false;
          this.previewImg = true;
          this.notificationService.showSuccess(response.message);
          // window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
          //  this.getTenantList({});
          for (let i = 0; i < this.tenantList.length; i++) {
            if (this.tenantList[i].id === UpdatedTenantDetails.id) {
              this.tenantList[i] = response.tenant;
              break;
            }
          }
          this.tenantList.forEach((element: any) => {
            element['initialss'] = this.getInitials(element.name);
          });

          this.resetBackendTenanterror();
        },
        error: (error) => {
          console.log(error, 'error');
          this.createTenantError = error.error.message;
          this.projectapicall = false;
          switch (error.status) {
            case 422:
              if (error.error.id) {
                this.createTenantErrorId = error.error.id[0];
              }
              if (error.error.name) {
                this.createTenantErrorName = error.error.name[0];
              }
              if (error.error.website) {
                this.tenantWebsiteError = error.error.website[0];
              }

              break;
            default:
            //Nothing
          }
          // this.notificationService.showError(error.message);
        },
      });
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;

    if (event.target.files) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    this.dropimg = false;
    this.previewImg = true;
  }

  public searchData(value: any) {
    this.currentSearchValue = value;
    this.pageNumber = 1;
    this.getTenantList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
      search: value,
    });
  }
  showUser(id: any) {
    this.router.navigate(['/user']);
  }

  //  deleteUser(deleteId:number) {
  //  this.userservice.deleteUserData(deleteId )
  //     .subscribe({
  //        next: (response) => {
  //     this.notificationService.showSuccess(response.message);
  //        this.getTenantList({});

  //     },
  //       error: (error) => {
  //         this.notificationService.showError(error.message);
  //       },
  //      });
  //  }

  setPage(page: number) {
    this.pageNumber = page;
    this.getTenantList({
      page: this.pageNumber,
      sort_field: this.currentSortedColumn,
      sort_method: this.currentSortedMethod,
      column_name: this.sortedColumn,
    });
  }
  resetImageFile(element: any) {
    element.value = '';
    this.url = null;
  }
  resetBackendTenanterror() {
    this.createTenantError = '';
    this.createTenantErrorId = '';
    this.createTenantErrorName = '';
    this.createTenantErroruserName = '';
    this.createTenantErroruserEmail = '';
    this.createTenantErroruserPassword = '';
    this.tenantWebsiteError='';
    this.CreateTenantForm.reset();
  }
  reload() {
    window.location.reload();
  }
  SendOtp() {
    // deleteTenantId;
    this.tenantservice.getDeleteOTP(this.deleteTenantId).subscribe({
      next: (response: any) => {
        this.deleteClicked = true;
        this.otpmessage = response.message;
        this.notificationService.showSuccess(response.message);
        this.sixtySecondInterval();
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }
  sixtySecondInterval() {
    this.showCount = true;
    var emitted = interval(1000);
    this.sub = emitted.subscribe((val) => {
      this.otpCount = 60 - val;
      if (this.otpCount == 0) {
        this.sub.unsubscribe();
        this.showCount = false;
      }
    });
  }
  deleteTenent() {
    let otp = {
      otp: this.deleteotp,
    };
    // console.log(otp,"delete");
    // return
    this.tenantservice.deleteTenant(this.deleteTenantId, otp).subscribe({
      next: (response: any) => {
        // this.notificationService.showSuccess(response.message);
        // this.modalService.dismissAll();
        this.getTenantList({});
        this.tenantDeleted = true;
        this.deleteSuccessMsg = response.message;
      },
      error: (error: any) => {
        // console.log(error);
        this.DeleteTenantError = error.error.otp;
        this.notificationService.showError(error.error.otp);
      },
    });
  }

  resetListSettings() {
    let listSettings: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    if (listSettings !== 'undefined' && listSettings)
      this.getSearchList = JSON.parse(listSettings);
    if (this.getSearchList && this.getSearchList.users) {
      delete this.getSearchList.users;
      this.storageService.setValue(
        'listSettings',
        JSON.stringify(this.getSearchList)
      );
    }
    this.iconVisible = true;
    this.currentSearchValue = '';
    this.currentSortedColumn = '';
    this.getTenantList({}, undefined, true);
  }
}
