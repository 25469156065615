<div class="d-flex justify-content-between align-items-center mt-3 mb-3">

  <div class="d-flex align-items-center gap-3">
    <a href="javascript:void(0)" class="radiusbtn d-flex align-items-center" (click)="goToDashboard()">
      <fa-icon [icon]="faArrowLeft" class="me-2"></fa-icon>
      <span>Go back to Dashboard</span>
    </a>

    <h4 class="m-0" *ngIf="type; else otherHeading">
      "{{ type }}" Clients with "{{ searchName }}" stage ({{ totalRows }})
    </h4>

    <ng-template #otherHeading>
      <h4 class="m-0">"Referenceable" Clients ({{ totalRows }})</h4>
    </ng-template>
  </div>

  <!-- Right Search Box -->
  <div class="top-right-sec w-45 d-flex" style="width: 20%;" *ngIf="isSearchable">
    <div class="input-group small-search me-3">
      <input type="text" class="form-control" placeholder="Search" tabindex="-1"
             (keyup.enter)="onSearch(inputsearch.value)"   #inputsearch aria-label="Search" />
      <div class="input-group-append rounded">
        <button class="btn" type="button" (click)="onSearch(inputsearch.value)">
          <img src="./assets/img/search-black.svg" alt="" />
        </button>
      </div>
    </div>
    <hr>
  </div>
</div>

<hr style="clear: both;">

<div class="bottom-table">

  <table class="table table-bordered">
    <thead>
    <tr>
      <th scope="col" style="width: 14%; text-align: center;"
          (mouseenter)="mouseEnter('name')"
          (mouseleave)="mouseLeave('name')">
        Client Name
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'name' })">
    <fa-icon
      *ngIf="sortfield !== 'name' && mouseOnColumnName === 'name'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'name' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'name' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('client_probability_status_id')"
          (mouseleave)="mouseLeave('client_probability_status_id')">
        Probability
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'client_probability_status_id' })">
    <fa-icon
      *ngIf="sortfield !== 'client_probability_status_id' && mouseOnColumnName === 'client_probability_status_id'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'client_probability_status_id' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'client_probability_status_id' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 16%; text-align: center;"
          (mouseenter)="mouseEnter('primary_contact')"
          (mouseleave)="mouseLeave('primary_contact')">
        Contact Person
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'primary_contact' })">
    <fa-icon
      *ngIf="sortfield !== 'primary_contact' && mouseOnColumnName === 'primary_contact'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'primary_contact' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'primary_contact' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('last_contacted_at')"
          (mouseleave)="mouseLeave('last_contacted_at')">
        Last Contacted
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'last_contacted_at' })">
    <fa-icon
      *ngIf="sortfield !== 'last_contacted_at' && mouseOnColumnName === 'last_contacted_at'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'last_contacted_at' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'last_contacted_at' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('contact_number')"
          (mouseleave)="mouseLeave('contact_number')">
        Contact Number
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'contact_number' })">
    <fa-icon
      *ngIf="sortfield !== 'contact_number' && mouseOnColumnName === 'contact_number'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'contact_number' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'contact_number' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 8%; text-align: center;"
          (mouseenter)="mouseEnter('contact_email')"
          (mouseleave)="mouseLeave('contact_email')">
        Email
        <span class="sortbyspanr"
              (click)="getClientList({ sort_field: 'contact_email' })">
    <fa-icon
      *ngIf="sortfield !== 'contact_email' && mouseOnColumnName === 'contact_email'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'contact_email' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'contact_email' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngIf="clientList.length === 0">
      <td colspan="9" class="text-center">No clients available</td>
    </tr>
    <tr *ngFor="let cl of clientList">

      <td
        [ngStyle]="{
            color: cl?.id ? 'blue' : 'black'
          }"
      >
        <div class="form-check">
          <label class="form-check-label" for="flexCheckDefault">
            <div style="cursor: pointer;" class="top-left-details w-60 d-flex align-middle">
              <div class="top_right_profile ps-3 d-inline-block">
                <p>
                    <span style="padding-left: 0px" (click)="open('OpenClient',cl.id)">
                      {{ cl?.name }} </span
                    >
                </p>
              </div>
            </div>
          </label>
        </div>
      </td>
      <td>
        {{ cl?.probability_status?.name || '-' }}
      </td>
      <!-- here -->
      <td
        [ngStyle]="{
            color: cl?.candidate_id ? 'blue' : 'black'
          }"
        (click)="showCandidate(cl.candidate_id)"
      >
        {{ cl?.primary_contact }}
      </td>

      <td>
        {{ (cl?.last_contacted_at | date : "M/d/yyyy") || "-" }}
      </td>

      <td>
        {{ cl?.contact_number || "-" }}
      </td>

      <td style="text-align:left;">
        <a style="text-decoration: none;"
           href="mailto:{{cl.contact_email}}">
          {{cl.contact_email?cl.contact_email:"---"}}
        </a>
      </td>

    </tr>
    </tbody>
  </table>
</div>

<div style="margin-bottom: 10%" class="pagination-main">
  <label for="totalRows" class="list-totalrecords"
  >Records: {{ clientList.length }} of {{ totalRows }}</label
  >
</div>
