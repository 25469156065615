<div class="login_page">
    <div class="container custom_login" id="container">
        <div class="form-container log-in-container">

            <div class="left-container">
                <form [formGroup]="registerForm" class="w-100 row" *ngIf="showLogin">

                    <div>
                        <h6 *ngIf="step == '1'" class="register-msg">Please register if you do not have account in
                            TATracker, or simply
                            Login.
                        </h6>
                        <h1>Signup</h1>

                        <div *ngIf="step == '1'">


                            <div>
                                <input type="text" class="form-control" formControlName="first_name"
                                    placeholder="First Name"
                                    [ngClass]="{ 'is-invalid': submitted && f['first_name'].errors }" />
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    *ngIf="submitted && f['first_name'] && f['first_name'].errors && f['first_name'].errors['required']">
                                    First Name is required
                                </span>
                            </div>

                            <div>
                                <input type="text" class="form-control" formControlName="last_name"
                                    placeholder="Last Name"
                                    [ngClass]="{ 'is-invalid': submitted && f['last_name'].errors }" />
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    *ngIf="submitted && f['last_name'] && f['last_name'].errors && f['last_name'].errors['required']">
                                    Last Name is required
                                </span>
                            </div>

                            <div>
                                <input type="email" class="form-control" formControlName="email" placeholder="Email"
                                    [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    *ngIf="submitted && f['email'] && f['email'].errors && f['email'].errors['required']">
                                    Email is required
                                </span>
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    class="w-100 col-12 ps-0"
                                    *ngIf="f['email'] && f['email'].errors && f['email'].errors['pattern']">
                                    Invalid Email Address
                                </span>
                            </div>

                            <div class="password-container" style="width:284px;height: 40px !important;">
                                <input type="password" style="padding:10;width:260px;height: 40px !important;"
                                    class="form-control" placeholder="Password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                    *ngIf="!showPassword" />
                                <input type="text" style="width:260px;height: 40px !important;" class="form-control"
                                    placeholder="Password" formControlName="password"
                                    [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                    *ngIf="showPassword" />
                                <em class="fa fa-eye" (click)="togglePassword()" *ngIf="showPassword"
                                    style="margin-right: 40px !important;"></em>
                                <em class="fa fa-eye-slash" (click)="togglePassword()" *ngIf="!showPassword"
                                    style="margin-right: 40px !important;"></em>
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    *ngIf="submitted && f['password'] && f['password'].errors && f['password'].errors['required']">
                                    Password is required
                                </span>
                                <span class="error-message"
                                    style="color: red; font-size: 11px; text-align: left; display: block;"
                                    *ngIf="f['password'].errors && (f['password'].errors['minlength'] || f['password'].errors['maxlength'])">
                                    Passwords range from 6-16 characters.
                                </span>
                            </div>




                            <div class="rememberMe mt-3">
                                <label for="">By continuing you are agreeing to TATracker’s <a
                                        href="https://www.tatracker.cloud/terms-of-service"
                                        class="text-primary text-decoration-none fst-normal fw-medium">Terms of Use</a>
                                    and <a href="https://www.tatracker.cloud/privacy-policy"
                                        class="text-primary text-decoration-none fst-normal fw-medium">Privacy
                                        Policy </a>. By continuing, you consent to
                                    TATracker sending you job recommendations based on jobs you apply to and preferences
                                    you share, career advice
                                    and content, and updates about TATracker services and features.
                                </label>
                            </div>

                            <div style="margin-top: 10px;">
                                <div style="color:red; font-size: 10px;text-align: left;" *ngIf="emailError">
                                    {{emailError}}
                                </div>
                                <div style="color:red; font-size: 10px;text-align: left;" *ngIf="passwordError">
                                    {{passwordError}}
                                </div>
                            </div>

                            <button (click)="verifyemail()" style="margin-top:1px; width: 260px;">Submit</button>



                        </div>

                        <div *ngIf="step == '2'">
                            <div>
                                <div style="padding:10px; font-size: 11px;padding-left: 1px;" class="text-success"
                                    *ngIf="registersuccess">
                                    {{registersuccess}} </div>
                                <div style="padding:10px; font-size: 11px;padding-left: 1px;" class="text-success"
                                    *ngIf="optSendsuccesmsg">
                                    {{optSendsuccesmsg}}</div>
                                <div style="padding:10px; font-size: 11px;padding-left: 1px;" class="text-danger"
                                    *ngIf="otpError">
                                    {{otpError }}</div>
                            </div>



                        </div>

                        <div *ngIf="step == '3'">
                            <div style="padding:10px; font-size: 14px;padding-left: 1px;" class="text-success"
                                *ngIf="registersuccess">
                                {{registersuccess}} </div>
                            <button (click)="successregister()" style="margin-top:2px; width: 260px;">Ok</button>
                        </div>

                    </div>


                    <div style="margin-top:1px;">

                        <pre
                            class="pre-wrap">If already registered?<a (click)="navigateToLogin()"
                                                    class="forgot_password signup-btn-l" style="margin: 0px; cursor: pointer;">Login</a></pre>

                    </div>


                </form>
            </div>

        </div>
        <div class="overlay-container">
            <div class="overlay">
                <div class="overlay-panel overlay-right">
                    <img style="width:100%" src={{applogo}} *ngIf="showAdminlogo" alt=""/>
                    <img style="width:100%" src="assets/img/TATlogo.PNG" *ngIf="!tenantlogo && !showAdminlogo" alt=""/>
                    <img src={{url?url:logo}}  alt="" *ngIf="tenantlogo" style="width:100%" />
                </div>
            </div>
        </div>
    </div>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-kenU1KFdBIe4zVF0s0G1M5b4hcpxyD9F7jL+jjXkk+Q2h455rYXK/7HAuoJl+0I4"
        crossorigin="anonymous"></script>
</div>
