import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { NgChartsModule } from 'ng2-charts';
import { CandidateSearchComponent } from './pages/candidate-search/candidate-search.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ClientSearchComponent } from './pages/client-search/client-search.component';
import { ClientDrillDownComponent } from './pages/client-drill-down/client-drill-down.component';
import { CandidateDrillDownComponent } from './pages/candidate-drill-down/candidate-drill-down.component';

@NgModule({
  declarations: [
    CandidateSearchComponent,
    ClientSearchComponent,
    ClientDrillDownComponent,
    CandidateDrillDownComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    FontAwesomeModule
  ]
})
export class DashboardModule { }
