<div *ngIf="selectedNotification && selectedNotification.type !== 'popup'"
     class="notification-bar"
     [ngClass]="getNotificationClass(selectedNotification.bar_type)">

  <div class="notification-content">
    <img [src]="megaphoneIcon" alt="icon" class="notification-icon">
    <div class="notification-text">
      <div>
        <strong class="notification-title" [ngClass]="getNotificationClass(selectedNotification.bar_type)">
          {{ selectedNotification.title }}
        </strong>
      </div>
      <div class="notification-description"
           [innerHTML]="selectedNotification.description"
           [ngClass]="getNotificationClass(selectedNotification.bar_type)">
      </div>
    </div>
  </div>

  <button type="button" class="border-0 bg-transparent notification-button me-4" (click)="closeNotificationBar()">
    <a class="ms-4" [ngClass]="getNotificationBtnClass(selectedNotification.bar_type)">OK</a>
  </button>
</div>

<div class="d-flex justify-content-between align-items-center mt-3 mb-3">

  <div class="top-left-sec w-50">
    <h3 class="d-inline-block align-middle m-0">{{ pageTitle }}</h3>
    <div
      class="d-inline-block align-middle m-0 ps-3 pt-1"
      style="cursor: pointer"
      title="reset"
      (click)="onRefreshClick()"
    >
      <em class="fa fa-refresh"></em>
    </div>

    <button
      type="button"
      class="border-0 bg-transparent" (click)="openModal(addModal)"
    >
      <a class="blue-btn ms-4">+ Add</a>
    </button>

  </div>
</div>

<hr style="clear: both;">


<div class="bottom-table">

  <table class="table table-bordered">
    <thead>
    <tr>

      <th scope="col" style="width: 15%; text-align: center;"
          (mouseenter)="mouseEnter('title')"
          (mouseleave)="mouseLeave('title')">
        Title
        <span class="sortbyspanr"
              (click)="getNotifications({ sort_field: 'title' })">
    <fa-icon
      *ngIf="sortfield !== 'title' && mouseOnColumnName === 'title'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'title' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'title' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 40%; text-align: center;"
          (mouseenter)="mouseEnter('description')"
          (mouseleave)="mouseLeave('description')">
        Description
        <span class="sortbyspanr"
              (click)="getNotifications({ sort_field: 'description' })">
    <fa-icon
      *ngIf="sortfield !== 'description' && mouseOnColumnName === 'description'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'description' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'description' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>
      <th scope="col" style="width: 5%; text-align: center;"
          (mouseenter)="mouseEnter('type')"
          (mouseleave)="mouseLeave('type')">
        Type
        <span class="sortbyspanr"
              (click)="getNotifications({ sort_field: 'type' })">
    <fa-icon
      *ngIf="sortfield !== 'type' && mouseOnColumnName === 'type'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'type' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'type' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>
      <th scope="col" style="width: 20%; text-align: center;"
          (mouseenter)="mouseEnter('created_at')"
          (mouseleave)="mouseLeave('created_at')">
        Date
        <span class="sortbyspanr"
              (click)="getNotifications({ sort_field: 'created_at' })">
    <fa-icon
      *ngIf="sortfield !== 'created_at' && mouseOnColumnName === 'created_at'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'created_at' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'created_at' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>
      <th scope="col" style="width: 8%; text-align: center;"
          (mouseenter)="mouseEnter('is_active')"
          (mouseleave)="mouseLeave('is_active')">
        Status
        <span class="sortbyspanr"
              (click)="getNotifications({ sort_field: 'is_active' })">
    <fa-icon
      *ngIf="sortfield !== 'is_active' && mouseOnColumnName === 'is_active'"
      [icon]="faArrowUp"
      style="color: grey; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'is_active' && sortmethod === 'asc'"
      [icon]="faArrowUp"
      style="color: black; font-size: x-large">
    </fa-icon>

    <fa-icon
      *ngIf="sortfield === 'is_active' && sortmethod === 'desc'"
      [icon]="faArrowDown"
      style="color: black; font-size: x-large">
    </fa-icon>
  </span>
      </th>

      <th scope="col" style="width: 20%; text-align: center;">
        Action
      </th>

    </tr>
    </thead>
    <tbody>
    <tr *ngIf="notificationList.length === 0">
      <td colspan="9" class="text-center">No notifications available</td>
    </tr>
    <tr *ngFor="let cl of notificationList">

      <td>
        <div class="truncate-text-td">
          {{ cl?.title }}
        </div>
      </td>
      <td>
        <div class="truncate-text-td">
          {{ stripHtmlTags(cl?.description || '') }}
        </div>
      </td>
      <td style="text-transform: capitalize;">
        {{ cl?.type }}
      </td>
      <td>
        {{ (cl?.start_date | date : "M/d/yyyy") + ' To ' + (cl?.end_date | date : "M/d/yyyy") || "-" }}
      </td>
      <td style="text-transform: capitalize;">
        {{ cl?.is_active ? 'Active' : 'Inactive' }}
      </td>

      <td>
        <div style="cursor: pointer">
          <ng-container *ngIf="currentTenantId !== '0' && cl.is_superadmin_notification === 1; else actions">
            <span title="System notification cannot be changed or removed">N/A</span>
          </ng-container>
          <ng-template #actions>
            <a><em class="bi bi-eye-fill me-3 custom-icon-color" title="Preview" (click)="displayNotification(cl)"></em></a>
            <a><em class="bi bi-pencil-square me-3" title="Edit" (click)="openEditModal(cl, editModal)"></em></a>
            <a><em class="bi bi-trash" title="Delete" (click)="openDeleteModal(cl)"></em></a>
          </ng-template>
        </div>
      </td>

    </tr>
    </tbody>
  </table>
</div>

<!--<div style="margin-bottom: 10%" class="pagination-main">-->
<!--  <label for="totalRows" class="list-totalrecords"-->
<!--  >Records: {{ notificationList.length }} of {{ totalRows }}</label-->
<!--  >-->
<!--</div>-->

<div class="pagination-main">
  <label for="" class="list-totalrecords">Total Records: {{ totalRows }}</label>
  <ul
    *ngIf="pager.pages && pager.pages.length && totalRows > pageSize"
    class="pagination justify-content-end m-0"
  >
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elseFirst"
        class="page-link page-show"
      ><<</a
      >
      <ng-template #elseFirst>
        <a (click)="setPage(1)" class="page-link"><<</a>
      </ng-template>
    </li>
    <li class="page-item" [ngClass]="{ disabled: pager.currentPage === 1 }">
      <a
        *ngIf="pager.currentPage === 1; else elsePrevious"
        class="page-link page-show"
      >
        <</a
      >
      <ng-template #elsePrevious>
        <a (click)="setPage(pager.currentPage - 1)" class="page-link"> <</a>
      </ng-template>
    </li>

    <li class="page-item">
      <a class="page-link">{{ pager.currentPage }}</a>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseNext"
        class="page-link page-show"
      >></a
      >
      <ng-template #elseNext>
        <a (click)="setPage(pager.currentPage + 1)" class="page-link">></a>
      </ng-template>
    </li>
    <li
      class="page-item"
      [ngClass]="{ disabled: pager.currentPage === pager.totalPages }"
    >
      <a
        *ngIf="pager.currentPage === pager.totalPages; else elseLast"
        class="page-link page-show"
      >>></a
      >
      <ng-template #elseLast>
        <a (click)="setPage(pager.totalPages)" class="page-link">>></a>
      </ng-template>
    </li>
  </ul>
</div>

<ng-template #addModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Notification</h5>
    <button (click)="modal.dismiss('Cross click');"
            type="button"
            class="close"
            aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="notificationForm">
      <div class="row">
        <div [class.col-6]="isTypeShown" [class.col-12]="!isTypeShown" class="mb-3">
          <label class="form-label">Title</label>
          <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
          <input type="text" class="form-control" formControlName="title" placeholder="Enter Title" maxlength="50" (input)="onTitleChange($event)">
          <small class="text-muted">{{ titleCharCount }}/{{ titleMaxLength }} characters</small>
          <div *ngIf="notificationForm.get('title')?.invalid && notificationForm.get('title')?.touched">
            <div class="text-danger" *ngIf="notificationForm.get('title')?.errors?.['required']">
              Title is required.
            </div>
            <div class="text-danger" *ngIf="notificationForm.get('title')?.errors?.['maxlength']">
              Title cannot exceed {{ notificationForm.get('title')?.errors?.['maxlength'].requiredLength }} characters.
            </div>
          </div>
        </div>

        <div class="col-6 mb-3" *ngIf="isTypeShown">
          <label class="form-label">Type</label>
          <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
          <select class="form-select form-control" formControlName="type" id="notificationType" placeholder="Select Type">
            <option value="" disabled selected>Select Type</option>
            <option *ngFor="let type of notificationTypes" [value]="type">{{ type }}</option>
          </select>
          <div *ngIf="notificationForm.get('type')?.touched && notificationForm.get('type')?.invalid" class="text-danger">
            Type is required.
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6" *ngIf="isBarSelected">
          <label class="form-label">Select Message Type</label>
          <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <select class="form-select form-control" formControlName="selectedMessageType" (change)="updateSelectedColor($event)">
            <option value="" disabled selected>Select Message Type</option>
            <option value="action" data-color="#C03C2C" style="background-color:#C03C2C; color:white;">Action Required</option>
            <option value="warning" data-color="#F0CD45" style="background-color:#FFF2CC; color:black;">Warning</option>
            <option value="informational" data-color="#46546F" style="background-color:#46546F; color:white;">Informational</option>
            <option value="success" data-color="#34835A" style="background-color:#34835A; color:white">Success</option>
          </select>
          <div *ngIf="notificationForm.get('selectedMessageType')?.touched && notificationForm.get('selectedMessageType')?.invalid" class="text-danger">
            Message type is required.
          </div>
        </div>

        <div [class.col-6]="isBarSelected" [class.col-12]="!isBarSelected">
          <div class="row">
            <div class="col-6">
              <label for="">Start Date</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="date" formControlName="startDate" class="form-control" [min]="minStartDate">
              <div *ngIf="notificationForm.get('startDate')?.touched && notificationForm.get('startDate')?.invalid" class="text-danger">
                Start date is required.
              </div>
            </div>
            <div class="col-6">
              <label for="">End Date</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="date" formControlName="endDate" class="form-control" [min]="minEndDate">
              <div *ngIf="notificationForm.get('endDate')?.touched && notificationForm.get('endDate')?.invalid" class="text-danger">
                End date is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label class="form-label">Description</label>
        <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
        <angular-editor formControlName="description" [config]="editorConfig" (ngModelChange)="onDescriptionChange($event)"></angular-editor>
        <div class="d-flex justify-content-between align-items-center">
          <small class="text-muted">{{ descriptionCharCount }}/{{ descriptionMaxLength }} characters</small>

          <div class="toggle-container ms-auto mt-2">
            <label class="switch">
              <input type="checkbox" formControlName="is_active" [checked]="notificationForm.get('is_active')?.value" (change)="toggleDismiss($event)">
              <span class="slider round"></span>
            </label>
            <span class="toggle-text">Active</span>
          </div>
        </div>

        <div *ngIf="notificationForm.get('description')?.invalid && notificationForm.get('description')?.touched">
          <div class="text-danger" *ngIf="notificationForm.get('description')?.errors?.['required']">
            Description is required.
          </div>
          <div class="text-danger" *ngIf="notificationForm.get('description')?.errors?.['maxlength']">
            Description cannot exceed {{ notificationForm.get('description')?.errors?.['maxlength'].requiredLength }} characters.
          </div>
        </div>

      </div>

    </form>
  </div>
  <div class="modal-footer">
    <div *ngIf="apicall"
         class="spinner-border">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button *ngIf="!apicall"
            (click)="modal.close('Cancel click');"
            type="button"
            class="filter">
      Cancel
    </button>
    <button *ngIf="!apicall"
            (click)="saveNotification()"
            type="button"
            class="blue-btn ms-">
      Save
    </button>

  </div>
</ng-template>

<ng-template #editModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Edit Notification</h5>
    <button (click)="modal.dismiss('Cross click');"
            type="button"
            class="close"
            aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editNotificationForm">
      <div class="row">
        <div [class.col-6]="isTypeShown" [class.col-12]="!isTypeShown" class="mb-3">
          <label class="form-label">Title</label>
          <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
          <input type="text" class="form-control" formControlName="title" placeholder="Enter Title" maxlength="50" (input)="onTitleChange($event)">
          <small class="text-muted">{{ titleCharCount }}/{{ titleMaxLength }} characters</small>
          <div *ngIf="editNotificationForm.get('title')?.touched && editNotificationForm.get('title')?.errors" class="text-danger">
            <div *ngIf="editNotificationForm.get('title')?.errors?.['required']">Title is required.</div>
            <div *ngIf="editNotificationForm.get('title')?.errors?.['maxlength']">
              Title cannot exceed {{ editNotificationForm.get('title')?.errors?.['maxlength'].requiredLength }} characters.
            </div>
          </div>
        </div>

        <div class="col-6 mb-3" *ngIf="isTypeShown">
          <label class="form-label">Type</label>
          <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
          <select class="form-select form-control" formControlName="type" id="notificationTypeEdit">
            <option value="" disabled selected>Select Type</option>
            <option *ngFor="let type of notificationTypes" [value]="type">{{ type }}</option>
          </select>
          <div *ngIf="editNotificationForm.get('type')?.touched && editNotificationForm.get('type')?.invalid" class="text-danger">
            Type is required.
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-6" *ngIf="isBarSelected">
          <label class="form-label">Select Message Type</label>
          <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
          <select class="form-select form-control" formControlName="selectedMessageType" (change)="updateSelectedColor($event)">
            <option value="" disabled selected>Select Message Type</option>
            <option value="action" data-color="#C03C2C" style="background-color:#C03C2C; color:white;">Action Required</option>
            <option value="warning" data-color="#F0CD45" style="background-color:#FFF2CC; color:black;">Warning</option>
            <option value="informational" data-color="#46546F" style="background-color:#46546F; color:white;">Informational</option>
            <option value="success" data-color="#34835A" style="background-color:#34835A; color:white">Success</option>
          </select>
          <div *ngIf="editNotificationForm.get('selectedMessageType')?.touched && editNotificationForm.get('selectedMessageType')?.invalid" class="text-danger">
            Message type is required.
          </div>
        </div>

        <div [class.col-6]="isBarSelected" [class.col-12]="!isBarSelected">
          <div class="row">
            <div class="col-6">
              <label for="">Start Date</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="date" formControlName="startDate" class="form-control" [min]="minStartDate">
              <div *ngIf="editNotificationForm.get('startDate')?.touched && editNotificationForm.get('startDate')?.invalid" class="text-danger">
                Start date is required.
              </div>
            </div>
            <div class="col-6">
              <label for="">End Date</label>
              <span class="text-danger" style="font-size: 14px;">&nbsp;<strong>*</strong></span>
              <input type="date" formControlName="endDate" class="form-control" [min]="minEndDate">
              <div *ngIf="editNotificationForm.get('endDate')?.touched && editNotificationForm.get('endDate')?.invalid" class="text-danger">
                End date is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label class="form-label">Description</label>
        <span class="text-danger" style="font-size: 14px">&nbsp;<strong>*</strong></span>
        <angular-editor formControlName="description" [config]="editorConfig" (ngModelChange)="onDescriptionChange($event)"></angular-editor>
        <div class="d-flex justify-content-between align-items-center">
          <small class="text-muted">{{ descriptionCharCount }}/{{ descriptionMaxLength }} characters</small>
          <div class="toggle-container ms-auto mt-2">
            <label class="switch">
              <input type="checkbox" formControlName="is_active" [checked]="editNotificationForm.get('is_active')?.value" (change)="toggleDismiss($event)">
              <span class="slider round"></span>
            </label>
            <span class="toggle-text">Active</span>
          </div>

        </div>

        <div *ngIf="editNotificationForm.get('description')?.touched && editNotificationForm.get('description')?.errors" class="text-danger">
          <div *ngIf="editNotificationForm.get('description')?.errors?.['required']">Description is required.</div>
          <div *ngIf="editNotificationForm.get('description')?.errors?.['maxlength']">
            Description cannot exceed {{ editNotificationForm.get('description')?.errors?.['maxlength'].requiredLength }} characters.
          </div>
        </div>

      </div>

    </form>
  </div>
  <div class="modal-footer">
    <div *ngIf="apicall"
         class="spinner-border">
      <span class="visually-hidden">Loading...</span>
    </div>
    <button *ngIf="!apicall"
            (click)="modal.close('Cancel click');"
            type="button"
            class="filter">
      Cancel
    </button>
    <button *ngIf="!apicall"
            (click)="updateNotification()"
            type="button"
            class="blue-btn">
      Update
    </button>
  </div>
</ng-template>

<ng-template #previewModal let-modal>
  <div class="modal fade show d-block" tabindex="-1">
    <div class="custom-modal-backdrop"></div>
    <div class="modal-dialog modal-dialog-centered" id="previewModalDialog">
      <div class="modal-content p-4 custom-notification" style="background-color: {{selectedNotification.backgroundColor}}">
        <div class="d-flex justify-content-center">
          <img src="{{megaphoneIcon}}" class="img-fluid" style="width: 80px;">
        </div>
        <h4 class="mt-3 mb-3 text-center" style="color:#43a8a0;">{{ selectedNotification.title }}</h4>
        <div [innerHTML]="sanitizeHtml(selectedNotification.description)"></div>
        <div class="d-flex justify-content-center mt-3">
          <button type="button" class="border-0 bg-transparent" (click)="modal.close('Close click')"><a class="blue-btn ms-4">OK</a></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmModal  let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Notification</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="mb-3">
        <p>Are you sure you want to delete  <strong>{{ selectedNotificationToDelete?.title }}? </strong></p>
      </div>
    </form>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-secondary cancelbutton"
      (click)="modal.dismiss('cancel click')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn btn-danger dangerbtn"
      (click)="confirmDelete(modal)"
    >
      Delete
    </button>
  </div>
</ng-template>
