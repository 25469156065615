<div class="candidate_details">
  <div
    style="position: relative"
    class="top-row d-flex justify-content-between"
  >
    <div class="top-left-details w-60 d-flex align-middle">
      <div class="top_profile">
        <div style="position: absolute; right: 2%">
          <button
            (click)="
              open(
                ViewClientModal,
                'viewClient',
                clientData?.id,
                undefined,
                'xl'
              )
            "
            type="button"
            class="blue-btn edit-btn ms-"
          >
            Edit client
          </button>
        </div>
        <div class="container1">
          <img *ngIf="clientData?.logo_path; else fallbackImage" [src]="clientData?.logo_path" height="120" width="120"
          class="clientlogobgimg img-fluid image1" alt="" />

        <ng-template #fallbackImage>
          <em class="bi tag-icon bi-buildings-fill"
          style="top: 0; font-size: 120px; width: 120px; height: 120px; display: inline-block; line-height: 120px;">
      </em>
        </ng-template>
          <a
            class="edit-icon1"
            (click)="selectFile1.click()"
            style="padding-right: 5px; padding-left: 5px; background: white"
          >
            <em class="bi bi-camera-fill" title="Edit Image"></em>
          </a>
          <input
            type="file"
            id="file-input1"
            height="120"
            width="120"
            style="display: none"
            accept="image/*"
            #selectFile1
            max-file-size="1024"
            name="logo"
            class="form-control clientlogobgimg img-fluid image1"
            (change)="attachLogoFiles($event)"
          />
        </div>
      </div>

      <div class="top_right_profile ps-3 d-inline-block">
        <h3 class="d-inline-block align-middle m-0">
          {{ clientData?.name || "---" }}
        </h3>

        <p>
          {{
            !clientData?.state || clientData?.state === "null"
              ? "---"
              : clientData?.state
          }}
        </p>

        <a
          style="cursor: pointer"
          title="Click to go back to list"
          (click)="returnToBack()"
          ><em class="bi bi-arrow-left-short"></em> Back to list</a
        >
      </div>
    </div>
  </div>

  <div class="row details-bottom-table">
    <div class="accordion col-4" id="accordionPanelsStayOpenExample">
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingOne">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseOne"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseOne"
          >
            Client Summary
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseOne"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingOne"
          title="Expand/Collapse"
        >
          <div class="accordion-body">
            <ul class="row list-unstyled">
              <li class="col-5">Name</li>
              <li class="col-7">
                {{ clientData?.name || "---" }}
              </li>
              <li class="col-5">Industry</li>
              <li class="col-7">
                {{ clientData?.industry || "---" }}
              </li>
              <li class="col-5">Address</li>
              <li class="col-7">
                {{ clientData?.street_address || "---" }}
              </li>
              <li class="col-5">City</li>
              <li class="col-7">
                {{ clientData?.city || "---" }}
              </li>
              <li class="col-5">Postal Code</li>
              <li class="col-7">
                {{ clientData?.postal_code || "---" }}
              </li>
              <li class="col-5">Website</li>
              <li class="col-7">
                {{
                  clientData?.website && clientData?.website !== "null"
                    ? clientData?.website
                    : "---"
                }}
              </li>
              <li class="col-5 mt-4">Referenceable Client</li>

              <li class="col-7 mt-4">
                {{ clientData?.is_referenceable ? "Yes" : "No" }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapseTwo"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapseTwo"
          >
            Client Status
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapseTwo"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-headingTwo"
        >
          <div class="accordion-body">
            <ul class="row list-unstyled">
              <li class="col-5">Account Owner</li>
              <li class="col-7">
                {{ clientData?.owner?.name || "---" }}
              </li>

              <li class="col-5">No Of Searches</li>
              <li class="col-7">
                {{ clientData?.projects_count }}
              </li>

              <li class="col-5">Client Stage</li>
              <li class="col-7">
                {{ clientData?.status?.name || "---" }}
              </li>
              <li class="col-5">Client Probability</li>
              <li class="col-7">
                {{ clientData?.probability_status?.name || "---" }}
              </li>
              <li class="col-5">Web Link 1</li>
              <li class="col-7">
                {{ clientData?.web_link_one || "---" }}
              </li>
              <li class="col-5">Web Link 2</li>
              <li class="col-7">
                {{ clientData?.web_link_two || "---" }}
              </li>
              <li class="col-5">Web Link 3</li>
              <li class="col-7">
                {{ clientData?.web_link_three || "---" }}
              </li>
              <li class="col-5 mt-4">Description</li>
              <p style="font-size: 12px">
                {{ clientData?.description || "---" }}
              </p>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion col-4" id="accordionPanelsStayOpenExample-two">
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingfour">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapsefour"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapsefour"
          >
            Contacts
          </button>
          <a
            (click)="open(createContact, 'createContact')"
            href="javascript:void(0)"
            class="edit"
            title="Add candidate to search"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>

        <div
          id="panelsStayOpen-collapsefour"
          class="accordion-collapse collapse show scrollsearch"
          aria-labelledby="panelsStayOpen-headingfour"
        >
          <div
            class="accordion-body"
            *ngFor="
              let data of clientData?.contacts;
              let i = index;
              let last = last
            "
          >
            <div class="experience d-flex justify-content-between">
              <div class="experience-left">
                <h4 *ngIf="!data.candidate_id" class="experience-left">
                  {{ data.name }}
                </h4>
                <a style="" [routerLink]="['/candidate/' + data.candidate_id]">
                  <h4
                    *ngIf="data.candidate_id"
                    style="color: #63c9c1"
                    class="experience-left"
                  >
                    {{ data.candidate?.in_first_name }}
                    {{ data.candidate?.in_last_name }}
                  </h4>
                </a>
                <p class="m-0 experience-left">
                  {{ data?.candidate?.current_position || "-" }}
                </p>
              </div>
              <div class="inner-edit col-lg-2" style="text-align: right">
                <a
                  *ngIf="!data?.candidate_id"
                  class="me-2"
                  href="javascript:void(0)"
                >
                  <em
                    (click)="open(updateContact, 'updateContact', data.id)"
                    class="bi bi-pencil-square"
                    title="Delete"
                    style="color: black"
                  ></em
                ></a>
                <a
                  (click)="open(deleteContact, 'deleteContact', data.id)"
                  href="javascript:void(0)"
                >
                  <em
                    class="bi bi-trash"
                    title="Delete"
                    style="color: black"
                  ></em
                ></a>
              </div>
            </div>
          </div>
          <div class="accordion-body" *ngIf="clientData?.contacts.length == 0">
            No contacts added
          </div>
        </div>
      </div>

      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-headingsix">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapsesix"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapsesix"
          >
            Documents
          </button>
        </h2>
        <div
          id="panelsStayOpen-collapsesix"
          class="accordion-collapse collapse show scrolldocument"
          aria-labelledby="panelsStayOpen-headingsix"
        >
          <div
            class="accordion-body"
            *ngFor="let document of documentData; let last = last"
          >
            <div class="experience d-flex justify-content-between">
              <div class="experience-left col-lg-10">
                <h4 class="m-0">
                  <img
                    width="8%"
                    height="8%"
                    src="./assets/img/doc.png"
                    alt=""
                  />
                  <a href="{{ document?.path }}" target="_blank">
                    {{ document?.name }}</a
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-body" *ngIf="documentData?.length == 0">
          No Documents added
        </div>
      </div>
    </div>

    <div class="accordion col-4" id="accordionPanelsStayOpenExample-three">
      <div class="accordion-item mb-3 rounded overflow-hidden">
        <h2 class="accordion-header" id="panelsStayOpen-heading-three-one">
          <button
            class="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#panelsStayOpen-collapse-three-one"
            aria-expanded="true"
            aria-controls="panelsStayOpen-collapse-three-one"
          >
            Client Notes
          </button>
          <a
            href="javascript:void(0)"
            class="edit"
            (click)="open(createNote, 'createNote', undefined, undefined, 'xl')"
            ><img src="./assets/img/plus-black.svg" alt=""
          /></a>
        </h2>
        <div
          id="panelsStayOpen-collapse-three-one"
          class="accordion-collapse collapse show"
          aria-labelledby="panelsStayOpen-heading-three-one"
        >
          <div class="accordion-body scrollnotes">
            <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
              <ng-select
                [items]="clientData?.contacts"
                (change)="getClientNotes()"
                class="form-control form-select"
                placeholder="All Contacts"
                bindLabel="name"
                formControlName="client_notes"
                bindValue="id"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ getContactLabel(item) }}</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <span>{{ getContactLabel(item) }}</span>
                </ng-template>
              </ng-select>
            </form>

            <div
              class="border rounded py-3 px-2 mt-2"
              *ngFor="let nl of clientNotesList"
            >
              <div class="d-flex justify-content-between mb-3">
                <div class="sub-profile d-flex align-items-center">
                  <button
                    class="profile-name"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="padding: 0px"
                  >
                    <img
                      *ngIf="nl?.creator?.avatar_path !== null"
                      style="border-radius: 50%"
                      src="{{ nl?.creator?.avatar_path }}"
                      alt=""
                    />
                    <span
                      *ngIf="nl?.creator?.avatar_path === null"
                      style="padding-left: 0px; color: white"
                      >{{ nl.initials }}</span
                    >
                  </button>

                  <h5>
                    {{ nl.creator?.name ? nl.creator?.name : "" }} <br />
                    <p class="online m-0">
                      {{ nl?.updated_at | date : "MM-dd-yyyy" }} at
                      {{
                        nl?.updated_at
                          | date
                            : "hh:mm
                      a"
                      }}
                    </p>
                  </h5>
                </div>
                <div class="inner-edit">
                  <em
                    class="bi bi-share-fill me-2"
                    *ngIf="nl.allow_export"
                  ></em>
                  <em *ngIf="nl.ms_mail_id" class="bi bi-envelope me-2"></em>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      open(editNote, 'editNote', nl.id, nl.project?.name, 'xl');
                      updateNoteContactId = nl.contact_id
                    "
                    ><em class="bi bi-pencil-square me-2"></em
                  ></a>
                  <a
                    href="javascript:void(0)"
                    (click)="
                      open(deleteNote, 'deleteNote', nl.id, nl.project?.name);
                      updateNoteContactId = nl.contact_id
                    "
                  >
                    <em class="bi bi-trash"></em
                  ></a>
                </div>
              </div>

              <p>
                <strong>
                  <a
                    [ngStyle]="{
                      'text-decoration': nl?.contact?.candidate_id
                        ? ''
                        : nl?.candidate?.id
                        ? ''
                        : 'none',
                      cursor: nl?.contact?.candidate_id
                        ? 'pointer'
                        : nl?.candidate?.id
                        ? 'pointer'
                        : ''
                    }"
                    (click)="
                      showProject3(
                        nl?.contact?.candidate_id
                          ? nl?.contact?.candidate_id
                          : nl?.candidate?.id
                      )
                    "
                    title=""
                    >{{
                      nl?.candidate
                        ? nl?.candidate?.in_first_name +
                          " " +
                          nl?.candidate?.in_last_name
                        : nl?.contact?.name
                    }}
                  </a>
                  <!-- <a
                    style="text-decoration: none;"
                    routerLink="/search"
                    title=""
                    >{{
                      nl?.client_id ? getClientName(nl?.client_id) : nl?.name
                    }}
                  </a> -->
                </strong>
              </p>

              <p class="m-0" style="white-space: pre-line">{{ nl?.note }}</p>
            </div>
            <div class="accordion-body" *ngIf="clientNotesList.length == 0">
              No notes added
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #deleteNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Note</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Do you want to delete note ?</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteNoteDetail()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #deleteContact let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Delete Contact</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="mb-3">
        <label for="">Do you want to delete the contact ?</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary cancelbutton"
        (click)="modal.close('Save click')"
      >
        Cancel
      </button>
      <button
        type="button"
        class="btn btn-danger dangerbtn"
        (click)="deleteContactById()"
      >
        Delete
      </button>
    </div>
  </ng-template>

  <ng-template #createNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title flex-grow-1" id="modal-basic-title">
        Add Client Notes
      </h4>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddNoteerror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="CreateNoteForm" #formDirective="ngForm">
      <div class="modal-body">
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Select Contact</label>
              <!-- <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              > -->
              <ng-select
                (change)="createNoteError = ''"
                [items]="clientData?.contacts"
                class="form-control form-select"
                placeholder="General"
                bindLabel="name"
                bindValue="id"
                formControlName="client_notes"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ getContactLabel(item) }}</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <span>{{ getContactLabel(item) }}</span>
                </ng-template>
              </ng-select>

              <!-- <ng-select
                [items]="projectData"
                (change)="getClientNotes()"
                class="form-control form-select"
                placeholder="All Searches"
                bindLabel="dropdown"
                formControlName="notes_project"
                bindValue="id"
                [ngClass]="{
                  'is-invalid':
                    submitted && createNoteForm['notes_project'].errors
                }"
              >
              </ng-select> -->
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="createNoteError?.length > 0"
              >
                {{ createNoteError }}
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="">Notes</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <textarea
                class="form-control"
                formControlName="notes"
                placeholder="Start typing new note"
                aria-label="Start typing new note"
                aria-describedby="button-add"
                [ngClass]="{
                  'is-invalid':
                    submitted && createNoteForm['notes_project'].errors
                }"
                rows="8"
              >
              </textarea>
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="
                  submitted &&
                  createNoteForm['notes'] &&
                  createNoteForm['notes'].errors &&
                  createNoteForm['notes'].errors['required']
                "
              >
                Note is required
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="allow_export"
              />
              <label class="form-check-label" for="allow_export">
                &nbsp;Export
              </label>
            </div>
          </div>
        </div> -->
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddNoteerror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="addNote()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #createContact let-modal>
    <div class="modal-header">
      <h4 class="modal-title flex-grow-1" id="modal-basic-title">
        Add Contact
      </h4>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddNoteerror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="createContactForm" #formDirective="ngForm">
      <div class="modal-body">
        <div class="mb-3">
          <div class="row">
            <div style="margin-left: 46%; font-size: x-small">OR</div>
            <div class="col-6">
              <ng-select
                [items]="candidateList"
                (change)="populateEmail($event); addContactErrors = {}"
                (clear)="addCandidateId = undefined; clearPrimaryContact($event)"
                (search)="onContactSearch($event)"
                class="form-control form-select"
                bindLabel="full_name"
                bindValue="candidate_id"
                formControlName="primary_contact"
                placeholder="Pick from Candidates"
              >
              </ng-select>
              <p
                style="color: red; font-size: 14px; margin: 0"
                *ngIf="addContactErrors && addContactErrors.name"
              >
                *{{ addContactErrors.name[0] }}
              </p>
              <p
                style="color: red; font-size: 14px; margin: 0"
                *ngIf="addContactErrors && addContactErrors.candidate_id"
              >
                *{{ addContactErrors.candidate_id[0] }}
              </p>
            </div>

            <div class="col-6">
              <input
                type="text"
                class="form-control"
                [readonly]="isReadOnly"
                formControlName="primary_contact_static"
                placeholder="Enter Contact Name"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Phone</label>
              <input
                type="text"
                maxlength="14"
                placeholder="Enter contact number"
                [readonly]="isReadOnly"
                formControlName="contact_number"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    submitted && createContactForm.get('contact_number')?.errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  createContactForm.get('contact_number') &&
                  createContactForm.get('contact_number')?.errors &&
                  createContactForm.get('contact_number')?.errors?.['pattern']
                "
              >
                Please enter valid phone number.
              </div>
            </div>

            <!-- <div class="mb-3" hidden>
              <label for="">Candidate Id</label>
              <input
                type="text"
                formControlName="candidate_id"
                class="form-control"
              />
            </div> -->
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Email</label>
              <input
                type="text"
                placeholder="Enter email "
                [readonly]="isReadOnly"
                formControlName="contact_email"
                class="form-control"
                [ngClass]="{
                  'is-invalid':
                    submitted && createContactForm.get('contact_email')?.errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                createContactForm.get('contact_email') &&
                createContactForm.get('contact_email')?.errors &&
                createContactForm.get('contact_email')?.errors?.['pattern']
                "
              >
                Please enter valid Email address.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddNoteerror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="addContact()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #updateContact let-modal>
    <div class="modal-header">
      <h4 class="modal-title flex-grow-1" id="modal-basic-title">
        Update Contact
      </h4>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="
          modal.dismiss('Cross click');
          this.submitted = false;
          resetBackendAddNoteerror()
        "
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="updateContactForm" #formDirective="ngForm">
      <div class="modal-body">
        <div class="mb-3">
          <div class="row">
            <div style="margin-left: 46%; font-size: x-small">OR</div>
            <div class="col-6">
              <ng-select
                [items]="candidateList"
                (change)="populateClientEmail($event); addContactErrors = {}"
                (keyup)="populateClientEmail($event)"
                (clear)="addCandidateId = undefined"
                class="form-control form-select"
                bindLabel="full_name"
                bindValue="full_name"
                formControlName="primary_contact"
                placeholder="Pick from Candidates"
              >
              </ng-select>
              <p
                style="color: red; font-size: 14px; margin: 0"
                *ngIf="updateContactDetailsErrorInCandidate?.length > 0"
              >
                *{{ updateContactDetailsErrorInCandidate }}
              </p>
              <p
                style="color: red; font-size: 14px; margin: 0"
                *ngIf="updateContactDetailsErrorInName?.length > 0"
              >
                *{{ updateContactDetailsErrorInName }}
              </p>
            </div>

            <div class="col-6">
              <input
                type="text"
                class="form-control"
                formControlName="primary_contact_static"
                placeholder="Enter Contact Name"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="">Phone</label>
              <input
                type="text"
                maxlength="14"
                placeholder="Enter contact number"
                formControlName="contact_number"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="">Email</label>
              <input
                type="text"
                placeholder="Enter email "
                formControlName="contact_email"
                class="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <button
          type="button"
          class="filter"
          (click)="
            modal.close('Save click');
            this.submitted = false;
            resetBackendAddNoteerror()
          "
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="updateContactDetails()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #editNote let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Edit Note</h4>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <form [formGroup]="UpdateNoteForm">
      <div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesError"
        >
          {{ UpdateNotesError }}
        </div>
        <div
          style="padding: 10px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesErrorNote"
        >
          {{ UpdateNotesErrorNote }}
        </div>
        <div
          style="padding: 5px; font-size: 14px; padding-left: 20px"
          class="text-danger"
          *ngIf="UpdateNotesErrorAllowexport"
        >
          {{ UpdateNotesErrorAllowexport }}
        </div>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <div class="col-6">
              <label for="">Select Contact</label>
              <!-- <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              > -->
              <ng-select
                (change)="createNoteError = ''"
                [items]="clientData?.contacts"
                class="form-control form-select"
                placeholder="General"
                bindLabel="name"
                bindValue="id"
                formControlName="client_notes"
              >
             </ng-select>

            </div>
            <div class="mb-3">
              <label for="">Notes</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <textarea
                class="form-control"
                formControlName="notes"
                placeholder="Start typing new note"
                rows="8"
                aria-label="Start typing new note"
                aria-describedby="button-add"
                [ngClass]="{
                  'is-invalid': submitted && updateNoteForm['notes'].errors
                }"
              >
              </textarea>
              <span
                style="color: red; font-size: 14px; text-align: left"
                class="w-100 col-12 ps-0"
                *ngIf="
                  submitted &&
                  updateNoteForm['notes'] &&
                  updateNoteForm['notes'].errors &&
                  updateNoteForm['notes'].errors['required']
                "
              >
                Note is required
              </span>
            </div>
          </div>
        </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="allow_export"
              />
              <label class="form-check-label" for="allow_export">
                &nbsp;Export
              </label>
            </div>
          </div>
        </div> -->
        <div class="row">
          <div
            style="padding: 10px; font-size: 14px; padding-left: 20px"
            class="text-success"
            *ngIf="isEmailNotes"
          >
            Notes taken from email
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <div class="spinner-border" *ngIf="projectapicall">
          <span class="visually-hidden">Loading...</span>
        </div>
        <input type="hidden" formControlName="notes_id" />
        <button
          type="button"
          class="filter"
          (click)="modal.close('Save click')"
          *ngIf="!projectapicall"
        >
          Cancel
        </button>
        <button
          type="button"
          class="blue-btn ms-"
          (click)="updateNoteDetail()"
          *ngIf="!projectapicall"
        >
          Save
        </button>
      </div>
    </form>
  </ng-template>

  <ng-template #ViewClientModal let-modal>
    <div class="row">
      <div class="col-lg-12">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Edit Client</h4>
          <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="
              modal.dismiss('Cross click');
              this.uploadsubmitted = false;
              this.submitted = false;
              resetBackendClienterror()
            "
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <form [formGroup]="CreateClientForm">
          <div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientError"
            >
              {{ createClientError }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorName"
            >
              {{ createClientErrorName }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorIndustry"
            >
              {{ createClientErrorIndustry }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorContactnumber"
            >
              {{ createClientErrorContactnumber }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorEmail"
            >
              {{ createClientErrorEmail }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorAddress"
            >
              {{ createClientErrorAddress }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorPostal"
            >
              {{ createClientErrorPostal }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorState"
            >
              {{ createClientErrorState }}
            </div>
            <div
              style="padding: 10px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWebsite"
            >
              {{ createClientErrorWebsite }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorDescription"
            >
              {{ createClientErrorDescription }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorPrimarycontact"
            >
              {{ createClientErrorPrimarycontact }}
            </div>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label for="">Client Name</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <input
                type="text"
                placeholder="Enter client name"
                formControlName="name"
                class="form-control"
                [ngClass]="{
                  'is-invalid': submitted && client['name'].errors
                }"
              />
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  client['name'] &&
                  client['name'].errors &&
                  client['name'].errors['required']
                "
              >
                Client Name is required.
              </div>
            </div>

            <div class="mb-3">
              <label for="">Account Owner/Originator</label>
              <span class="text-danger" style="font-size: 14px"
                >&nbsp;<strong>*</strong></span
              >
              <ng-select
                [items]="userList"
                class="form-control form-select"
                bindLabel="name"
                bindValue="id"
                formControlName="user_id"
                placeholder="Select Account Owner/Originator"
                (clear)="getUsersList()"
                (search)="getUsersList($event)"
              >
              </ng-select>
              <div
                style="color: red; font-size: 14px"
                *ngIf="
                  submitted &&
                  client['user_id'] &&
                  client['user_id'].errors &&
                  client['user_id'].errors['required']
                "
              >
                Account Owner/Originator is required.
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label for="">Industry</label>
                <input
                  type="text"
                  placeholder="Enter industry"
                  formControlName="industry"
                  class="form-control"
                />
              </div>

              <div class="col-6">
                <label for="">No. of Searches</label>
                <input
                  type="text"
                  formControlName="no_of_searches"
                  class="form-control"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-6">
                <label for="">Client Stage</label>
                <select
                  name=""
                  id=""
                  class="form-select"
                  formControlName="client_status_id"
                >
                  <option value="0">Select Client Stage</option>
                  <option
                    value="{{ cs.id }}"
                    *ngFor="let cs of clientStatusList"
                  >
                    {{ cs.name }}
                  </option>
                </select>
              </div>

              <div class="col-6">
                <label for="">Client Probability</label>
                <select
                  name=""
                  id=""
                  class="form-select"
                  formControlName="client_probability_status_id"
                >
                  <option value="0">Select Probability</option>
                  <option
                    *ngFor="let status of ClientProbabiltylist"
                    [value]="status.id"
                  >
                    {{ status.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-check pt-1 mb-3">
              <input
                class="form-check-input"
                title="is_referenceable"
                type="checkbox"
                value=""
                formControlName="is_referenceable"
              />
              <label
                class="form-check-label"
                title="is_referenceable"
                for="flexCheckDefault"
              >
                Referenceable Client
              </label>
            </div>

            <div class="mb-3">
              <div class="row">
                <label for="" style="margin: 0px">Primary Contact</label>
                <div style="margin-left: 46%; font-size: x-small">OR</div>
                <div class="col-6">
                  <ng-select
                    [items]="candidateList"
                    (change)="populateEmail($event)"
                    (clear)="clearPrimaryContact($event)"
                    class="form-control form-select"
                    (search)="onCandidateSearch($event)"
                    bindLabel="full_name"
                    bindValue="full_name"
                    formControlName="primary_contact"
                    placeholder="Pick from Candidates"
                  >
                  </ng-select>
                </div>

                <div class="col-6">
                  <input
                    type="text"
                    class="form-control"
                    [readonly]="isReadOnly"
                    formControlName="primary_contact_static"
                    placeholder="Enter Contact Name"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Phone</label>
                  <input
                    type="text"
                    maxlength="14"
                    placeholder="Enter contact number"
                    [readonly]="isReadOnly"
                    formControlName="contact_number"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['contact_number'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['contact_number'] &&
                      client['contact_number'].errors &&
                      client['contact_number'].errors['pattern']
                    "
                  >
                    Please enter valid phone number.
                  </div>
                </div>

                <div class="mb-3" hidden>
                  <label for="">Candidate Id</label>
                  <input
                    type="text"
                    formControlName="candidate_id"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Email</label>
                  <input
                    type="text"
                    placeholder="Enter email "
                    [readonly]="isReadOnly"
                    formControlName="contact_email"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['contact_email'].errors
                    }"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['contact_email'] &&
                      client['contact_email'].errors &&
                      client['contact_email'].errors['pattern']
                    "
                  >
                    Please enter valid Email address.
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="">Street address</label>
              <input
                type="text"
                placeholder="Enter street address"
                formControlName="street_address"
                class="form-control"
              />
            </div>
            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Postal code</label>
                  <input
                    type="text"
                    placeholder="Enter postal code"
                    formControlName="postal_code"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">City</label>
                  <input
                    type="text"
                    placeholder="Enter city"
                    formControlName="city"
                    class="form-control"
                    [ngClass]="{
                      'is-invalid': submitted && client['city'].errors
                    }"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-6">
                <div class="mb-3">
                  <label for="">State</label>
                  <ng-select
                    [items]="stateList"
                    class="form-control form-select"
                    bindLabel="name"
                    bindValue="name"
                    formControlName="state"
                    placeholder="Select state"
                  >
                  </ng-select>
                </div>
              </div>
              <div class="col-6">
                <div class="mb-3">
                  <label for="">Website</label>
                  <input
                    type="text"
                    placeholder="Enter website"
                    formControlName="website"
                    class="form-control"
                  />
                  <div
                    style="color: red; font-size: 14px"
                    *ngIf="
                      client['website'] &&
                      client['website'].errors &&
                      client['website'].errors['pattern']
                    "
                  >
                    Please enter a valid URL. (i.e. http://www.google.com)
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="">Description</label>
              <textarea
                type="text"
                placeholder="Enter description"
                formControlName="description"
                class="form-control"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <div class="spinner-border" *ngIf="projectapicall">
              <span class="visually-hidden">Loading...</span>
            </div>
            <button
              type="button"
              class="filter"
              (click)="
                modal.close('Save click');
                this.uploadsubmitted = false;
                this.submitted = false;
                resetBackendClienterror()
              "
              *ngIf="!projectapicall"
            >
              Cancel
            </button>
            <button
              type="button"
              class="blue-btn ms-"
              (click)="UpdateClients()"
              *ngIf="!projectapicall"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <div class="col-lg-1" style="width: 0.33333%">
        <div class="vl"></div>
      </div>

      <div class="col-lg-5" style="padding: 0px 0px 0px 10px">
        <div
          class="modal-header"
          style="border-bottom: unset !important; padding-bottom: 0px"
        >
          <div style="display: flex">
            <h5 class="modal-title" id="modal-basic-title">
              <strong>Documents</strong>
            </h5>
          </div>
        </div>
        <form [formGroup]="CreateClientForm">
          <div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="CreatDocumentsErrorDocuments"
            >
              {{ CreatDocumentsErrorDocuments }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinkone"
            >
              {{ createClientErrorWeblinkone }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinktwo"
            >
              {{ createClientErrorWeblinktwo }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorWeblinkthree"
            >
              {{ createClientErrorWeblinkthree }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="createClientErrorLogo"
            >
              {{ createClientErrorLogo }}
            </div>
            <div
              style="padding: 5px; font-size: 14px; padding-left: 20px"
              class="text-danger"
              *ngIf="CreatDocumentsErrorDocumentsname"
            >
              {{ CreatDocumentsErrorDocumentsname }}
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-8">
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx"
                  placeholder="Document is required"
                  max-file-size="10000000"
                  formControlName="document"
                  class="form-control"
                  (change)="attachFiles2($event)"
                />
              </div>
              <div class="col-4">
                <button
                  type="button"
                  class="blue-btn ms-"
                  (click)="UploadDocuments2()"
                  style="float: right"
                  *ngIf="!projectapicall2 && showUploadFileButton"
                >
                  Upload
                </button>
              </div>
            </div>

            <div class="row" style="margin-top: 15px">
              <div class="col-lg-12">
                <div *ngIf="projectapicall">{{ fileName }}</div>
                <div class="row">
                  <div
                    id="panelsStayOpen-collapsesix"
                    class="accordion-collapse collapse show scrolldoc"
                    aria-labelledby="panelsStayOpen-headingsix"
                  >
                    <div
                      class="accordion-body"
                      *ngFor="let document of documentData"
                    >
                      <div class="experience d-flex justify-content-between">
                        <div class="experience-left col-lg-10">
                          <h6 class="modal-title" id="modal-basic-title">
                            <a
                              href="{{ document?.path }}"
                              target="_blank"
                              style="
                                color: gray;
                                text-decoration: unset !important;
                                font-size: 14px;
                              "
                            >
                              {{ document?.name }}</a
                            >
                          </h6>
                        </div>
                        <div
                          class="inner-edit col-lg-2"
                          style="text-align: right"
                        >
                          <a
                            href="javascript:void(0)"
                            (click)="deleteDocuments1(document.id)"
                          >
                            <em
                              class="bi bi-trash"
                              title="Delete"
                              style="color: black"
                            ></em
                          ></a>
                        </div>
                      </div>
                      <hr style="margin-top: 2px" />
                    </div>
                  </div>
                  <div
                    class="accordion-body"
                    *ngIf="clientData?.documents.length == 0"
                    style="margin-left: 20px"
                  >
                    No Documents added
                  </div>

                  <div style="margin-top: 10px">
                    <div class="spinner-border" *ngIf="projectapicall2">
                      <span class="visually-hidden">Uploading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="">Web link 1</label>
                <label for="" *ngIf="showexternallink1 && !editexternallink1"
                  ><span
                    (click)="
                      editexternallink1 = true; showexternallink1 = false
                    "
                    >{{ weblink1 | slice : 0 : 40
                    }}{{
                      weblink1.length > 40
                        ? "...
                      "
                        : " "
                    }}</span
                  ><a
                    href="{{ weblink1 }}"
                    target="_blank"
                    title="Open web link"
                    ><em
                      class="fa fa-external-link"
                      aria-hidden="true"
                    ></em> </a
                ></label>
                <input
                  form=""
                  *ngIf="!showexternallink1 && editexternallink1"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_one"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_one'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_one'] &&
                    client['web_link_one'].errors &&
                    client['web_link_one'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="">Web link 2</label>
                <label for="" *ngIf="showexternallink2 && !editexternallink2"
                  ><span
                    (click)="
                      editexternallink2 = true; showexternallink2 = false
                    "
                    >{{ weblink2 | slice : 0 : 40
                    }}{{
                      weblink2.length > 40
                        ? "...
                      "
                        : " "
                    }} </span
                  ><a
                    href="{{ weblink2 }}"
                    target="_blank"
                    title="Open web link"
                  >
                    <em class="fa fa-external-link" aria-hidden="true"></em> </a
                ></label>

                <input
                  form=""
                  *ngIf="!showexternallink2 && editexternallink2"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_two"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_two'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_two'] &&
                    client['web_link_two'].errors &&
                    client['web_link_two'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
              <div
                class="row"
                style="display: flex; justify-content: space-between"
              >
                <label for="" *ngIf="!showexternallink3">Web link 3</label>
                <label for="" *ngIf="showexternallink3 && !editexternallink3"
                  ><span
                    (click)="
                      editexternallink3 = true; showexternallink3 = false
                    "
                    >{{ weblink3 | slice : 0 : 40
                    }}{{
                      weblink3.length > 40
                        ? "...
                      "
                        : " "
                    }}</span
                  >
                  <a href="{{ weblink3 }}" target="_blank" title="Open web link"
                    ><em
                      class="fa fa-external-link"
                      aria-hidden="true"
                    ></em> </a
                ></label>

                <input
                  *ngIf="!showexternallink3 && editexternallink3"
                  type="text"
                  placeholder="Enter URL"
                  formControlName="web_link_three"
                  class="form-control"
                  [ngClass]="{
                    'is-invalid': submitted && client['web_link_three'].errors
                  }"
                />
                <div
                  style="color: red; font-size: 14px"
                  *ngIf="
                    client['web_link_three'] &&
                    client['web_link_three'].errors &&
                    client['web_link_three'].errors['pattern']
                  "
                >
                  Please enter a valid URL. (i.e. http://www.google.com)
                </div>
              </div>
            </div>

            <div class="row" style="margin-top: 20px">
              <div>
                <h5
                  class="modal-title"
                  id="modal-basic-title"
                  style="float: left"
                >
                  <strong>Client Logo</strong>
                </h5>
                <button
                  type="button"
                  class="blue-btn"
                  (click)="selectFile2.click()"
                  *ngIf="logoBox2"
                  style="font-size: 14px !important; float: right"
                >
                  Change
                </button>
              </div>
              <input
                type="file"
                id="file-input2"
                style="display: none"
                accept="image/*"
                #selectFile2
                max-file-size="1024"
                formControlName="logo"
                class="form-control"
                (change)="attachLogoFiles2($event)"
              />
            </div>

            <div class="row" style="margin-top: 10px">
              <div
                *ngIf="rectangleBox"
                id="panelsStayOpen-collapsesix"
                class="accordion-collapse collapse show scrolldocument"
                aria-labelledby="panelsStayOpen-headingsix"
              >
                <label
                  for="file-input3"
                  class="accordion-body clientlogobg file full-width-label"
                >
                  <span>Choose File</span>
                  <input
                    type="file"
                    id="file-input3"
                    style="display: none"
                    accept="image/*"
                    #selectFile3
                    max-file-size="1024"
                    formControlName="logo2"
                    class="form-control"
                    (change)="attachLogoFiles2($event)"
                  />
                </label>
              </div>
              <div
                class="col-lg-12 row"
                style="margin-top: 10px"
                *ngIf="logoBox2"
              >
                <div class="col-12" style="padding-right: 0px">
                  <img
                    [src]="url2"
                    *ngIf="url2 !== null"
                    height="120"
                    class="clientlogobgimg img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
