<div *ngIf="selectedBarNotification" style="height:auto; width: 100%;">
  <div *ngIf="selectedBarNotification.type !== 'popup'" class="notification-bar" [ngClass]="getNotificationClass(selectedBarNotification.bar_type)">
    <div class="notification-content">
      <div class="notification-icon-wrapper">
        <img [src]="megaphoneIcon" alt="icon" class="notification-icon">
        <sup *ngIf="barNotificationList.length > 1" class="notificationCount">
          {{ unreadCount }}
        </sup>
      </div>
      <div class="notification-text">
        <strong class="notification-title" [ngClass]="getNotificationClass(selectedBarNotification.bar_type)">
          {{ selectedBarNotification.title }}
        </strong>
        <div class="notification-description" [innerHTML]="selectedBarNotification.description" [ngClass]="getNotificationClass(selectedBarNotification.bar_type)">
        </div>
      </div>
    </div>
    <button type="button" class="border-0 bg-transparent notification-button me-4" (click)="markNotificationAsRead(selectedBarNotification)">
      <a class="ms-4" [ngClass]="getNotificationBtnClass(selectedBarNotification.bar_type)">Got it</a>
    </button>
  </div>
</div>

<ng-template #previewModal let-modal>
  <div class="modal fade show d-block" tabindex="-1">
    <div class="custom-modal-backdrop"></div>
    <div class="modal-dialog modal-dialog-centered" id="popupModalDialog">
      <div class="modal-content p-4 custom-notification popupNotificationCustom"
           style="background-color: {{popupNotification.backgroundColor}}">
        <div class="d-flex justify-content-center">
          <img src="{{megaphoneIcon}}" class="img-fluid" style="width: 80px;">
        </div>
        <h4 class="mt-3 mb-3 text-center" style="color:#43a8a0;">{{ popupNotification.title }}</h4>
        <div [innerHTML]="sanitizeHtml(popupNotification.description)"></div>
        <div class="d-flex justify-content-center mt-3 mb-2">
          <button type="button" class="border-0 bg-transparent" (click)="modal.close('Close click')">
            <a class="blue-btn ms-4">Got it</a>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
