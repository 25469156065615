import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/core/services/common/notification.service';
import { CandidateService } from 'src/app/core/services/candidates/candidate.service';
import { ClientService } from 'src/app/core/services/clients/client.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { TagserviceService } from 'src/app/core/services/tags/tagservice.service';
import { ProjectService } from 'src/app/core/services/projects/project.service';
import { NotesService } from 'src/app/core/services/notes/notes.service';
import {
  faArrowUpRightFromSquare,
  faCompress,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { NgSelectComponent } from '@ng-select/ng-select';
import { Location } from '@angular/common';
import {
  StorageKey,
  StorageService,
} from 'src/app/core/common/storage.service';
import { futureDateValidator, endDateValidator } from '../../custom-validators';
import {
  dueDateNotPast,
  birthDateNotFuture,
} from '../../../../shared/components/date.validators';

import { UserService } from 'src/app/core/services/users/user.service';

@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.css'],
})
export class CandidateComponent implements OnInit {
  tooltipContent: string = `You cannot change this as it is a candidate's login ID.`;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faCompress = faCompress;
  faShare = faShare;
  CreateCandidateForm!: FormGroup;
  CreateNoteForm!: FormGroup;
  CreateCandidateDetailsForm!: FormGroup;
  AddExperienceForm!: FormGroup;
  AddCertificateForm!: FormGroup;
  AddEducationForm!: FormGroup;
  AddDocumentForm!: FormGroup;
  UpdateNoteForm!: FormGroup;
  addAllCandidateAsContacts: any;
  submitted: boolean = false;
  closeResult: string = '';
  experienceData = [];
  tagList = [];
  files: any;
  refreshDate: any;
  deleteDocumentId: any;
  projectData: any = [];
  clientNotesData: any = [];
  projectUpdate: any;
  uploadedDocuments: any;
  showTagDropdown = false;
  selectedtagValue: any;
  experienceId!: any;
  deleteExperienceId: any;
  CandidateId!: number;
  candidateStatus: any = [];
  projectRank: any;
  projectStatus: any;
  certificateList: any = [];
  experienceList: any = [];
  educationData: any = [];
  selectedProjectName: string = '';
  createCandidateData: any;
  isSearchable: boolean = false;
  searchClicked: string = '';
  selectedProject: any;
  projectLists: any = [];
  deleteProjectId: any;
  stateData: any = [];
  languageData = [];
  // selectedlanguage: any
  selectedlanguage: any[] = [];
  candidatetagList: any;
  allowExportcheck: any;
  strengthId: any;
  documentData: any;
  deleteEducationId: any;
  deleteName: any;
  pageTitle: string = 'Candidate Detail';
  showCreateCandidate: boolean = false;
  showProjectCandidate: boolean = false;
  showCreateButton: string = '';
  candidateData: any;
  is_client: any;
  deleteCertificateId: any;
  pageNumber: number = 1;
  pageSize: number = 10;
  totalRows: number = 0;
  pager: any = {};
  fileName!: string;
  selectedProjectStatus: any;
  imageselected: boolean = false;
  imagepath: string = '';
  filePath!: string;
  notesList: any = [];
  clientNotesList: any = [];
  deleteNoteId: any;
  editNoteSearchName: any = '';
  updatesubmitted: boolean = false;
  tagError: boolean = false;
  selectedStrengths: any = {};
  projectapicall: boolean = false;
  isInputEnabled: boolean = true;
  @ViewChild('tagselect', { static: false }) tagselect?: NgSelectComponent;
  CreateCandidateDetailsError: any;
  CreateCandidateDetailsErrorFirstname: any;
  CreateCandidateDetailsErrorLastname: any;
  CreateCandidateDetailsErrorGender: any;
  CreateCandidateDetailsErrorInbirthdate: any;
  CreateCandidateDetailsErrorCurrentcompany: any;
  CreateCandidateDetailsErrorCurrentposition: any;
  CreateCandidateDetailsErrorCustomdiversity: any;
  CreateCandidateDetailsErrorCustomcompensationdetails: any;
  CreateCandidateDetailsErrorLanguageids: any;
  CreateCandidateDetailsErrorLinkedinurl: any;
  CreateContactsDetailsError: any;
  CreateContactsDetailsErrorInpersonalphone: any;
  CreateContactsDetailsErrorInpersonalemail: any;
  CreateContactsDetailsErrorInworkphone: any;
  CreateContactsDetailsErrorInworkemail: any;
  CreateContactsDetailsErrorisInpersonalphonepreffered: any;
  CreateContactsDetailsErrorisInpersonalemailpreffered: any;
  CreateContactsDetailsErrorisInworkphonepreffered: any;
  CreateContactsDetailsErrorisInworkemailpreffered: any;
  CreateContactsDetailsErrorInstate: any;
  CreateContactsDetailsErrorIncity: any;
  updateContactDetailsErrorInCandidate: any;
  CreateContactsDetailsErrorIncountryfullname: any;
  CreateEducationDetailsError: any;
  CreateEducationDetailsErrorDegreename: any;
  CreateEducationDetailsErrorSchool: any;
  CreateEducationDetailsErrorStartdate: any;
  CreateEducationDetailsErrorEnddate: any;
  UpdateEducationDetailsError: any;
  UpdateEducationDetailsErrorDegreename: any;
  UpdateEducationDetailsErrorSchool: any;
  UpdateEducationDetailsErrorStartdate: any;
  UpdateEducationDetailsErrorEnddate: any;
  CreatDocumentsError: any;
  CreatDocumentsErrorDocument: any;
  CreatDocumentsErrorDescription: any;
  UpdateDocumentsError: any;
  UpdateDocumentsErrorDocument: any;
  UpdateDocumentsErrorDescription: any;
  CreateCertificateDetailsError: any;
  CreateCertificateDetailsErrorName: any;
  CreateCertificateDetailsErrorAuthority: any;
  CreateCertificateDetailsErrorStartdate: any;
  CreateCertificateDetailsErrorEnddate: any;
  UpdateCertificateDetailsError: any;
  UpdateCertificateDetailsErrorName: any;
  UpdateCertificateDetailsErrorAuthority: any;
  UpdateCertificateDetailsErrorStartdate: any;
  UpdateCertificateDetailsErrorEnddate: any;
  CreatNotesError: any;
  CreatNotesErrorProjectid: any;
  CreatNotesErrorNote: any;
  CreatNotesErrorAllowexport: any;
  clientNoteError: any;
  UpdateNotesError: any;
  UpdateNotesErrorNote: any;
  UpdateNotesErrorAllowexport: any;
  createAttachError: any;
  createAttachErrorProjectid: any;
  files2: any;
  fileName2: any;
  filePath2: any;
  url: any;
  url2: any;
  isEmailNotes: boolean = false;
  language_ids: any[] = [];
  // language: void;
  get createCandidateForm() {
    return this.CreateCandidateForm.controls;
  }
  get createNoteForm() {
    return this.CreateNoteForm.controls;
  }
  get updateNoteForm() {
    return this.UpdateNoteForm.controls;
  }
  @ViewChild('formDirective')
  Probability: any;
  clientsData: any = [];
  ClientStatuslist: any = [];
  ClientProbabiltylist: any = [];
  AddNewBusinessForm!: FormGroup;
  clientsList = [];
  selectedClientId: any;
  createClientAttachError: any;
  is_already_linked: any;
  @ViewChild('replaceClient') replaceClient: any;
  deleteClientId: any;
  is_client_module_access_disabled: any;
  isself_created: any;
  selectedFileName : any;

  @ViewChild('CheckResume') CheckResumeModal: any;
  isResumeChecked: boolean = false;
  editModalResumeId: any = '';

  emailPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  @ViewChild('ViewClientModal', { static: false }) ViewClientModal: any;
  CreateClientForm!: FormGroup;
  ph3 = /[- +()0-9]+/;

  ClientId!: number;
  rectangleBox: boolean = true;
  logoBox: boolean = false;
  logoBox2: boolean = false;
  openLogoSection: boolean = false;
  CreatDocumentsErrorDocuments: any;
  createClientError: string = '';
  createClientErrorName: any;
  createClientErrorIndustry: any;
  createClientErrorContactnumber: any;
  createClientErrorEmail: any;
  createClientErrorAddress: any;
  createClientErrorPostal: any;
  createClientErrorCity: any;
  createClientErrorState: any;
  createClientErrorWebsite: any;
  createClientErrorDescription: any;
  createClientErrorPrimarycontact: any;
  showexternallink1: boolean = false;
  showexternallink2: boolean = false;
  showexternallink3: boolean = false;
  editexternallink1: boolean = false;
  editexternallink2: boolean = false;
  editexternallink3: boolean = false;
  weblink1: string = '';
  weblink2: string = '';
  weblink3: string = '';

  userList: any;
  clientStatusList: any = [];
  static_primary_contact: boolean = true;
  candidateList: any = [];
  stateList: any;

  projectapicall2: boolean = false;

  showUploadFileButton: boolean = false;
  CreatDocumentsErrorDocumentsname: any;
  createClientErrorLogo: any;
  createClientErrorWeblinkthree: any;
  createClientErrorWeblinkone: any;
  createClientErrorWeblinktwo: any;
  clientList: any = [];
  uploadsubmitted: boolean = false;

  activeNote: String = '';

  strengths = [
    { label: 'Select strength', value: 0 },
    { label: '1 Star', value: 1 },
    { label: '2 Stars', value: 2 },
    { label: '3 Stars', value: 3 },
    { label: '4 Stars', value: 4 },
    { label: '5 Stars', value: 5 },
  ];

  isResumeOptions = [
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
  ];

  // Get an array of stars based on the value
  getStars(value: number): string[] {
    return new Array(value).fill('★');
  }

  private formDirective!: NgForm;
  constructor(
    private notificationService: NotificationService,
    private formBuilder: FormBuilder,
    private candidateService: CandidateService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private tagService: TagserviceService,
    private projectService: ProjectService,
    private notesService: NotesService,
    private router: Router,
    private location: Location,
    private storageService: StorageService,
    private ClientService: ClientService,
    private clientService: ClientService,
    private userService: UserService
  ) {
    this.AddNewBusinessForm = this.formBuilder.group({
      clientname: [null, Validators.required],
    });

    this.is_client_module_access_disabled = this.storageService.getValue(
      'is_client_module_access_disabled'
    );

    this.CreateClientForm = this.formBuilder.group({
      ClientId: [null],
      name: [null, [Validators.required]],
      user_id: [null, [Validators.required]],
      industry: [null],
      city: [null],
      state: [null],
      contact_email: [null, [Validators.pattern(this.emailPattern)]],
      //contact_number:[null,[Validators.pattern("[0-9 ]{12}")]],
      contact_number: [null, [Validators.pattern(this.ph3)]],
      postal_code: [null],
      street_address: [null],
      description: [null],
      is_resume: [0],
      website: [null],
      primary_contact: [null],
      primary_contact_static: [null],
      client_status_id: [0],
      client_probability_status_id: [0],
      no_of_searches: [null],
      web_link_one: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_two: [null, [Validators.pattern(this.getURLPattern())]],
      web_link_three: [null, [Validators.pattern(this.getURLPattern())]],
      // web_link_one:[null,[this.validateUrl]],
      document: [null],
      logo: [null],
      candidate_id: [null],
      is_referenceable: [null],
    });
  }

  ngOnInit(): void {
    this.CandidateId = this.route.snapshot.params['id'];
    this.onLoad();
    this.getProjectList();
    this.getCandidateStatus();
    this.getClientNotes();
    this.getCandidateNotes();
    this.getclientStatus();
    this.getclientprobability();
    this.getClientsList();
    this.getClientStatuses();
    this.getUsersList();
    // this.addNewBusinessForm();
  }
  get f() {
    return this.CreateCandidateDetailsForm.controls;
  }
  get addExpDetails() {
    return this.AddExperienceForm.controls;
  }
  get certificateDetails() {
    return this.AddCertificateForm.controls;
  }
  get educationDetails() {
    return this.AddEducationForm.controls;
  }
  get documentDetails() {
    return this.AddDocumentForm.controls;
  }
  get AddNew() {
    return this.AddNewBusinessForm.controls;
  }
  get client() {
    return this.CreateClientForm.controls;
  }

  createCandidateform() {
    this.CreateCandidateDetailsForm = this.formBuilder.group({
      id: [null],
      // firstname: [null, Validators.required],
      firstname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      lastname: [
        null,
        [
          Validators.required,
          Validators.minLength(0),
          Validators.maxLength(15),
        ],
      ],
      gender: [null],
      strength: [null],
      current_company: [null],
      current_position: [null],
      // in_birth_date: [null],
      in_birth_date: [null, [birthDateNotFuture()]],
      in_state: [null],
      in_city: [null],
      in_work_phone: [null],
      // in_personal_email: [null],
      in_personal_email: [null, [Validators.pattern(this.emailPattern)]],
      in_personal_phone: [null],
      in_work_email: [null, [Validators.pattern(this.emailPattern)]],
      country: [null],
      is_in_personal_phone_preffered: [null],
      is_in_personal_email_preffered: [null],
      is_in_work_phone_preffered: [null],
      is_in_work_email_preffered: [null],
      language_ids: [null],
      // linkedin_url:[{value:"",disabled:true}],
      linkedin_url: [null],
      custom_diversity: [null],
      custom_compensation_details: [null],
      source: [null],
    });
  }
  addExperienceDetailsForm() {
    this.AddExperienceForm = this.formBuilder.group({
      company: [null, Validators.required],
      title: [null, Validators.required],
      location: [null, Validators.required],
      start_date: [null, Validators.required],
      end_date: [null],
      description: [null],
    });
  }

  // AddCertificateForms() {
  //   this.AddCertificateForm = this.formBuilder.group({
  //     name: [null, Validators.required],
  //     authority: [null, Validators.required],
  //     // start_date: [null],
  //     start_date: [null, [Validators.required, futureDateValidator()]],
  //     end_date: [null],
  //     certificateId: [null]

  //   })
  // }

  AddCertificateForms() {
    this.AddCertificateForm = this.formBuilder.group(
      {
        name: [null, Validators.required],
        authority: [null, Validators.required],
        // start_date: [null],
        start_date: [null, [Validators.required, futureDateValidator()]],
        end_date: [null],
        certificateId: [null],
      },
      {
        validator: endDateValidator('start_date', 'end_date'),
      }
    );
  }

  // addEducationform() {
  //   this.AddEducationForm = this.formBuilder.group({
  //     degree_name: [null, Validators.required],
  //     school: [null],
  //     // field_of_study:[null,Validators.required],
  //     // start_date: [null],
  //     // start_date: [null, [Validators.required, futureDateValidator()]],
  //     end_date: [null],
  //     // grade:[null],
  //     // description:[null],
  //     // activities_and_societies:[null],
  //     education_id: [null]
  //   })
  // }

  addEducationform() {
    this.AddEducationForm = this.formBuilder.group(
      {
        degree_name: [null, Validators.required],
        school: [null],
        start_date: [null, [Validators.required, futureDateValidator()]],
        end_date: [null],
        education_id: [null],
      },
      {
        validator: endDateValidator('start_date', 'end_date'),
      }
    );
  }

  addNewBusinessForm() {}

  getClientsList() {
    this.clientService.listAll().subscribe({
      next: (response) => {
        this.clientsList = response.clients.data;
        this.clientsList = this.clientsList.filter(
          (ele: any) => ele.is_active == 1
        );
      },
      error: (error) => {},
    });
  }

  onClientSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      let data = { search: searchValue };
      this.clientService.searchClientwiseList(data).subscribe({
        next: (response: any) => {
          this.clientsList = response.clients.data;
          this.clientsList = this.clientsList.filter(
            (ele: any) => ele.is_active == 1
          );
        },
        error: (err: any) => {},
      });
    }
  }

  stripHtmlTags(html: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  // Save New Client
  newbusinesssubmit() {
    this.submitted = true;
    if (this.AddNewBusinessForm.invalid) {
      return;
    }

    (this.selectedClientId =
      this.AddNewBusinessForm.controls['clientname'].value),
      (this.is_already_linked = '');
    this.clientService
      .createclient(
        this.CandidateId,
        this.selectedClientId,
        this.is_already_linked
      )
      .subscribe({
        next: (response: any) => {
          this.modalService.dismissAll();
          this.AddNewBusinessForm.reset();
          this.resetAddBusinessError();
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error) => {
          this.AddNewBusinessForm.reset();
          this.resetAddBusinessError();
          this.createClientAttachError = error.error.message;
          this.modalService.dismissAll();
          switch (error.status) {
            case 422:
              this.modalService
                .open(this.replaceClient, {
                  ariaLabelledBy: 'modal-basic-title',
                })
                .result.then(
                  (result) => {
                    this.closeResult = `Closed with: ${result}`;
                    this.is_already_linked = 1;
                    this.clientService
                      .createclient(
                        this.CandidateId,
                        this.selectedClientId,
                        this.is_already_linked
                      )
                      .subscribe({
                        next: (response: any) => {
                          this.onLoad();
                          this.modalService.dismissAll();
                          this.AddNewBusinessForm.reset();
                          this.resetAddBusinessError();
                          this.notificationService.showSuccess(
                            response.message
                          );
                          window.location.reload();
                        },
                        error: (error) => {
                          this.AddNewBusinessForm.reset();
                          this.createClientAttachError = error.error.message;
                          this.modalService.dismissAll();
                        },
                      });
                  },
                  (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(
                      reason
                    )}`;
                  }
                );
              break;
            default:
            //Nothing
          }
        },
      });
  }

  // Delete Client
  deleteClient() {
    this.clientService
      .deleteclient(this.CandidateId, this.deleteClientId)
      .subscribe({
        next: (response: any) => {
          window.location.reload();
          this.notificationService.showSuccess(response.message);
          this.getClientNotes();
          this.onLoad();
          this.modalService.dismissAll();
        },
        error: (error: any) => {},
      });
  }

  // toggleCheckbox(event: Event) {
  //   const checked = (event.target as HTMLInputElement).checked;
  //   this.AddDocumentForm.patchValue({ is_resume: checked ? 1 : 0 });
  // }

  // openUrlModal(popupForLinkedinContent: any) {
  //   this.modalService.open(popupForLinkedinContent, { ariaLabelledBy: 'modal-basic-title' }).result.then(
  //     (result) => {
  //       this.closeResult = `Closed with: ${result}`;
  //     },
  //     (reason) => {
  //       this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //     },
  //   );
  // }

  adddocumentForm() {
    this.AddDocumentForm = this.formBuilder.group({
      document: [null, Validators.required],
      //  description:[null],
      description: [null, [Validators.minLength(0), Validators.maxLength(15)]],
      documentId: [null],
      is_resume: ['0', Validators.required],
    });
    this.selectedFileName = '';
  }

  onLoad() {
    this.candidateService
      .candidateDetail(this.route.snapshot.params['id'])
      .subscribe({
        next: (response: any) => {
          this.candidateData = response.candidate;
          this.candidateData.languages_names =
            this.candidateData?.languages &&
            this.candidateData?.languages.length > 0
              ? this.candidateData?.languages.map((a: any) => a.name).join(', ')
              : '---';
          //  console.log(this.candidateData.id);
          this.is_client = response.is_client;
          this.experienceData = response.candidate.experiences;
          this.projectData = response.projects;
          this.clientsData = response.candidate.new_clients;

          this.clientsData.forEach((element: any) => {
            element.clients.client_probability_status_id =
              element.clients.client_probability_status_id || 0;
          });

          this.projectData.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name + (ele?.client ? ' [' + ele?.client + ']' : '');
          });
          this.projectData.unshift({
            id: 0,
            project_id: 0,
            dropdown: 'General',
          });
          this.projectData.forEach((project: any) => {
            this.selectedStrengths[project.id] = project.strength;
          });
          // this.CreateNoteForm.controls['notes_project'].setValue(0);
          this.documentData = response.candidate.documents;
          // console.log(this.documentData);
          this.candidatetagList = response.candidate.tags;
          this.certificateList = response.candidate.certifications;
          // this.experienceList = response.candidate.experiences;
          this.educationData = response.candidate.qualifications;
          this.CandidateId = this.candidateData.id;
          this.experienceList = response.candidate.experiences;
          this.isself_created = this.candidateData.self_created;

          this.url = this.candidateData.in_profile_pic_url;
          // this.rectangleBox = true;
          // this.openLogoSection =false;
          if (this.url == null) {
            //  this.rectangleBox = true;
            //  this.logoBox = false;
          } else if (this.url != null) {
            // this.rectangleBox = false;
            // this.logoBox = true;
          }
        },
        error: (error) => {
          this.notificationService.showError(error.message);
        },
      });
    this.onBuildForm();
  }

  showProject2(id: number, searchvalue: string = '', projectRank: string = '') {
    this.router.navigate(['/search/' + id + '/' + projectRank]);

    /* if(searchvalue == '')
      this.router.navigate(['/search/' + id]);
    else
      this.router.navigate(['/search/' + id +'/'+searchvalue]); */
  }

  showProject3(id: number) {
    this.router.navigate(['/client/' + id]);
  }

  showProject4(id: number) {
    this.router.navigate(['/search/' + id]);
  }

  onBuildForm() {
    this.CreateCandidateForm = this.formBuilder.group({
      linkedInUrl: [null, [Validators.required]],
    });

    this.CreateNoteForm = this.formBuilder.group({
      notes_project: [null, [Validators.required]],
      client_notes: [null, [Validators.required]],
      notes: [null, [Validators.required]],
      allow_export: [null],
    });

    this.UpdateNoteForm = this.formBuilder.group({
      notes_id: [null],
      notes_project: [null],
      notes: [null, [Validators.required]],
      allow_export: [null],
      client_notes: [null],
      note_type: [null],
    });
  }

  getProjectList() {
    // let data = { is_public: 0 };
    this.projectService.listAll().subscribe({
      next: (response) => {
        this.projectLists = response.projects.data;
        this.projectLists = this.projectLists.filter(
          (ele: any) => ele.project_status_id == 1
        );
        this.projectLists.forEach((ele: any) => {
          ele['dropdown'] =
            ele?.name +
            (ele?.client?.name ? ' [' + ele.client?.name + ']' : '');
        });
      },
      error: (err) => {},
    });
  }

  resetBackendAddNoteerror() {
    this.CreatNotesErrorProjectid = '';
    this.CreatNotesErrorNote = '';
    this.CreatNotesErrorAllowexport = '';
    this.CreatNotesError = '';
    this.clientNoteError = '';
    this.CreateNoteForm.reset();
  }

  // this.CreateNoteForm = this.formBuilder.group({
  //   notes_project: [null, [Validators.required]],
  //   client_notes: [null, [Validators.required]],
  //   notes: [null, [Validators.required]],
  //   allow_export: [null],
  // });
  addNote() {
    this.submitted = true;
    let data = {};
    if (this.activeNote === 'candidate') {
      data = {
        id: this.CandidateId,
        project_id: this.CreateNoteForm.controls['notes_project'].value,
        note: this.CreateNoteForm.controls['notes'].value,
        allow_export: this.CreateNoteForm.controls['allow_export'].value
          ? 1
          : 0,
        is_client_note: 0,
      };
    }

    this.projectapicall = true;
    this.notesService.create(data).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();
        // this.formDirective.resetForm();
        this.CreateNoteForm.reset();
        this.submitted = false;
        this.projectapicall = false;
        // this.onLoad();
        window.location.reload();
        this.getCandidateNotes();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatNotesError = error.error.message;
        if (error.status === 422) {
          if (error.error.project_id) {
            this.CreatNotesErrorProjectid = error.error.project_id[0];
          }
          if (error.error.note) {
            this.CreatNotesErrorNote = error.error.note[0];
          }
          if (error.error.allow_export) {
            this.CreatNotesErrorAllowexport = error.error.allow_export[0];
          }
        }
      },
    });
  }

  addClientNote() {
    this.submitted = true;
    // if (!this.createNoteForm['client_notes'].value) {
    //   this.clientNoteError = 'Client is required.';
    //   return;
    // }
    const clientId = this.createNoteForm['client_notes'].value;
    const clientData = this.clientsData.find(
      (data: any) => data.client_id === clientId
    );
    let data = {
      client_id: clientId,
      note: this.createNoteForm['notes'].value,
      note_type: clientId ? 'contact' : 'candidate',
      ...(clientId ? { contact_id: clientData?.id } : { candidate_id: this.candidateData?.id })
    };

    this.projectapicall = true;
    this.notesService.createContactNote(data).subscribe({
      next: (response) => {
        window.location.reload();
        this.projectapicall = false;
        this.notificationService.showSuccess(response.message);
        this.modalService.dismissAll();
        this.CreateNoteForm.reset();
        this.submitted = false;
        this.getClientNotes();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatNotesError = error.error.message;
      },
    });
  }

  populateAddNotes(notes_project: any, notes: any, allow_export: any) {
    this.CreateNoteForm.controls['notes_project'].setValue(notes_project);
    this.CreateNoteForm.controls['notes'].setValue(notes);
    this.CreateNoteForm.controls['allow_export'].setValue(allow_export ? 1 : 0);
    this.CreateNoteForm.controls['notes_project'].setValue(0);
  }

  getStates() {
    this.candidateService.getstate().subscribe({
      next: (response: any) => {
        this.stateData = response.states;
      },
      error: (error: any) => {},
    });
  }
  getlanguage() {
    this.candidateService.getlanguageData().subscribe({
      next: (response: any) => {
        this.languageData = response.languages;
      },
      error: (error) => {},
    });
  }

  attachFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.selectedFileName = this.files.name;
    this.filePath = event.target.value;
  }

  restrictAlphabet(evt: any) {
    const keys = evt.keyCode;
    if (keys < 48 || keys > 58) {
      return false;
    } else {
      return true;
    }
  }

  resetBackendUploadDocumenterror() {
    this.CreatDocumentsErrorDocument = '';
    this.CreatDocumentsErrorDescription = '';
    this.CreatDocumentsError = '';
    //  this.AddEducationForm.reset();
  }

  UploadDocuments() {
    this.submitted = true;
    if (!this.files) {
      return;
    }

    if (this.AddDocumentForm.invalid) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);
    if(this.AddDocumentForm.controls['description'].value){
      formData.append(
        'description',
        this.AddDocumentForm.controls['description'].value
      );
    }
    const isResumeValue = this.AddDocumentForm.controls['is_resume'].value ? 1 : 0;
    formData.append('is_resume', isResumeValue.toString());
    // formData.append(
    //   'is_resume',
    //   this.AddDocumentForm.controls['is_resume'].value
    // );

    this.projectapicall = true;
    this.candidateService.createDocument(this.CandidateId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall = false;
        this.submitted = false;
        this.onLoad();
      },
      error: (error) => {
        this.projectapicall = false;
        this.CreatDocumentsError = error.error.message;
        if (error.status === 422) {
          if (error.error.document) {
            this.CreatDocumentsErrorDocument = error.error.document[0];
          }
          if (error.error.description) {
            this.CreatDocumentsErrorDescription = error.error.description[0];
          }
        }
      },
    });
  }

  onCheckboxChange(event: any) {
    if (event.target.checked) {
      let document = this.documentData.filter(
        (ele: any) => ele.is_resume == 1 && ele.id !== this.editModalResumeId
      );
      if(document && document.length > 0){
        this.modalService.open(this.CheckResumeModal).result.then(
          () => {
            this.isResumeChecked = true;
          },
          () => {
            this.isResumeChecked = false;
          }
        );
      } else {
        this.isResumeChecked = false;
      }
    }
  }

  confirmPreferredResume(modal: any) {
    modal.close();
    this.isResumeChecked = true;
  }

  cancelPreferredResume(modal: any) {
    modal.dismiss();
    this.isResumeChecked = false;
  }

  EditDocument(EditId: any) {
    let document = this.documentData.filter((ele: any) => ele.id == EditId);
    this.editModalResumeId = EditId;
    if (document[0] && document[0].name) {
        this.selectedFileName = document[0].name;
    }
    if (document[0].description === 'string') {
      document[0].description = JSON.parse(document[0].description);
    }
    this.AddDocumentForm.controls['description'].setValue(
      document[0].description
    );
    this.AddDocumentForm.controls['is_resume'].setValue(
      document[0].is_resume === 1 ? true : false
    );
    if(document[0].is_resume === 1){
      this.isResumeChecked = true;
    } else {
      this.isResumeChecked = false;
    }
    this.AddDocumentForm.controls['documentId'].setValue(document[0].id);
    if (this.selectedFileName) {
      this.AddDocumentForm.get('document')?.clearValidators();
      this.AddDocumentForm.get('document')?.updateValueAndValidity();
    } else {
      this.AddDocumentForm.get('document')?.setValidators(Validators.required);
      this.AddDocumentForm.get('document')?.updateValueAndValidity();
    }
  }
  UpdateDocuments() {
    this.submitted = true;
//     if (!this.files) {
//       return;
//     }

    if (this.AddDocumentForm.invalid) {
      return;
    }

    let documentId = this.AddDocumentForm.controls['documentId'].value;

    const formData: FormData = new FormData();
    if (this.fileName) {
      formData.append('name', this.fileName);
    }
    if (this.files) {
      formData.append('document', this.files);
    }
    if(this.AddDocumentForm.controls['description'].value) {
      formData.append(
        'description',
        this.AddDocumentForm.controls['description'].value
      );
    } else {
      formData.append(
        'description',
        ''
      );
    }
    const isResumeValue = this.AddDocumentForm.controls['is_resume'].value ? 1 : 0;
    formData.append('is_resume', isResumeValue.toString());
    // formData.append(
    //   'is_resume',
    //   this.AddDocumentForm.controls['is_resume'].value
    // );
    this.projectapicall = true;
    this.candidateService
      .updateDocument(this.CandidateId, documentId, formData)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.onLoad();
          this.modalService.dismissAll();
          //window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateDocumentsError = error.error.message;
          if (error.status === 422) {
            if (error.error.document) {
              this.UpdateDocumentsErrorDocument = error.error.document[0];
            }
            if (error.error.description) {
              this.UpdateDocumentsErrorDescription = error.error.description[0];
            }
          }
        },
      });
  }

  deleteDocuments() {
    this.candidateService
      .deleteDocument(this.CandidateId, this.deleteDocumentId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.onLoad();
          this.modalService.dismissAll();
          //window.location.reload();
        },
        error: (err: any) => {},
      });
  }
  removeFile() {}
  clearTags(deleteId: any) {
    this.candidateService.dettachtag(this.CandidateId, deleteId).subscribe({
      next: (response) => {
        this.onLoad();
      },
      error: (err) => {},
    });
  }

  OpenCandidateDetails() {
    this.CreateCandidateDetailsForm.controls['id'].setValue(
      this.candidateData.id
    );
    this.CreateCandidateDetailsForm.controls['firstname'].setValue(
      this.candidateData.in_first_name
    );
    this.CreateCandidateDetailsForm.controls['lastname'].setValue(
      this.candidateData.in_last_name
    );
    // this.CreateCandidateDetailsForm.controls['gender'].setValue(this.candidateData.in_gender);
    // console.log(this.candidateData.in_gender)

    if (
      this.candidateData.in_gender !== null &&
      this.candidateData.in_gender !== undefined
    ) {
      const genderValue =
        this.candidateData.in_gender.toLowerCase() === 'male'
          ? 'Male'
          : this.candidateData.in_gender.toLowerCase() === 'female'
          ? 'Female'
          : 'Other';
      this.CreateCandidateDetailsForm.controls['gender'].setValue(genderValue);
    }
    this.CreateCandidateDetailsForm.controls['in_birth_date'].setValue(
      this.candidateData.in_birth_date
    );
    this.CreateCandidateDetailsForm.controls['current_company'].setValue(
      this.candidateData.current_company
    );
    this.CreateCandidateDetailsForm.controls['strength'].setValue(
      this.candidateData.strength
    );
    this.CreateCandidateDetailsForm.controls['current_position'].setValue(
      this.candidateData.current_position
    );
    this.CreateCandidateDetailsForm.controls['linkedin_url'].setValue(
      this.candidateData.linkedin_url
    );
    // this.CreateCandidateDetailsForm.controls['language_ids'].setValue(this.candidateData.languages);
    this.CreateCandidateDetailsForm.controls['language_ids'].setValue(
      this.candidateData.languages.map((a: any) => a.id)
    );
    // console.log(this.CreateCandidateDetailsForm.controls['language_ids'].value);
    // this.language_ids = this.candidateData.languages.map((a: any) => a.id);
    // console.log(this.language_ids);
    // this.selectedlanguage = this.candidateData.languages
    // console.log(this.selectedlanguage);
    // const languageIds = this.selectedlanguage.map((language: any) => language.id);
    // console.log("languageIds : ", languageIds);
    // this.CreateCandidateDetailsForm.controls['language_ids'].setValue(languageIds);

    this.CreateCandidateDetailsForm.controls['custom_diversity'].setValue(
      this.candidateData.custom_diversity
    );
    this.CreateCandidateDetailsForm.controls[
      'custom_compensation_details'
    ].setValue(this.candidateData.custom_compensation_details);
    this.CreateCandidateDetailsForm.controls['source'].setValue(
      this.candidateData.source
    );
  }

  comparelanguage(lang1: number, lang2: number): boolean {
    return lang1 === lang2;
  }

  UpdateCandidatesDetails() {
    this.submitted = true;
    if (this.CreateCandidateDetailsForm.invalid) {
      return;
    }

    if (this.CreateCandidateDetailsForm.controls['gender'].value === 'null') {
      this.CreateCandidateDetailsForm.controls['gender'].setValue('');
    }

    // let langids = [];
    // if (this.CreateCandidateDetailsForm.controls['language_ids'].value) {
    //   // langids = this.CreateCandidateDetailsForm.controls['language_ids'].value.map((a: any) => a.id);
    //   langids = this.CreateCandidateDetailsForm.controls['language_ids'].value
    // }

    // // let currentUserId = langids.slice();
    // console.log(langids);

    // langids.push(currentUserId);

    let updatedCandidate = {
      id: this.CreateCandidateDetailsForm.controls['id'].value,
      in_first_name:
        this.CreateCandidateDetailsForm.controls['firstname'].value,
      in_last_name: this.CreateCandidateDetailsForm.controls['lastname'].value,
      in_gender: this.CreateCandidateDetailsForm.controls['gender'].value,
      in_birth_date:
        this.CreateCandidateDetailsForm.controls['in_birth_date'].value,
      current_company:
        this.CreateCandidateDetailsForm.controls['current_company'].value,
      strength: this.CreateCandidateDetailsForm.controls['strength'].value,
      current_position:
        this.CreateCandidateDetailsForm.controls['current_position'].value,
      linkedin_url:
        this.CreateCandidateDetailsForm.controls['linkedin_url'].value,
      language_ids:
        this.CreateCandidateDetailsForm.controls['language_ids'].value,
      // language_ids: langids,
      // language_ids: this.CreateCandidateDetailsForm.controls['language_ids'].value.map((a: any) => a.id),
      custom_diversity:
        this.CreateCandidateDetailsForm.controls['custom_diversity'].value,
      custom_compensation_details:
        this.CreateCandidateDetailsForm.controls['custom_compensation_details']
          .value,
      source: this.CreateCandidateDetailsForm.controls['source'].value,
    };

    this.projectapicall = true;
    this.candidateService
      .updateCandidateData(updatedCandidate.id, updatedCandidate)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.CreateCandidateDetailsForm.reset();
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          this.onLoad();
        },
        error: (error) => {
          this.projectapicall = false;
          this.CreateCandidateDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.in_first_name) {
              this.CreateCandidateDetailsErrorFirstname =
                error.error.in_first_name[0];
            }
            if (error.error.in_last_name) {
              this.CreateCandidateDetailsErrorLastname =
                error.error.in_last_name[0];
            }
            // if (error.error.in_gender) {
            //   this.CreateCandidateDetailsErrorGender = error.error.in_gender[0];
            // }
            if (error.error.in_birth_date) {
              this.CreateCandidateDetailsErrorInbirthdate =
                error.error.in_birth_date[0];
            }
            if (error.error.current_company) {
              this.CreateCandidateDetailsErrorCurrentcompany =
                error.error.current_company[0];
            }
            if (error.error.current_position) {
              this.CreateCandidateDetailsErrorCurrentposition =
                error.error.current_position[0];
            }
            if (error.error.custom_diversity) {
              this.CreateCandidateDetailsErrorCustomdiversity =
                error.error.custom_diversity[0];
            }
            if (error.error.custom_compensation_details) {
              this.CreateCandidateDetailsErrorCustomcompensationdetails =
                error.error.custom_compensation_details[0];
            }
            if (error.error.language_ids) {
              this.CreateCandidateDetailsErrorLanguageids =
                error.error.language_ids[0];
            }
            if (error.error.linkedin_url) {
              this.CreateCandidateDetailsErrorLinkedinurl =
                error.error.linkedin_url[0];
            }
          }
        },
      });
  }

  projectchange(projectId: any, changetype: any, selectedStatus?: any) {
    // let data={
    //   project_id:projectId,
    //   // strength:0,
    //   rank:0,
    //   // candidate_status_id:0,
    //   // allow_export:0
    // };
    // console.log(selectedStatus)
    switch (changetype) {
      case 'projectRank':
        let inputValue = (<HTMLInputElement>(
          document.getElementById('projectRank_' + projectId)
        )).value;
        this.projectRank = Number(inputValue);
        this.projectUpdate = {
          project_id: projectId,
          rank: this.projectRank,
        };

        break;
      case 'projectStatus':
        this.projectUpdate = {
          project_id: projectId,
          candidate_status_id: selectedStatus == 'null' ? null : selectedStatus,
        };

        break;
      case 'project_Strength':
        this.projectUpdate = {
          project_id: projectId,
          strength: selectedStatus == 'null' ? null : selectedStatus,
        };

        break;
      case 'allow_export':
        this.projectUpdate = {
          project_id: projectId,
          allow_export: this.allowExportcheck,
        };

        break;
    }
    // this.projectRank
    this.candidateService
      .updateProjectStatus(this.CandidateId, this.projectUpdate)
      .subscribe({
        next: (response: any) => {
          this.onLoad();
        },
        error: (err: any) => {},
      });
  }

  ClientStatusChange(name: any, changetype: any, client_status_id?: number) {
    const clientToUpdate = this.clientsData.find(
      (client: any) => client.clients.name === name
    );
    if (clientToUpdate) {
      clientToUpdate.client_status_id = client_status_id;
      this.ClientService.updateClientData(
        clientToUpdate.clients.id,
        clientToUpdate.clients
      ).subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
        },
        error: (error) => {
          console.error('Error updating client data:', error);
          this.notificationService.showError('Failed to update client data.');
        },
      });
    } else {
      console.warn('Client not found in clientsData array');
    }
  }

  Probabilitychange(name: any, changetype: any, probabilityid?: any) {
    const clientToUpdate = this.clientsData.find(
      (client: any) => client?.clients.name === name
    );
    if (clientToUpdate) {
      clientToUpdate.client_probability_status_id = probabilityid;
      this.ClientService.updateClientData(
        clientToUpdate.clients.id,
        clientToUpdate.clients
      ).subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
        },
        error: (error) => {
          console.error('Error updating client data:', error);
          this.notificationService.showError('Failed to update client data.');
        },
      });
    } else {
      console.warn('Client not found in clientsData array');
    }
  }

  showimage(data: string, strengthId: any, projectId: any, changetype: any) {
    this.imagepath = data;
    this.imageselected = true;
    this.strengthId = strengthId;
    this.projectchange(projectId, changetype);
  }
  allowExportchecked(event: any, projectId: any, changetype: any) {
    event.target.checked
      ? (this.allowExportcheck = 1)
      : (this.allowExportcheck = 0);
    this.projectchange(projectId, changetype);
  }
  DeleteCandidates() {
    this.candidateService.deleteCandidateData(this.CandidateId).subscribe({
      next: (response) => {
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.router.navigate(['/candidate']);
      },
      error: (err) => {},
    });
  }

  OpenContactDetails() {
    this.getStates();
    this.CreateCandidateDetailsForm.controls['in_personal_phone'].setValue(
      this.candidateData.in_personal_phone
    );
    this.CreateCandidateDetailsForm.controls['in_personal_email'].setValue(
      this.candidateData.in_personal_email
    );
    this.CreateCandidateDetailsForm.controls['in_work_phone'].setValue(
      this.candidateData.in_work_phone
    );
    this.CreateCandidateDetailsForm.controls['in_work_email'].setValue(
      this.candidateData.in_work_email
    );
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_phone_preffered'
    ].setValue(this.candidateData.is_in_personal_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_personal_email_preffered'
    ].setValue(this.candidateData.is_in_personal_email_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_phone_preffered'
    ].setValue(this.candidateData.is_in_work_phone_preffered);
    this.CreateCandidateDetailsForm.controls[
      'is_in_work_email_preffered'
    ].setValue(this.candidateData.is_in_work_email_preffered);
    this.CreateCandidateDetailsForm.controls['in_state'].setValue(
      this.candidateData.in_state
    );
    this.CreateCandidateDetailsForm.controls['in_city'].setValue(
      this.candidateData.in_city
    );
    this.CreateCandidateDetailsForm.controls['country'].setValue(
      this.candidateData.in_country_full_name
    );

    if (this.candidateData.is_personal_email_read_only == 1) {
      this.CreateCandidateDetailsForm.controls['in_personal_email'].disable();
    } else {
      this.CreateCandidateDetailsForm.controls['in_personal_email'].enable();
    }
  }

  UpdateContactsDetails() {
    this.submitted = true;
    let updatedContactDetails = {
      id: this.CandidateId,
      in_personal_phone:
        this.CreateCandidateDetailsForm.controls['in_personal_phone'].value,
      in_personal_email:
        this.CreateCandidateDetailsForm.controls['in_personal_email'].value,
      in_work_phone:
        this.CreateCandidateDetailsForm.controls['in_work_phone'].value,
      in_work_email:
        this.CreateCandidateDetailsForm.controls['in_work_email'].value,
      is_in_personal_phone_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_personal_phone_preffered'
      ].value
        ? 1
        : 0,
      is_in_personal_email_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_personal_email_preffered'
      ].value
        ? 1
        : 0,
      is_in_work_phone_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_work_phone_preffered'
      ].value
        ? 1
        : 0,
      is_in_work_email_preffered: this.CreateCandidateDetailsForm.controls[
        'is_in_work_email_preffered'
      ].value
        ? 1
        : 0,
      in_state: this.CreateCandidateDetailsForm.controls['in_state'].value,
      in_city: this.CreateCandidateDetailsForm.controls['in_city'].value,
      in_country_full_name:
        this.CreateCandidateDetailsForm.controls['country'].value,
    };
    this.projectapicall = true;
    this.candidateService
      .updateCandidateData(updatedContactDetails.id, updatedContactDetails)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.CreateCandidateDetailsForm.reset();
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          this.onLoad();
        },
        error: (error) => {
          this.projectapicall = false;
          this.CreateContactsDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.in_personal_phone) {
              this.CreateContactsDetailsErrorInpersonalphone =
                error.error.in_personal_phone[0];
            }
            if (error.error.in_personal_email) {
              this.CreateContactsDetailsErrorInpersonalemail =
                error.error.in_personal_email[0];
            }
            if (error.error.in_work_phone) {
              this.CreateContactsDetailsErrorInworkphone =
                error.error.in_work_phone[0];
            }
            if (error.error.in_work_email) {
              this.CreateContactsDetailsErrorInworkemail =
                error.error.in_work_email[0];
            }
            if (error.error.is_in_personal_phone_preffered) {
              this.CreateContactsDetailsErrorisInpersonalphonepreffered =
                error.error.is_in_personal_phone_preffered[0];
            }
            if (error.error.is_in_personal_email_preffered) {
              this.CreateContactsDetailsErrorisInpersonalemailpreffered =
                error.error.is_in_personal_email_preffered[0];
            }
            if (error.error.is_in_work_phone_preffered) {
              this.CreateContactsDetailsErrorisInworkphonepreffered =
                error.error.is_in_work_phone_preffered[0];
            }
            if (error.error.is_in_work_email_preffered) {
              this.CreateContactsDetailsErrorisInworkemailpreffered =
                error.error.is_in_work_email_preffered[0];
            }
            if (error.error.in_state) {
              this.CreateContactsDetailsErrorInstate = error.error.in_state[0];
            }
            if (error.error.in_city) {
              this.CreateContactsDetailsErrorIncity = error.error.in_city[0];
            }
            if (error.error.in_country_full_name) {
              this.CreateContactsDetailsErrorIncountryfullname =
                error.error.in_country_full_name[0];
            }
          }
        },
      });
  }

  AddExperienceDetails() {
    this.submitted = true;
    // if(this.AddExperienceForm.invalid){
    //   return;
    // }

    let ExperienceDetails = {
      id: this.CandidateId,
      company: this.AddExperienceForm.controls['company'].value,
      title: this.AddExperienceForm.controls['title'].value,
      location: this.AddExperienceForm.controls['location'].value,
      start_date: this.AddExperienceForm.controls['start_date'].value,
      end_date: this.AddExperienceForm.controls['end_date'].value,
      description: this.AddExperienceForm.controls['description'].value,
    };
    this.candidateService
      .createExperience(ExperienceDetails.id, ExperienceDetails)
      .subscribe({
        next: (response) => {
          //  this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error) => {},
      });
  }
  openExperienceDetail(EditId: any) {
    let ExperienceDetails: any = this.experienceData.filter((data: any) => {
      return data.id == EditId;
    });
    this.submitted = true;
    this.experienceId = ExperienceDetails[0].id;
    this.AddExperienceForm.controls['company'].setValue(
      ExperienceDetails[0].company
    );
    this.AddExperienceForm.controls['title'].setValue(
      ExperienceDetails[0].title
    );
    this.AddExperienceForm.controls['location'].setValue(
      ExperienceDetails[0].location
    );
    this.AddExperienceForm.controls['start_date'].setValue(
      ExperienceDetails[0].start_date
    );
    this.AddExperienceForm.controls['end_date'].setValue(
      ExperienceDetails[0].end_date
    );
    this.AddExperienceForm.controls['description'].setValue(
      ExperienceDetails[0].description
    );
  }

  UpdateExperienceDetail() {
    this.submitted = true;
    // if(this.AddExperienceForm.invalid){
    //   return;
    // }
    let UpdateExperienceDetails = {
      candidateid: this.CandidateId,
      experienceId: this.experienceId,
      company: this.AddExperienceForm.controls['company'].value,
      title: this.AddExperienceForm.controls['title'].value,
      location: this.AddExperienceForm.controls['location'].value,
      start_date: this.AddExperienceForm.controls['start_date'].value,
      end_date: this.AddExperienceForm.controls['end_date'].value,
      description: this.AddExperienceForm.controls['description'].value,
    };

    this.candidateService
      .updateExperienceData(
        UpdateExperienceDetails.candidateid,
        UpdateExperienceDetails.experienceId,
        UpdateExperienceDetails
      )
      .subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error) => {},
      });
  }
  resetBackendAddCertificateDetailserror() {
    this.CreateCertificateDetailsErrorName = '';
    this.CreateCertificateDetailsErrorAuthority = '';
    this.CreateCertificateDetailsErrorStartdate = '';
    this.CreateCertificateDetailsErrorEnddate = '';
    this.CreateCertificateDetailsError = '';
    this.AddCertificateForm.reset();
  }

  AddCertificateDetails() {
    this.submitted = true;
    if (this.AddCertificateForm.invalid) {
      return;
    }

    let CertificateDetails = {
      id: this.CandidateId,
      name: this.AddCertificateForm.controls['name'].value,
      authority: this.AddCertificateForm.controls['authority'].value,
      start_date: this.AddCertificateForm.controls['start_date'].value,
      end_date: this.AddCertificateForm.controls['end_date'].value,
    };
    this.projectapicall = true;
    this.candidateService
      .createCertificate(CertificateDetails.id, CertificateDetails)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
          this.modalService.dismissAll();
          this.projectapicall = false;
          this.submitted = false;
          this.onLoad();
        },
        error: (error) => {
          this.projectapicall = false;
          this.CreateCertificateDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.name) {
              this.CreateCertificateDetailsErrorName = error.error.name[0];
            }
            if (error.error.authority) {
              this.CreateCertificateDetailsErrorAuthority =
                error.error.authority[0];
            }
            if (error.error.start_date) {
              this.CreateCertificateDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.CreateCertificateDetailsErrorEnddate =
                error.error.end_date[0];
            }
          }
        },
      });
  }
  EditCertificate(EditId: any) {
    this.submitted = true;
    let certificateDetails = this.certificateList.find(
      (c: any) => c.id === EditId
    );
    this.AddCertificateForm.controls['certificateId'].setValue(
      certificateDetails.id
    );
    this.AddCertificateForm.controls['name'].setValue(certificateDetails.name);
    this.AddCertificateForm.controls['authority'].setValue(
      certificateDetails.authority
    );
    this.AddCertificateForm.controls['start_date'].setValue(
      certificateDetails.start_date
    );
    this.AddCertificateForm.controls['end_date'].setValue(
      certificateDetails.end_date
    );
  }
  updateCertificateDetails() {
    // if(this.AddCertificateForm.invalid){
    //   return;
    // }

    this.submitted = true;
    if (this.AddCertificateForm.invalid) {
      return;
    }
    let CertificateDetails = {
      id: this.CandidateId,
      certificateId: this.AddCertificateForm.controls['certificateId'].value,
      name: this.AddCertificateForm.controls['name'].value,
      authority: this.AddCertificateForm.controls['authority'].value,
      start_date: this.AddCertificateForm.controls['start_date'].value,
      end_date: this.AddCertificateForm.controls['end_date'].value,
    };
    this.projectapicall = true;
    this.candidateService
      .updateCertificate(
        CertificateDetails.id,
        CertificateDetails.certificateId,
        CertificateDetails
      )
      .subscribe({
        next: (response: any) => {
          // this.notificationService.showSuccess(response.message);
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          window.location.reload();
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateCertificateDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.name) {
              this.UpdateCertificateDetailsErrorName = error.error.name[0];
            }
            if (error.error.authority) {
              this.UpdateCertificateDetailsErrorAuthority =
                error.error.authority[0];
            }
            if (error.error.start_date) {
              this.UpdateCertificateDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.UpdateCertificateDetailsErrorEnddate =
                error.error.end_date[0];
            }
          }
        },
      });
  }

  resetBackendAddEducationDetailserror() {
    this.CreateEducationDetailsErrorDegreename = '';
    this.CreateEducationDetailsErrorSchool = '';
    this.CreateEducationDetailsErrorStartdate = '';
    this.CreateEducationDetailsErrorEnddate = '';
    this.CreateEducationDetailsError = '';
    this.AddEducationForm.reset();
  }

  AddEducationDetails() {
    this.submitted = true;
    if (this.AddEducationForm.invalid) {
      return;
    }
    let AddEducationDetails = {
      degree_name: this.AddEducationForm.controls['degree_name'].value,
      school: this.AddEducationForm.controls['school'].value,
      // field_of_study:this.AddEducationForm.controls['field_of_study'].value,
      start_date: this.AddEducationForm.controls['start_date'].value,
      end_date: this.AddEducationForm.controls['end_date'].value,
      // grade:this.AddEducationForm.controls['grade'].value,
      // description:this.AddEducationForm.controls['description'].value,
      // activities_and_societies:this.AddEducationForm.controls['activities_and_societies'].value,
    };
    this.projectapicall = true;
    this.candidateService
      .createEducation(this.CandidateId, AddEducationDetails)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          // window.location.reload();
          this.modalService.dismissAll();
          this.projectapicall = false;
          this.AddEducationForm.reset();
          this.submitted = false;
          this.onLoad();
        },
        error: (error) => {
          this.projectapicall = false;
          this.CreateEducationDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.degree_name) {
              this.CreateEducationDetailsErrorDegreename =
                error.error.degree_name[0];
            }
            if (error.error.school) {
              this.CreateEducationDetailsErrorSchool = error.error.school[0];
            }
            if (error.error.start_date) {
              this.CreateEducationDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.CreateEducationDetailsErrorEnddate = error.error.end_date[0];
            }
          }
        },
      });
  }

  editNoteDetail(EditId: any) {
    if (this.activeNote === 'client') {
      var NoteDetail = this.clientNotesList.find((c: any) => c.id === EditId);
    } else {
      var NoteDetail = this.notesList.find((c: any) => c.id === EditId);
    }
    if (NoteDetail?.ms_mail_id) this.isEmailNotes = true;
    else this.isEmailNotes = false;
    this.UpdateNoteForm.controls['notes'].setValue(NoteDetail.note);
    this.UpdateNoteForm.controls['allow_export'].setValue(
      NoteDetail.allow_export
    );
    this.UpdateNoteForm.controls['notes_id'].setValue(EditId);
    this.UpdateNoteForm.controls['client_notes'].setValue(NoteDetail?.client?.id);
  }

  EditEducationDetails(EditId: any) {
    let EducationDetail = this.educationData.find((c: any) => c.id === EditId);
    this.AddEducationForm.controls['education_id'].setValue(EducationDetail.id);
    this.AddEducationForm.controls['degree_name'].setValue(
      EducationDetail.degree_name
    );
    this.AddEducationForm.controls['school'].setValue(EducationDetail.school);
    // this.AddEducationForm.controls['field_of_study'].setValue(EducationDetail.field_of_study)
    this.AddEducationForm.controls['start_date'].setValue(
      EducationDetail.start_date
    );
    this.AddEducationForm.controls['end_date'].setValue(
      EducationDetail.end_date
    );
    // this.AddEducationForm.controls['grade'].setValue(EducationDetail.grade)
    // this.AddEducationForm.controls['description'].setValue(EducationDetail.description)
    // this.AddEducationForm.controls['activities_and_societies'].setValue(EducationDetail.activities_and_societies)
  }
  UpdateEducationDetails() {
    this.submitted = true;
    if (this.AddEducationForm.invalid) {
      return;
    }
    let UpdateEducationDetails = {
      education_id: this.AddEducationForm.controls['education_id'].value,
      degree_name: this.AddEducationForm.controls['degree_name'].value,
      school: this.AddEducationForm.controls['school'].value,
      // field_of_study:this.AddEducationForm.controls['field_of_study'].value,
      start_date: this.AddEducationForm.controls['start_date'].value,
      end_date: this.AddEducationForm.controls['end_date'].value,
      // grade:this.AddEducationForm.controls['grade'].value,
      // description:this.AddEducationForm.controls['description'].value,
      // activities_and_societies:this.AddEducationForm.controls['activities_and_societies'].value,
    };
    this.projectapicall = true;
    this.candidateService
      .updateEducation(
        this.CandidateId,
        UpdateEducationDetails.education_id,
        UpdateEducationDetails
      )
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
          this.submitted = false;
          window.location.reload();
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateEducationDetailsError = error.error.message;
          if (error.status === 422) {
            if (error.error.degree_name) {
              this.UpdateEducationDetailsErrorDegreename =
                error.error.degree_name[0];
            }
            if (error.error.school) {
              this.UpdateEducationDetailsErrorSchool = error.error.school[0];
            }
            if (error.error.start_date) {
              this.UpdateEducationDetailsErrorStartdate =
                error.error.start_date[0];
            }
            if (error.error.end_date) {
              this.UpdateEducationDetailsErrorEnddate = error.error.end_date[0];
            }
          }
        },
      });
  }
  deleteEducationDetail() {
    this.candidateService
      .deleteEducation(this.CandidateId, this.deleteEducationId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error) => {},
      });
  }

  deleteCertificateDetail() {
    this.candidateService
      .deleteCertificate(this.CandidateId, this.deleteCertificateId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error: any) => {},
      });
  }
  deleteProjects() {
    this.candidateService
      .detachProject(this.CandidateId, this.deleteProjectId)
      .subscribe({
        next: (response: any) => {
          this.notificationService.showSuccess(response.message);
          this.onLoad();
          this.modalService.dismissAll();
          // window.location.reload();
        },
        error: (error: any) => {},
      });
  }

  open(
    content: any,
    typeOfModal: string,
    EditId?: number,
    deleteName?: string,
    size = 'md'
  ) {
    if (typeOfModal == 'UpdateCandidateDetails') {
      this.createCandidateform();
      this.getlanguage();
      this.OpenCandidateDetails();
    }
    if (typeOfModal == 'UpdateContactDetails') {
      this.createCandidateform();
      this.OpenContactDetails();
    }
    if (typeOfModal == 'UpdateExperienceDetails') {
      this.addExperienceDetailsForm();
      this.openExperienceDetail(EditId);
    }
    if (typeOfModal == 'AddExperience') {
      this.addExperienceDetailsForm();
    }
    if (typeOfModal == 'AddCertificate') {
      this.AddCertificateForms();
    }
    if (typeOfModal == 'EditCertificate') {
      this.AddCertificateForms();
      this.EditCertificate(EditId);
    }
    if (typeOfModal == 'deleteCerticate') {
      this.deleteName = deleteName;
      this.deleteCertificateId = EditId;
    }
    if (typeOfModal == 'deleteExperience') {
      this.deleteName = deleteName;
      this.deleteExperienceId = EditId;
    }

    if (typeOfModal == 'deleteProject') {
      this.deleteName = deleteName;
      this.deleteProjectId = EditId;
    }

    // Delete Client
    if (typeOfModal == 'deleteclientModal') {
      this.deleteName = deleteName;
      this.deleteClientId = EditId;
    }

    if (typeOfModal == 'AddEducation') {
      this.addEducationform();
    }
    if (typeOfModal == 'AddNewBusiness') {
      // this.addEducationform();
    }
    if (typeOfModal == 'UpdateEducation') {
      this.addEducationform();
      this.EditEducationDetails(EditId);
    }
    if (typeOfModal == 'deleteEducation') {
      this.deleteName = deleteName;
      this.deleteEducationId = EditId;
    }
    if (typeOfModal == 'UploadDocument') {
      this.submitted = false;
      this.isResumeChecked = false;
      this.adddocumentForm();
    }
    if (typeOfModal == 'EditDocument') {
      this.submitted = false;
      this.adddocumentForm();
      this.EditDocument(EditId);
    }
    if (typeOfModal == 'deleteDocument') {
      this.deleteName = deleteName;
      this.deleteDocumentId = EditId;
    }
    if (typeOfModal == 'editNote') {
      this.editNoteSearchName = deleteName;
      this.editNoteDetail(EditId);
    }
    if (typeOfModal == 'deleteNote') {
      this.deleteNoteId = EditId;
    }
    if (typeOfModal == 'DeleteCandidate') {
      this.deleteName = deleteName;
    }
    if (typeOfModal == 'Addtag') {
      this.selectedtagValue = null;
      this.getTags();
    }
    if (typeOfModal == 'createNote') {
      this.CreateNoteForm.controls['notes_project'].setValue(0);
    }
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: size,
        centered: typeOfModal === 'createClient' ? true : false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        () => {
          //${this.getDismissReason(reason)}
          this.closeResult = `Dismissed `;
        }
      );
  }

  createCurrentCompanyAsClient() {
    const addAllCheckBox = document.getElementById(
      'addAllCandidates'
    ) as HTMLInputElement;
    const data = {
      candidate_id: this.candidateData?.id,
      name: this.candidateData?.current_company,
      add_contacts: addAllCheckBox?.checked ? 1 : 0,
    };
    this.clientService.createCurrentCompanyAsClient(data).subscribe({
      next: (response) => {
        this.notificationService.showSuccess(
          'The client has been created successfully, and their contacts have been added successfully.'
        );
        this.modalService.dismissAll();
        this.onLoad();
      },
    });
  }

  getTags() {
    // this.tagselect?.clearModel();
    // this.showTagDropdown=true;
    this.tagService.listAll().subscribe({
      next: (response) => {
        this.tagList = response.tags.data;
        this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
      },
      error: (err) => {},
    });
  }
  TagsSelected() {
    const selectedTag: any = this.candidatetagList.find(
      (tag: any) => tag.id === this.selectedtagValue
    );
    if (selectedTag) {
      this.tagError = true;
      this.modalService.dismissAll();
      return;
    }
    this.candidateService
      .attachtag(this.CandidateId, this.selectedtagValue)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.onLoad();

          // window.location.reload();
        },
        error: (err) => {},
      });
  }
  dropdownselect(evt: any) {
    this.selectedProjectStatus = evt;
  }
  dropdownselectStrength(evt: any, projectId: any) {
    this.strengthId = evt;
    this.selectedStrengths[projectId] = evt;
  }

  getCandidateStatus() {
    this.candidateService.getCandidateStatus().subscribe({
      next: (response: any) => {
        this.candidateStatus = response.candidate_statuses.data.filter(
          (e: any) => Number(e.is_active) === 1
        );

      },
      error: (error) => {},
    });
  }

  getclientStatus() {
    this.ClientService.getClientStatus().subscribe({
      next: (response: any) => {
        this.ClientStatuslist = response.client_statuses.data;
      },
      error: (error) => {},
    });
  }

  getclientprobability() {
    this.ClientService.getclientprobability().subscribe({
      next: (response: any) => {
        this.ClientProbabiltylist = response.client_probability_statuses.data;
      },
      error: (error) => {},
    });
  }

  onTagSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      // searchValue.length>=2
      let data = { search: searchValue };
      this.tagService.searchTagList(data).subscribe({
        next: (response: any) => {
          this.tagList = response.tags.data;
          this.tagList = this.tagList.filter((ele: any) => ele.is_active == 1);
        },
        error: (err) => {},
      });
    }
  }
  returnToBack() {
    this.location.back();
//     setTimeout(() => {
//       window.location.reload();
//     }, 200);
  }

  searchPeopleInCompany() {
    if (!this.candidateData.current_company) {
      return;
    }
    let candidateStorage: any = this.storageService.getValue(
      StorageKey.listSettings
    );
    candidateStorage = JSON.parse(candidateStorage);
    //  candidateStorage.candidates.search = this.candidateData.current_company;
    this.storageService.setValue(
      'listSettings',
      JSON.stringify({
        candidates: {
          apply_filter: this.candidateData.current_company,
          page: 1,
        },
      })
    );
    candidateStorage = this.storageService.getValue(StorageKey.listSettings);
    candidateStorage = JSON.parse(candidateStorage);
    candidateStorage.candidates.apply_filter =
      'search_current_company=' + this.candidateData.current_company;
    candidateStorage.candidates.page = 1;
    this.storageService.setValue(
      StorageKey.listSettings,
      JSON.stringify(candidateStorage)
    );
    this.router.navigate(['/candidate-list']);
  }

  onProjectSearch(term: any) {
    let searchValue = term.term;
    if (true) {
      // searchValue.length>=2
      let data = {
        is_public: 0,
        search: searchValue,
      };

      this.projectService.searchProjectList(data).subscribe({
        next: (response: any) => {
          this.projectLists = response.projects.data;
          this.projectLists = this.projectLists.filter(
            (ele: any) => ele.project_status_id == 1
          );
          this.projectLists.forEach((ele: any) => {
            ele['dropdown'] =
              ele?.name +
              (ele?.client?.name ? ' [' + ele?.client?.name + ']' : '');
          });
        },
        error: (err: any) => {},
      });
    }
  }

  getInitials(name: string): string {
    const words = name.split(' ');
    let initials = '';

    for (let i = 0; i < words.length; i++) {
      const word = words[i].trim();

      if (word.length > 0) {
        initials += word[0].toUpperCase();
      }
    }

    return initials;
  }

  getClientName(id: any) {
    const client = this.clientsData.find((data: any) => data.clients.id === id);
    return client?.clients?.name || '';
  }

  getClientNotes() {
    this.projectapicall = true;
    let data = {
      client_id: undefined,
      candidate_id: this.CandidateId,
      sort_field: 'updated_at',
      sort_method: 'desc',
    };
    // if (this.CreateNoteForm.controls['client_notes'].value != null)
      data.client_id = this.CreateNoteForm.controls['client_notes'].value;

    this.notesService.getClientNotes(data).subscribe({
      next: (response: any) => {
        this.projectapicall = false;
        this.clientNotesList = response.notes.data;
        this.clientNotesList.forEach((element: any) => {
          element['initials'] = this.getInitials(element.creator.name);
        });
      },
      error: (error) => {},
    });
  }

  getCandidateNotes() {
    let data = {
      id: this.CandidateId,
      project_id: undefined,
      sort_field: 'updated_at',
      sort_method: 'desc',
      is_client_note: 0,
    };

    if (this.CreateNoteForm.controls['notes_project'].value != null)
      data.project_id = this.CreateNoteForm.controls['notes_project'].value;
    this.notesService.listByFilter(data).subscribe({
      next: (response: any) => {
        this.notesList = response.notes.data;
        this.notesList.forEach((element: any) => {
          element['initials'] = this.getInitials(element.creator.name);
        });
      },
      error: (error) => {},
    });
  }
  deleteExperienceDetail(): void {
    this.candidateService
      .deleteExperience(this.CandidateId, this.deleteExperienceId)
      .subscribe({
        next: (response) => {
          this.notificationService.showSuccess(response.message);
          window.location.reload();
        },
        error: (error) => {},
      });
  }

  deleteNoteDetail() {
    this.notesService
      .deleteNote(this.CandidateId, this.deleteNoteId)
      .subscribe({
        next: (response) => {
          window.location.reload();
          if (this.activeNote === 'candidate') {
            this.getCandidateNotes();
          } else {
            this.getClientNotes();
          }
          this.modalService.dismissAll();
          this.notificationService.showSuccess(response.message);
        },
        error: (error) => {},
      });
  }

  deleteClientNoteDetail() {
    this.notesService.deleteClientNote(this.deleteNoteId).subscribe({
      next: (response) => {
        this.getClientNotes();
        this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
      },
      error: (error) => {},
    });
  }

  updateNoteDetail() {
    this.updatesubmitted = true;

    let data = {
      note: this.UpdateNoteForm.controls['notes'].value,
      allow_export: this.UpdateNoteForm.controls['allow_export'].value ? 1 : 0,
      is_client_note: this.activeNote === 'candidate' ? 0 : 1,
    };
    this.projectapicall = true;
    this.notesService
      .updateNote(
        this.CandidateId,
        this.UpdateNoteForm.controls['notes_id'].value,
        data
      )
      .subscribe({
        next: (response) => {
          window.location.reload();
          if (this.activeNote === 'candidate') {
            this.getCandidateNotes();
          } else {
            this.getClientNotes();
          }
          this.modalService.dismissAll();
          this.updatesubmitted = false;
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateNotesError = error.error.message;
          if (error.status === 422) {
            if (error.error.note) {
              this.UpdateNotesErrorNote = error.error.note[0];
            }
            if (error.error.allow_export) {
              this.UpdateNotesErrorAllowexport = error.error.allow_export[0];
            }
          }
        },
      });
  }

  updateClientNoteDetail() {
    this.updatesubmitted = true;
    const clientId = this.UpdateNoteForm.controls['client_notes'].value;
    const clientData = this.clientsData.find(
      (data: any) => data.client_id === clientId
    );

    let data = {
      note: this.UpdateNoteForm.controls['notes'].value,
      client_id: clientId,
      note_type: clientId ? 'contact' : 'candidate',
      ...(clientId ? { contact_id: clientData?.id } : { candidate_id: this.candidateData.id }),
    };
    this.projectapicall = true;
    this.notesService
      .updateClientNote(this.updateNoteForm['notes_id'].value, data)
      .subscribe({
        next: (response) => {
          window.location.reload();
          this.getClientNotes();
          this.modalService.dismissAll();
          this.updatesubmitted = false;
          this.notificationService.showSuccess(response.message);
          this.projectapicall = false;
        },
        error: (error) => {
          this.projectapicall = false;
          this.UpdateNotesError = error.error.message;
          if (error.status === 422) {
            if (error.error.note) {
              this.UpdateNotesErrorNote = error.error.note[0];
            }
            if (error.error.allow_export) {
              this.UpdateNotesErrorAllowexport = error.error.allow_export[0];
            }
          }
        },
      });
  }
  linkedinRefresh(popupForLinkedinContent: any) {
    if (this.candidateData?.linkedin_url === null) {
      this.openUrlModal(popupForLinkedinContent);
      return;
    }
    this.candidateService.linkedinRefresh(this.CandidateId).subscribe({
      next: (response: any) => {
        this.refreshDate = response.candidate.refreshed_at;
        this.notificationService.showSuccess(response.message);
        window.location.reload();
      },
      error: (err: any) => {},
    });
  }

  resetBackendAttacherror() {
    this.createAttachErrorProjectid = '';
    this.createAttachError = '';
  }

  projectSelected() {
    if (this.selectedProject || this.selectedProject != null) {
      this.candidateService
        .attachProject(this.CandidateId, this.selectedProject)
        .subscribe({
          next: (response: any) => {
            this.notificationService.showSuccess(response.message);
            this.onLoad();
            this.modalService.dismissAll();
            window.location.reload();
          },
          error: (error: any) => {
            this.createAttachError = error.error.message;
            if (error.status === 422) {
              if (error.error.project_id) {
                this.createAttachErrorProjectid = error.error.project_id[0];
              }
            }
          },
        });
    }
  }

  createCandidate() {
    this.submitted = true;

    if (this.CreateCandidateForm.invalid) return;

    if (this.createCandidateForm['linkedInUrl'].value.trim() == '') {
      this.notificationService.showError('Please Enter LinkedIn URL');
      return;
    }

    this.createCandidateData = {
      url: this.createCandidateForm['linkedInUrl'].value,
    };

    this.candidateService.create(this.createCandidateData).subscribe({
      next: (response) => {},
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  openUrlModal(popupForLinkedinContent: any) {
    this.modalService
      .open(popupForLinkedinContent, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  // attachLogoFiles(event: any) {

  //   this.files2 = event.target.files[0];
  //   this.fileName2 = this.files2.name;
  //   this.filePath2 = event.target.value;

  //   if (event.target.files) {
  //     var reader = new FileReader;
  //     reader.onload = (event: any) => {
  //       this.url = event.target.result;
  //     }
  //     reader.readAsDataURL(event.target.files[0]);
  //     const formData: FormData = new FormData();
  //     formData.append('name', this.fileName2);
  //     formData.append('profile_pic', this.files2);
  //     this.projectapicall = true;
  //     this.candidateService.updateCandidateData(this.CandidateId, formData).subscribe({
  //       next: (response) => {
  //         this.modalService.dismissAll();

  //         this.notificationService.showSuccess(response.message);
  //         this.projectapicall = false;
  //         this.submitted = false;
  //         this.CreateCandidateDetailsForm.reset();
  //         this.onLoad();

  //       },
  //       error: (error) => {
  //         this.projectapicall = false;
  //         this.CreateCandidateDetailsError = error.error.message;
  //         switch (error.status) {
  //           case 422:
  //             if (error.error.in_first_name) {
  //               this.CreateCandidateDetailsErrorFirstname = error.error.in_first_name[0];
  //             }
  //             if (error.error.in_last_name) {
  //               this.CreateCandidateDetailsErrorLastname = error.error.in_last_name[0];
  //             }
  //             if (error.error.in_gender) {
  //               this.CreateCandidateDetailsErrorGender = error.error.in_gender[0];
  //             }
  //             if (error.error.in_birth_date) {
  //               this.CreateCandidateDetailsErrorInbirthdate = error.error.in_birth_date[0];
  //             }
  //             if (error.error.current_company) {
  //               this.CreateCandidateDetailsErrorCurrentcompany = error.error.current_company[0];
  //             }
  //             if (error.error.current_position) {
  //               this.CreateCandidateDetailsErrorCurrentposition = error.error.current_position[0];
  //             }
  //             if (error.error.custom_diversity) {
  //               this.CreateCandidateDetailsErrorCustomdiversity = error.error.custom_diversity[0];
  //             }
  //             if (error.error.custom_compensation_details) {
  //               this.CreateCandidateDetailsErrorCustomcompensationdetails = error.error.custom_compensation_details[0];
  //             }
  //             if (error.error.language_ids) {
  //               this.CreateCandidateDetailsErrorLanguageids = error.error.language_ids[0];
  //             }
  //             if (error.error.linkedin_url) {
  //               this.CreateCandidateDetailsErrorLinkedinurl = error.error.linkedin_url[0];
  //             }
  //             break;
  //           default:
  //           //Nothing
  //         }
  //         // this.notificationService.showError(error.message);
  //       }
  //     })

  //   }

  // }

  attachLogoFiles(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;

    if (event.target.files) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);

      // Your existing upload logic
      const formData: FormData = new FormData();
      formData.append('name', this.fileName);
      formData.append('profile_pic', this.files);
      this.projectapicall = true;
      this.candidateService
        .updateCandidateData(this.CandidateId, formData)
        .subscribe({
          next: (response) => {
            this.modalService.dismissAll();
            this.notificationService.showSuccess(response.message);
            this.projectapicall = false;
            this.submitted = false;
            this.CreateCandidateDetailsForm.reset();
            this.onLoad();
          },
          error: (error) => {
            this.projectapicall = false;
            this.CreateCandidateDetailsError = error.error.message;
          },
        });
    }
  }

  resetAddBusinessError() {
    // this.CreateEducationDetailsErrorDegreename = "";
    // this.CreateEducationDetailsErrorSchool="";
    // this.CreateEducationDetailsErrorStartdate = "";
    // this.CreateEducationDetailsErrorEnddate="";
    // this.CreateEducationDetailsError="";
    this.createClientAttachError = '';
    this.AddNewBusinessForm.reset();
  }

  capitalizeFirstLetter(str: string): string {
    if (!str) return '---'; // If the string is empty or undefined, return '---'
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  resetAddCertificateForm() {
    this.AddCertificateForm.reset();
  }

  private getURLPattern(): string {
    return '^(http|https)://([\\da-z.-]+)\\.([a-z.]{2,6})([/\\w .-]*)*/?$';
  }

  showclient(clientid: number) {
    // console.log(clientid)

    this.clientService.getClientData({ id: clientid }).subscribe({
      next: (data) => {
        const response = data.client;
        this.ClientId = data.client.id;
        this.getCandidatePhoneNumber();
        this.getDocumentData();
        this.getStateList();
        this.getUsersList({ term: response.owner?.name });
        this.url2 = data.client.logo_path;
        this.rectangleBox = true;
        this.openLogoSection = false;
        if (this.url2 == null) {
          this.rectangleBox = true;
          this.logoBox2 = false;
        } else if (this.url2 != null) {
          this.rectangleBox = false;
          this.logoBox2 = true;
        }

        if (response.web_link_one !== 'null' && response.web_link_one != null) {
          this.CreateClientForm.controls['web_link_one'].setValue(
            response.web_link_one
          );
          this.showexternallink1 = true;
          this.weblink1 = response.web_link_one;
          this.editexternallink1 = false;
        } else {
          this.CreateClientForm.controls['web_link_one'].setValue('');
          this.showexternallink1 = false;
          this.weblink1 = '';
          this.editexternallink1 = true;
        }

        if (response.web_link_two !== 'null' && response.web_link_two != null) {
          this.CreateClientForm.controls['web_link_two'].setValue(
            response.web_link_two
          );
          this.showexternallink2 = true;
          this.weblink2 = response.web_link_two;
          this.editexternallink2 = false;
        } else {
          this.CreateClientForm.controls['web_link_two'].setValue('');
          this.showexternallink2 = false;
          this.weblink2 = '';
          this.editexternallink2 = true;
        }

        if (
          response.web_link_three !== 'null' &&
          response.web_link_three != null
        ) {
          this.CreateClientForm.controls['web_link_three'].setValue(
            response.web_link_three
          );
          this.showexternallink3 = true;
          this.weblink3 = response.web_link_three;
          this.editexternallink3 = false;
        } else {
          this.CreateClientForm.controls['web_link_three'].setValue('');
          this.showexternallink3 = false;
          this.weblink3 = '';
          this.editexternallink3 = true;
        }

        if (response.description !== 'null' && response.description != null) {
          this.CreateClientForm.controls['description'].setValue(
            response.description
          );
        } else {
          this.CreateClientForm.controls['description'].setValue('');
        }

        if (response.name == null) {
          this.CreateClientForm.controls['name'].setValue(null);
        } else {
          this.CreateClientForm.controls['name'].setValue(response.name);
        }

        if (response.owner == null) {
          this.CreateClientForm.controls['user_id'].setValue(null);
        } else {
          this.CreateClientForm.controls['user_id'].setValue(response.owner.id);
        }

        if (response.industry !== 'null' && response.industry != null) {
          this.CreateClientForm.controls['industry'].setValue(
            response.industry
          );
        } else {
          this.CreateClientForm.controls['industry'].setValue('');
        }

        if (
          response.projects_count !== 'null' &&
          response.projects_count != null
        ) {
          this.CreateClientForm.controls['no_of_searches'].setValue(
            response.projects_count
          );
        } else {
          this.CreateClientForm.controls['no_of_searches'].setValue('');
        }
        this.client['no_of_searches'].disable();

        if (response.no !== 'null' && response.client_status_id != 0) {
          this.CreateClientForm.controls['client_status_id'].setValue(
            response.client_status_id
          );
        } else {
          this.CreateClientForm.controls['client_status_id'].setValue(0);
        }

        // Proability staus Id
        if (
          response.no !== 'null' &&
          response.client_probability_status_id != 0
        ) {
          this.CreateClientForm.controls[
            'client_probability_status_id'
          ].setValue(response.client_probability_status_id);
        } else {
          this.CreateClientForm.controls[
            'client_probability_status_id'
          ].setValue(0);
        }

        if (
          response.street_address !== 'null' &&
          response.street_address != null
        ) {
          this.CreateClientForm.controls['street_address'].setValue(
            response.street_address
          );
        } else {
          this.CreateClientForm.controls['street_address'].setValue('');
        }

        if (response.postal_code !== 'null' && response.postal_code != null) {
          this.CreateClientForm.controls['postal_code'].setValue(
            response.postal_code
          );
        } else {
          this.CreateClientForm.controls['postal_code'].setValue('');
        }

        if (response.city !== 'null' && response.city != null) {
          this.CreateClientForm.controls['city'].setValue(response.city);
        } else {
          this.CreateClientForm.controls['city'].setValue('');
        }

        if (response.state !== 'null' && response.state != null) {
          this.CreateClientForm.controls['state'].setValue(response.state);
        } else {
          this.CreateClientForm.controls['state'].setValue(null);
        }

        if (
          response.contact_email !== 'null' &&
          response.contact_email != null
        ) {
          this.CreateClientForm.controls['contact_email'].setValue(
            response.contact_email
          );
        } else {
          this.CreateClientForm.controls['contact_email'].setValue('');
        }

        if (
          response.contact_number !== 'null' &&
          response.contact_number != null
        ) {
          this.CreateClientForm.controls['contact_number'].setValue(
            response.contact_number
          );
        } else {
          this.CreateClientForm.controls['contact_number'].setValue('');
        }

        if (response.candidate_id == null) {
          this.CreateClientForm.controls['candidate_id'].setValue('');
        } else {
          this.CreateClientForm.controls['candidate_id'].setValue(
            response.candidate_id
          );
        }

        this.CreateClientForm.controls['ClientId'].setValue(response.id);

        if (
          response.primary_contact !== 'null' &&
          response.primary_contact != null
        ) {
          this.CreateClientForm.controls['primary_contact_static'].setValue(
            response.primary_contact
          );
          this.CreateClientForm.controls['primary_contact'].setValue(
            response.primary_contact
          );
          this.client['primary_contact_static'].disable();
        } else {
        }

        this.CreateClientForm.controls['is_referenceable'].setValue(
          response.is_referenceable !== null ? response.is_referenceable : null
        );

        this.modalService
          .open(this.ViewClientModal, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md',
          })
          .result.then(
            (result) => {
              this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
              //${this.getDismissReason(reason)}
              this.closeResult = `Dismissed `;
            }
          );
      },

      error: (err) => {
        console.error('Error fetching client data:', err);
      },
    });
  }

  getUsersList(term: any = null) {
    let currentTenant = this.storageService.getValue(
      StorageKey.currentTenantId
    );
    if (currentTenant == '0') {
      return;
    }
    let data = {};
    if (term && term.term) {
      data = { search: term.term };
    }
    this.userService.listByFilter(data).subscribe({
      next: (response) => {
        this.userList = response.users.data;
        let loginUserDetail: any = this.storageService.getValue(
          StorageKey.currentUser
        );
        let currentUser = JSON.parse(loginUserDetail);
        if (currentUser.is_super_admin != 1) {
          let currentUserid = this.storageService.getValue(
            StorageKey.currentUserid
          );
          let isUserIdInList: boolean = false;
          this.userList.forEach((e: any) => {
            if (e.id == currentUserid) {
              isUserIdInList = true;
            }
          });
          if (!isUserIdInList) {
            this.userList.push(currentUser);
          }
        }
      },
      error: (error) => {},
    });
  }

  getClientStatuses() {
    this.clientService.clientStatusListByFilter({}).subscribe({
      next: (response) => {
        this.clientStatusList = response.client_statuses.data;
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  populateEmail(event: any) {
    if (event && event.id != undefined) {
      this.f['primary_contact_static'].disable();
      this.static_primary_contact = false;
      this.candidateService.candidateDetail(event.id).subscribe({
        next: (response: any) => {
          this.candidateData = response.candidate;
          this.f['contact_number'].setValue('');
          this.f['contact_email'].setValue('');
          this.f['primary_contact_static'].setValue('');
          this.f['candidate_id'].setValue('');

          if (this.candidateData.is_in_personal_email_preffered) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.is_in_work_email_preffered) {
            this.f['contact_email'].setValue(event.in_work_email);
          } else if (this.candidateData.in_personal_email) {
            this.f['contact_email'].setValue(event.in_personal_email);
          } else if (this.candidateData.in_work_email) {
            this.f['contact_email'].setValue(event.in_work_email);
          }

          if (this.candidateData.is_in_personal_phone_preffered) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.is_in_work_phone_preffered) {
            this.f['contact_number'].setValue(event.in_work_phone);
          } else if (this.candidateData.in_personal_phone) {
            this.f['contact_number'].setValue(event.in_personal_phone);
          } else if (this.candidateData.in_work_phone) {
            this.f['contact_number'].setValue(event.in_work_phone);
          }

          this.f['primary_contact_static'].setValue(event.full_name);
          this.f['candidate_id'].setValue(event.id);

          this.getCandidatePhoneNumber();
        },
        error: () => {},
      });
    } else {
      this.f['primary_contact_static'].enable();
      /*  if((event.type != "keyup") && (event.type == "blur" && this.f['primary_contact'].value != "")){
         this.static_primary_contact=true;
         this.f['primary_contact'].setValue("");
       }
       this.candidateData="";
       if(event.type != "blur")
       this.f['primary_contact_static'].setValue(event.target.value); */
      if (event && event.target)
        this.getCandidatePhoneNumber(event.target.value);
      this.f['contact_number'].setValue('');
      this.f['contact_email'].setValue('');
      this.f['primary_contact_static'].setValue('');
      this.f['candidate_id'].setValue('');
    }
  }

  getCandidatePhoneNumber(selected_value = '') {
    this.candidateService.listByFilter({ search: selected_value }).subscribe({
      next: (response) => {
        this.candidateList = response.candidates.data;
        this.candidateList.forEach((element: any) => {
          element.full_name =
            element.in_first_name +
            ' ' +
            element.in_last_name +
            ' ' +
            (element.current_company
              ? '[' + element.current_company + ']'
              : '');
        });
      },
      error: (error) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getStateList() {
    this.clientService.listStateAll().subscribe({
      next: (response) => {
        this.stateList = response.states;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  getDocumentData() {
    this.clientService.getDocumentData(this.ClientId).subscribe({
      next: (response) => {
        this.documentData = response.client.documents;
      },
      error: (error: any) => {
        this.notificationService.showError(error.message);
      },
    });
  }

  deleteDocuments1(deleteId: any) {
    this.clientService.deleteDocument(this.ClientId, deleteId).subscribe({
      next: (response: any) => {
        this.notificationService.showSuccess(response.message);
        this.getDocumentData();
      },
      error: (err: any) => {},
    });
  }

  UpdateClients() {
    this.submitted = true;
    if (this.CreateClientForm.invalid) {
      return;
    }

    let UpdatedClientDetails = {
      id: this.CreateClientForm.controls['ClientId'].value,
      name: this.CreateClientForm.controls['name'].value,
      user_id: this.CreateClientForm.controls['user_id'].value,
      industry: this.CreateClientForm.controls['industry'].value,
      city: this.CreateClientForm.controls['city'].value,
      state: this.CreateClientForm.controls['state'].value,
      contact_email: this.CreateClientForm.controls['contact_email'].value,
      contact_number: this.CreateClientForm.controls['contact_number'].value,
      postal_code: this.CreateClientForm.controls['postal_code'].value,
      street_address: this.CreateClientForm.controls['street_address'].value,
      description: this.CreateClientForm.controls['description'].value,
      website: this.CreateClientForm.controls['website'].value,
      primary_contact: this.CreateClientForm.controls['primary_contact'].value
        ? this.CreateClientForm.controls['primary_contact'].value
        : this.CreateClientForm.controls['primary_contact_static'].value,
      client_status_id:
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value,
      web_link_one: this.CreateClientForm.controls['web_link_one'].value,
      web_link_two: this.CreateClientForm.controls['web_link_two'].value,
      web_link_three: this.CreateClientForm.controls['web_link_three'].value,
      candidate_id: this.CreateClientForm.controls['candidate_id'].value,
      client_probability_status_id:
        this.CreateClientForm.controls['client_probability_status_id'].value ==
        null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id']
              .value,
      is_referenceable: this.CreateClientForm.controls['is_referenceable'].value
        ? 1
        : 0,
    };

    this.projectapicall = true;
    const formData: FormData = new FormData();

    if (this.fileName2 != null) {
      formData.append('logoname', this.fileName2);
      formData.append('logo', this.files2);
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append(
        'contact_email',
        this.CreateClientForm.controls['contact_email'].value
      );
      formData.append(
        'contact_number',
        this.CreateClientForm.controls['contact_number'].value
      );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      formData.append(
        'primary_contact',
        this.CreateClientForm.controls['primary_contact'].value
          ? this.CreateClientForm.controls['primary_contact'].value
          : this.CreateClientForm.controls['primary_contact_static'].value
      );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      formData.append(
        'candidate_id',
        this.CreateClientForm.controls['candidate_id'].value
      );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    } else if (this.fileName2 == null) {
      formData.append(
        'ClientId',
        this.CreateClientForm.controls['ClientId'].value
      );
      formData.append('name', this.CreateClientForm.controls['name'].value);
      formData.append(
        'user_id',
        this.CreateClientForm.controls['user_id'].value
      );
      formData.append(
        'industry',
        this.CreateClientForm.controls['industry'].value
      );
      formData.append('city', this.CreateClientForm.controls['city'].value);
      formData.append('state', this.CreateClientForm.controls['state'].value);
      formData.append(
        'contact_email',
        this.CreateClientForm.controls['contact_email'].value
      );
      formData.append(
        'contact_number',
        this.CreateClientForm.controls['contact_number'].value
      );
      formData.append(
        'postal_code',
        this.CreateClientForm.controls['postal_code'].value
      );
      formData.append(
        'street_address',
        this.CreateClientForm.controls['street_address'].value
      );
      formData.append(
        'description',
        this.CreateClientForm.controls['description'].value
      );
      formData.append(
        'website',
        this.CreateClientForm.controls['website'].value
      );
      formData.append(
        'primary_contact',
        this.CreateClientForm.controls['primary_contact'].value
          ? this.CreateClientForm.controls['primary_contact'].value
          : this.CreateClientForm.controls['primary_contact_static'].value
      );
      formData.append(
        'client_status_id',
        this.CreateClientForm.controls['client_status_id'].value == null
          ? 0
          : this.CreateClientForm.controls['client_status_id'].value
      );
      formData.append(
        'web_link_one',
        this.CreateClientForm.controls['web_link_one'].value
      );
      formData.append(
        'web_link_two',
        this.CreateClientForm.controls['web_link_two'].value
      );
      formData.append(
        'web_link_three',
        this.CreateClientForm.controls['web_link_three'].value
      );
      formData.append(
        'candidate_id',
        this.CreateClientForm.controls['candidate_id'].value
      );
      formData.append(
        'client_probability_status_id',
        this.CreateClientForm.controls['client_probability_status_id'].value ==
          null
          ? 0
          : this.CreateClientForm.controls['client_probability_status_id'].value
      );
      formData.append(
        'is_referenceable',
        this.CreateClientForm.controls['is_referenceable'].value ? '1' : '0'
      );
    }

    this.clientService
      .updateClientData(UpdatedClientDetails.id, formData)
      .subscribe({
        next: (response) => {
          this.modalService.dismissAll();
          this.CreateClientForm.reset();
          this.notificationService.showSuccess(response.message);
          //  window.location.reload();
          this.projectapicall = false;
          this.submitted = false;
          // this.onLoad();
          this.onLoad();
          //  this.getClientList({});
          for (let i = 0; i < this.clientList.length; i++) {
            if (this.clientList[i].id === UpdatedClientDetails.id) {
              this.clientList[i] = response.client;
              break;
            }
          }
        },
        error: (error) => {
          this.projectapicall = false;
          this.createClientError = error.error.message;
          // this.createClientError = error.error.url;
          if (error.status === 422) {
            if (error.error.name) {
              this.createClientErrorName = error.error.name[0];
            }
            if (error.error.industry) {
              this.createClientErrorIndustry = error.error.industry[0];
            }
            if (error.error.contact_number) {
              this.createClientErrorContactnumber =
                error.error.contact_number[0];
            }
            if (error.error.contact_email) {
              this.createClientErrorEmail = error.error.contact_email[0];
            }
            if (error.error.street_address) {
              this.createClientErrorAddress = error.error.street_address[0];
            }
            if (error.error.postal_code) {
              this.createClientErrorPostal = error.error.postal_code[0];
            }
            if (error.error.city) {
              this.createClientErrorCity = error.error.city[0];
            }
            if (error.error.state) {
              this.createClientErrorState = error.error.state[0];
            }
            if (error.error.website) {
              this.createClientErrorWebsite = error.error.website[0];
            }
            if (error.error.description) {
              this.createClientErrorDescription = error.error.description[0];
            }
            if (error.error.primary_contact) {
              this.createClientErrorPrimarycontact =
                error.error.primary_contact[0];
            }
            if (error.error.web_link_one) {
              this.createClientErrorWeblinkone = error.error.web_link_one[0];
            }
            if (error.error.web_link_two) {
              this.createClientErrorWeblinktwo = error.error.web_link_two[0];
            }
            if (error.error.web_link_three) {
              this.createClientErrorWeblinkthree =
                error.error.web_link_three[0];
            }
            if (error.error.logo) {
              this.createClientErrorLogo = error.error.logo[0];
            }
          }
        },
      });
  }

  resetBackendClienterror() {
    this.createClientErrorName = '';
    this.createClientErrorIndustry = '';
    this.createClientErrorContactnumber = '';
    this.createClientErrorEmail = '';
    this.createClientErrorAddress = '';
    this.createClientErrorPostal = '';
    this.createClientErrorCity = '';
    this.createClientErrorState = '';
    this.createClientErrorWebsite = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorCity = '';
    this.createClientErrorPrimarycontact = '';
    this.createClientErrorWeblinkone = '';
    this.createClientErrorWeblinktwo = '';
    this.createClientErrorWeblinkthree = '';
    this.createClientErrorLogo = '';
    this.CreatDocumentsErrorDocuments = '';
    this.createClientError = '';
    this.CreatDocumentsErrorDocumentsname = '';
    this.CreateClientForm.reset();
  }

  attachFiles2(event: any) {
    this.files = event.target.files[0];
    this.fileName = this.files.name;
    this.filePath = event.target.value;
    this.showUploadFileButton = true;
  }

  UploadDocuments2() {
    this.showUploadFileButton = false;
    this.uploadsubmitted = true;
    if (!this.files) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('name', this.fileName);
    formData.append('document', this.files);

    this.projectapicall2 = true;

    this.clientService.createDocument(this.ClientId, formData).subscribe({
      next: (response: any) => {
        // this.onLoad();
        // this.modalService.dismissAll();
        this.notificationService.showSuccess(response.message);
        this.projectapicall2 = false;
        this.uploadsubmitted = false;
        this.getDocumentData();
        this.CreateClientForm.controls['document'].setValue(null);
      },
      error: (error) => {
        this.projectapicall2 = false;
        if (error.status === 422) {
          if (error.error.document) {
            this.CreatDocumentsErrorDocuments = error.error.document[0];
          }

          if (error.error.name) {
            this.CreatDocumentsErrorDocumentsname = error.error.name[0];
          }
        }
      },
    });
  }

  attachLogoFiles2(event: any) {
    this.files2 = event.target.files[0];
    this.fileName2 = this.files2.name;
    this.filePath2 = event.target.value;

    if (event.target.files) {
      this.logoBox2 = true;
      this.rectangleBox = false;
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.url2 = event.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
}
